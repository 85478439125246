import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { Edit, Visibility } from "@mui/icons-material";

const AdminPostList = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPosts();
  }, [page, rowsPerPage]);

  const fetchPosts = async () => {
    try {
      const response = await api.get(`/admin/posts?page=${page + 1}&per_page=${rowsPerPage}`);
      setPosts(response.data.posts || []);
    } catch (error) {
      console.error("Erro ao buscar posts", error);
      setPosts([]);
    }
  };

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Gerenciar Posts
      </Typography>
      <Button variant="contained" color="primary" onClick={() => navigate("/admin/posts/new")}>
        Criar Novo Post
      </Button>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Título</TableCell>
              <TableCell>Autor</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {posts.map((post) => (
              <TableRow key={post.id}>
                <TableCell>{post.id}</TableCell>
                <TableCell>{post.title}</TableCell>
                <TableCell>{post.author}</TableCell>
                <TableCell>
                  <IconButton onClick={() => navigate(`/admin/posts/${post.id}`)} color="primary">
                    <Visibility />
                  </IconButton>
                  <IconButton onClick={() => navigate(`/admin/posts/edit/${post.id}`)} color="secondary">
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={posts.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </TableContainer>
    </Box>
  );
};

export default AdminPostList;
