import Routes from "./routes";
import { AuthContextProvider } from "./contexts/AuthContext";
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline />
            <Routes></Routes>
          </LocalizationProvider>
        </ThemeProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
