import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Title
} from "chart.js";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Title
);

const AdsCostPerSaleChart = ({ orders, adsTrackers, startDate, endDate }) => {
  // Gera labels para todas as datas entre startDate e endDate
  const generateLabels = (start, end) => {
    const labels = [];
    let current = dayjs(start);
    const last = dayjs(end);
    while (current.isSameOrBefore(last, "day")) {
      labels.push(current.format("DD/MM/YYYY"));
      current = current.add(1, "day");
    }
    return labels;
  };

  const labels = generateLabels(startDate, endDate);

  // Agrupa os pedidos por data (contando apenas os que estão com invoice_status "paid")
  const ordersGrouped = orders.reduce((acc, order) => {
    if (order.invoice_status !== "paid") return acc;
    const date = dayjs(order.created_at).format("DD/MM/YYYY");
    if (!acc[date]) acc[date] = 0;
    acc[date] += 1;
    return acc;
  }, {});

  // Agrupa os dados de ads por data somando os gastos (google, facebook e bing)
  const adsGrouped = adsTrackers.reduce((acc, tracker) => {
    if (!tracker.reference_date) return acc;
    const date = dayjs(tracker.reference_date).format("DD/MM/YYYY");
    const totalCost =
      (tracker.google_cost || 0) +
      (tracker.facebook_cost || 0) +
      (tracker.bing_cost || 0);
    if (!acc[date]) acc[date] = 0;
    acc[date] += totalCost;
    return acc;
  }, {});

  // Calcula o custo por venda para cada data (gastos / número de vendas)
  const costPerSaleData = labels.map(date => {
    const sales = ordersGrouped[date] || 0;
    const adCost = (adsGrouped[date] || 0) / 100;
    return sales > 0 ? adCost / sales : 0;
  });

  const data = {
    labels,
    datasets: [
      {
        label: "Custo por Venda (R$)",
        data: costPerSaleData,
        fill: false,
        borderColor: "#e74c3c",
        backgroundColor: "#e74c3c",
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Custo por Venda por Dia" },
    },
    scales: {
      x: { title: { display: true, text: "Data" } },
      y: {
        title: { display: true, text: "Custo por Venda (R$)" },
        beginAtZero: true,
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default AdsCostPerSaleChart;
