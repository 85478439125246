import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Box,
  Paper,
  Stack
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import ApiIcon from "@mui/icons-material/Api";
import { MainLogo } from "../../../components/MainLogo";
import api from "../../../services/api";

const AdminSiteNew = () => {
  const [siteData, setSiteData] = useState({
    name: "",
    title: "",
    description: "",
    active: true,
    order_api_approve_endpoint: "",
    order_api_approve_token: "",
    order_api_reject_endpoint: "",
  });

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSiteData({
      ...siteData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    api
      .post("/admin/sites", { site: siteData })
      .then((response) => {
        console.log("Site criado com sucesso:", response.data);
        navigate("/admin/sites");
      })
      .catch((error) => {
        console.error("Erro ao criar site:", error);
        setError("Erro ao criar o site.");
      });
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <MainLogo />

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/admin/sites")}
        >
          Voltar
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom>
        Criar Novo Site
      </Typography>

      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <Paper variant="outlined" sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          {/* Seção: Informações do Site */}
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardHeader
              avatar={<InfoIcon color="primary" />}
              title="Informações do Site"
              sx={{ backgroundColor: "action.hover" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Nome"
                    name="name"
                    value={siteData.name}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Título"
                    name="title"
                    value={siteData.title}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Descrição"
                    name="description"
                    value={siteData.description}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="active"
                        checked={siteData.active}
                        onChange={handleChange}
                      />
                    }
                    label="Ativo"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Seção: Configurações da API */}
          <Card variant="outlined" sx={{ mb: 2 }}>
            <CardHeader
              avatar={<ApiIcon color="primary" />}
              title="Configurações da API"
              sx={{ backgroundColor: "action.hover" }}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Endpoint de Aprovação de Pedido"
                    name="order_api_approve_endpoint"
                    value={siteData.order_api_approve_endpoint}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Token de Aprovação de Pedido"
                    name="order_api_approve_token"
                    value={siteData.order_api_approve_token}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Endpoint de Rejeição de Pedido"
                    name="order_api_reject_endpoint"
                    value={siteData.order_api_reject_endpoint}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* Botões de Ação */}
          <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 3 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
            >
              Criar Site
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};

export default AdminSiteNew;
