import { Box, Typography, Card, CardContent } from "@mui/material";
import { useOrder } from "../../hooks/useOrder";
import { useEffect } from "react";

export function OrderShow() {
  const { order, setOrder } = useOrder();

  useEffect(() => {
    setOrder((prev) => ({ ...prev, showSidebar: false }));
    return () => setOrder((prev) => ({ ...prev, showSidebar: true }));
  }, [setOrder]);

  return (
    <Box sx={{ padding: 1 }}>
      <Box>
        <Box
          sx={{
            position: "relative",
            textAlign: "center",
            marginBottom: 5,
            width: 300,
            height: "auto",
            marginX: "auto",
          }}
        >
          <div
            className={`card-html-${order.design_id === 2 ? 'pink' : order.design_id === 1 ? 'green' : 'black'}`}
            dangerouslySetInnerHTML={{
              __html: order.card_svg_html,
            }}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
          <img
            src={order.card_photo}
            alt="Foto da carteirinha"
            style={{
              position: "absolute",
              top: "50%",
              left: "7%",
              transform: "translateY(-50%)",
              width: 75,
              height: 75,
              borderRadius: 0,
            }}
          />
        </Box>

        <Typography variant="h5" textAlign="center" gutterBottom>
          QRCode de validação:
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
            backgroundColor: "white",
            borderRadius: 2,
            marginTop: 2,
            width: "80%",
            marginX: "auto",
          }}
          dangerouslySetInnerHTML={{
            __html: order.qrcode_svg,
          }}
        />
      </Box>
    </Box>
  );
}
