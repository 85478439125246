import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Stack,
  Modal,
  Backdrop,
  Fade
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/useAuth";
import { MainLogo } from "../../../components/MainLogo";

// Ícones para navegação e ações
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import WebIcon from "@mui/icons-material/Web";

const AdminSiteShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [site, setSite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated()) return;

    setLoading(true);
    api
      .get(`/admin/sites/${id}`)
      .then((response) => setSite(response.data))
      .catch((err) => {
        console.error("Erro ao carregar site:", err);
        setError("Erro ao carregar os detalhes do site.");
      })
      .finally(() => setLoading(false));
  }, [id, isAuthenticated]);

  if (loading)
    return (
      <Typography align="center" variant="h6" sx={{ mt: 4 }}>
        Carregando...
      </Typography>
    );
  if (error)
    return (
      <Typography align="center" color="error" sx={{ mt: 4 }}>
        {error}
      </Typography>
    );
  if (!site)
    return (
      <Typography align="center" sx={{ mt: 4 }}>
        Nenhum site encontrado.
      </Typography>
    );

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <MainLogo />
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/admin/sites")}
        >
          Voltar
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom>
        Detalhes do Site
      </Typography>

      {/* Seção: Informações do Site */}
      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          avatar={<WebIcon color="primary" />}
          title="Informações do Site"
          sx={{ backgroundColor: "action.hover" }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Nome:</strong> {site.name}
              </Typography>
              <Typography variant="body1">
                <strong>Título:</strong> {site.title}
              </Typography>
              <Typography variant="body1">
                <strong>Descrição:</strong> {site.description}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Ativo:</strong> {site.active ? "Sim" : "Não"}
              </Typography>
              <Typography variant="body1">
                <strong>ID do Site:</strong> {site.id}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Ações do site */}
      <Paper variant="outlined" sx={{ p: 2, mt: 3 }}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2} justifyContent="center">
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => navigate(`/admin/sites/${id}/edit`)}
          >
            Editar Site
          </Button>
          <Button variant="contained" color="error" startIcon={<CancelIcon />}>
            Desativar Site
          </Button>
          <Button variant="contained" color="success" startIcon={<CheckCircleIcon />}>
            Ativar Site
          </Button>
        </Stack>
      </Paper>
    </Container>
  );
};

export default AdminSiteShow;
