import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  FormControlLabel,
  Checkbox,
  Container,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { MainLogo } from "../../../components/MainLogo";

const AdminDesignEdit = () => {
  const { id } = useParams();
  const [design, setDesign] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDesign = async () => {
      try {
        const response = await api.get(`/admin/designs/${id}`);
        setDesign(response.data);
      } catch (error) {
        console.error("Erro ao buscar design para edição", error);
      }
    };
    fetchDesign();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setDesign({ ...design, [name]: type === "checkbox" ? checked : value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.patch(`/admin/designs/${id}`, { design });
      alert("Design atualizado com sucesso!");
      navigate("/admin/designs");
    } catch (error) {
      console.error("Erro ao atualizar design", error);
      setErrors(error.response?.data?.errors || {});
    }
  };

  const handleBack = () => {
    navigate("/admin/designs");
  };

  if (!design) {
    return (
      <Container
        sx={{
          mt: 4,
          mb: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>Carregando...</Typography>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        my: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <MainLogo />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Button variant="text" startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Voltar
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom>
        Editar Design
      </Typography>

      <Paper sx={{ p: 4, maxWidth: 600, width: "100%" }}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Nome"
            name="name"
            value={design.name || ""}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            error={!!errors.name}
            helperText={errors.name ? errors.name[0] : ""}
          />

          <TextField
            label="Preço (em centavos)"
            name="price_in_cents"
            type="number"
            value={design.price_in_cents || ""}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            error={!!errors.price_in_cents}
            helperText={errors.price_in_cents ? errors.price_in_cents[0] : ""}
          />

          <TextField
            label="URL da Foto"
            name="photo_url"
            value={design.photo_url || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            error={!!errors.photo_url}
            helperText={errors.photo_url ? errors.photo_url[0] : ""}
          />

          <TextField
            label="Ano"
            name="year"
            value={design.year || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            error={!!errors.year}
            helperText={errors.year ? errors.year[0] : ""}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={design.visibility || false}
                onChange={handleChange}
                name="visibility"
                color="primary"
              />
            }
            label="Visível"
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Salvar Alterações
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default AdminDesignEdit;
