import { createContext, useEffect, useState } from "react";
import api from "../services/api";
import { Snackbar, Alert } from "@mui/material";

export const userToken = () => window.localStorage.getItem("@app/token");
export const AuthContext = createContext({});

export function AuthContextProvider(props) {
  const [user, setUser] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const isAuthenticated = () => userToken() !== null;

  async function getUserInfo() {
    const token = userToken();

    if (token) {
      try {
        const response = await api.get("/users/me", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data);
      } catch (error) {
        signOutUser(false);
      }
    }
  }

  useEffect(() => {
    getUserInfo();
  }, [userToken()]);

  async function signInUser(email, password) {
    const requestBody = { email, password };

    try {
      const response = await api.post("/auth/sign_in", requestBody);

      if (response && response.data.token) {
        window.localStorage.setItem("@app/token", response.data.token);
        await getUserInfo();
      } else {
        showSnackbar(
          "Ocorreu um erro no seu login. Verifique seus dados de acesso e tente novamente.",
          "error"
        );
      }
    } catch (e) {
      showSnackbar(
        `Ocorreu um erro no seu login. ${e.response?.data?.error || "Tente novamente."}`,
        "error"
      );
    }
  }

  async function signUpUser(email, password) {
    const requestBody = { email, password };

    try {
      const response = await api.post("/auth/sign_up", requestBody);

      if (response && response.data.token) {
        window.localStorage.setItem("@app/token", response.data.token);
        await getUserInfo();
      } else {
        showSnackbar(
          "Ocorreu um erro no seu cadastro. Verifique seus dados e tente novamente.",
          "error"
        );
      }
    } catch (e) {
      showSnackbar(
        `Ocorreu um erro no seu cadastro. ${e.response?.data?.error || "Tente novamente."}`,
        "error"
      );
    }
  }

  async function signOutUser(shouldRedirect = true) {
    setUser(null);
    window.localStorage.removeItem("@app/token");

    if (shouldRedirect && window.location.pathname !== "/sign_in") {
      setTimeout(() => (window.location = "/sign_in"), 500);
    }
  }

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <AuthContext.Provider
      value={{ user, signInUser, isAuthenticated, signOutUser, signUpUser }}
    >
      {props.children}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
}
