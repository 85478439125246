import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  CircularProgress,
  Pagination,
  Stack,
  Snackbar,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { MainLogo } from "../../../components/MainLogo";

const AdminDesignList = () => {
  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchDesigns = async (pageNumber = 1, query = "") => {
    setLoading(true);
    try {
      const response = await api.get("/admin/designs", {
        params: { page: pageNumber, per_page: perPage, search: query },
      });
      setDesigns(response.data.designs);
      setTotal(response.data.total);
      setTotalPages(response.data.total_pages);
      setPage(response.data.page);
      setSuccessMessage("Designs carregados com sucesso!");
    } catch (err) {
      console.error("Erro ao buscar designs", err);
      setError("Erro ao carregar a lista de designs.");
    } finally {
      setLoading(false);
    }
  };

  // Busca inicial ao montar o componente
  useEffect(() => {
    fetchDesigns(page, search);
  }, []);

  // Atualiza a busca enquanto o usuário digita (debounce de 500ms)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchDesigns(1, search);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [search]);

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchDesigns(value, search);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleDesignEdit = (designId) => {
    navigate(`/admin/designs/${designId}/edit`);
  };

  const handleDesignView = (designId) => {
    navigate(`/admin/designs/${designId}`);
  };

  const handleBack = () => {
    navigate("/admin");
  };

  return (
    <Container maxWidth="lg" sx={{ my: 4 }}>
      <MainLogo />

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Button variant="text" startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Voltar
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom>
        Lista de Designs
      </Typography>

      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <TextField
          label="Buscar por id, nome, ano, preço ou ativo"
          variant="outlined"
          size="small"
          value={search}
          onChange={handleSearchChange}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate("/admin/designs/new")}
          sx={{ whiteSpace: "nowrap" }}
        >
          Criar Design
        </Button>
      </Stack>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      ) : designs.length === 0 ? (
        <Typography color="text.secondary">
          Nenhum design encontrado.
        </Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Ano</TableCell>
                  <TableCell>Preço</TableCell>
                  <TableCell>Ativo</TableCell>
                  <TableCell>Foto</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {designs.map((design) => (
                  <TableRow key={design.id}>
                    <TableCell>{design.id}</TableCell>
                    <TableCell>{design.name}</TableCell>
                    <TableCell>{design.year}</TableCell>
                    <TableCell>{design.price_in_cents}</TableCell>
                    <TableCell>{design.visibility ? "Sim" : "Não"}</TableCell>
                    <TableCell>
                      {design.photo_url ? (
                        <img
                          src={design.photo_url}
                          alt={design.name}
                          style={{ width: 50, height: 50, objectFit: "cover" }}
                        />
                      ) : (
                        "Sem foto"
                      )}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleDesignView(design.id)}
                        >
                          Visualizar
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleDesignEdit(design.id)}
                        >
                          Editar
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {totalPages > 1 && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </>
      )}

      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
      />
    </Container>
  );
};

export default AdminDesignList;
