import React, { useState } from "react";
import api from "../../../services/api";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  FormControlLabel,
  Checkbox,
  Container,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { MainLogo } from "../../../components/MainLogo";

const AdminDesignCreate = () => {
  const [design, setDesign] = useState({
    name: "",
    price_in_cents: 0,
    photo_url: "",
    visibility: true,
    year: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setDesign({
      ...design,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post("/admin/designs", { design });
      alert("Design criado com sucesso!");
      navigate(`/admin/designs/${response.data.id}`);
    } catch (error) {
      console.error("Erro ao criar design", error);
      setErrors(error.response?.data?.errors || {});
    }
  };

  const handleBack = () => {
    navigate("/admin");
  };

  return (
    <Container
      sx={{
        mt: 4,
        mb: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <MainLogo />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Button variant="text" startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Voltar
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom>
        Criar Novo Design
      </Typography>

      <Paper sx={{ p: 4, maxWidth: 600, width: "100%" }}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Nome"
            name="name"
            value={design.name}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            error={!!errors.name}
            helperText={errors.name ? errors.name[0] : ""}
          />
          <TextField
            label="Preço (em centavos)"
            name="price_in_cents"
            type="number"
            value={design.price_in_cents}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            error={!!errors.price_in_cents}
            helperText={errors.price_in_cents ? errors.price_in_cents[0] : ""}
          />
          <TextField
            label="URL da Foto"
            name="photo_url"
            value={design.photo_url}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            error={!!errors.photo_url}
            helperText={errors.photo_url ? errors.photo_url[0] : ""}
          />
          <TextField
            label="Ano"
            name="year"
            value={design.year}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            error={!!errors.year}
            helperText={errors.year ? errors.year[0] : ""}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="visibility"
                checked={design.visibility}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Visível"
          />
          <Box sx={{ mt: 3 }}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Criar Design
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default AdminDesignCreate;
