import { useEffect, useState } from "react";
import { Box, Grid, Button, CircularProgress, Typography } from "@mui/material";
import { MainLogo } from "../../components/MainLogo";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import strftime from "strftime";

export function ShelvedOrders() {
  const [myOrders, setMyOrders] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const navigate = useNavigate();

  const fetchOrders = async () => {
    setRequestLoading(true);
    try {
      const response = await api.get("/orders");
      setMyOrders(response.data.orders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setRequestLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const paymentStatusDisplay = {
    paid: "Confirmado",
    pending: "Pendente",
    not_generated: "Boleto não gerado",
  };

  const handleUnshelveOrder = async (e, order) => {
    e.preventDefault();
    const dataObject = new FormData();
    dataObject.append("order[shelved]", false);

    setRequestLoading(true);
    try {
      await api.put(`/orders/${order.identifier}/shelve_order`, dataObject);
      fetchOrders();
    } catch (error) {
      console.error("Error unshelving order:", error);
    }
    setRequestLoading(false);
  };

  const renderOrder = (order) => {
    return (
      <Box key={order.identifier} sx={{ borderBottom: "1px solid #ccc", py: 2, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: "center" }}>
              <div dangerouslySetInnerHTML={{ __html: order.card_svg_html }} />
              <Typography variant="body2">{order.activation_code || order.identifier}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <Typography variant="body1">
              <strong>Modelo:</strong> {order.design_name || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Nome:</strong> {order.full_name || "N/A"}
            </Typography>
            <Typography variant="body1">
              <strong>Pedido iniciado em:</strong> {strftime("%d/%m/%Y - %H:%M", new Date(order.created_at))}
            </Typography>
            <Typography variant="body1">
              <strong>Status do pagamento:</strong> {paymentStatusDisplay[order.invoice_status]}
            </Typography>

            {order.status === "waiting_review" && (
              <Box sx={{ mt: 2, p: 2, backgroundColor: "primary.main", color: "white" }}>
                <Typography variant="body2">
                  <i className="fa fa-info-circle me-3"></i> Sua carteirinha está em processo de aprovação. Por favor aguarde.
                </Typography>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={3}>
            {order.approved_at && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                href={`orders/${order.identifier}`}
              >
                Abrir carteirinha
              </Button>
            )}

            {order.status === "adjustments_requested" && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                href={`orders/${order.identifier}`}
              >
                Realizar ajustes
              </Button>
            )}

            {!order.approved_at && order.status !== "adjustments_requested" && order.status !== "waiting_review" && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                href={`orders/${order.identifier}`}
              >
                Continuar pedido
              </Button>
            )}

            {order.invoice_url && (
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                href={order.invoice_url}
                target="_blank"
              >
                Visualizar boleto
              </Button>
            )}

            {order.shelved && (
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={(e) => handleUnshelveOrder(e, order)}
              >
                Ativar pedido
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box sx={{ padding: 3 }}>
      {requestLoading && <CircularProgress />}
      <Box sx={{ textAlign: "center", mb: 5 }}>
        <MainLogo />
        <Typography variant="h4" sx={{ fontWeight: 700, mt: 3 }}>
          Pedidos Arquivados
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 4 }}
        onClick={() => navigate("/orders")}
      >
        Pedidos Ativos
      </Button>

      {myOrders
        .filter((order) => order.shelved)
        .map((order) => renderOrder(order))}
    </Box>
  );
}
