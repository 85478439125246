/* global gtag */

import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import InputMask from "react-input-mask";
import {
  School,
  ArrowForward,
  Engineering,
  LocalLibrary,
  Handyman,
  Science,
  Build,
  Work,
  Badge,
  CalendarToday,
  MenuBook,
  AccountCircle,
  LocationCity,
} from "@mui/icons-material";
import { useOrder } from "../../../hooks/useOrder";
import { useAuth } from "../../../hooks/useAuth";
import api from "../../../services/api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';

const COURSE_TYPES = [
  { label: "Ensino Infantil", icon: <School />, value: "Ensino Infantil" },
  { label: "Ensino Fundamental", icon: <School />, value: "Ensino Fundamental" },
  { label: "Ensino Médio", icon: <School />, value: "Ensino Médio" },
  { label: "Técnico", icon: <Engineering />, value: "Técnico" },
  { label: "Graduação", icon: <LocalLibrary />, value: "Graduação" },
  { label: "Graduação Tecnológica", icon: <Build />, value: "Graduação tecnológica" },
  { label: "Pós-graduação", icon: <Badge />, value: "Pós-graduação" },
  { label: "Especialização", icon: <Handyman />, value: "Especialização" },
  { label: "Mestrado", icon: <Science />, value: "Mestrado" },
  { label: "Doutorado", icon: <Science />, value: "Doutorado" },
  { label: "MBA", icon: <Work />, value: "MBA" },
];

const validationSchema = Yup.object().shape({
  birthDate: Yup.string()
    .required("Data de nascimento é obrigatória")
    .matches(/^\d{2}\/\d{2}\/\d{4}$/, "Data de nascimento inválida"),
  customCourse: Yup.string().required("Curso é obrigatório"),
  courseType: Yup.string().required("Tipo de curso é obrigatório"),
  cpf: Yup.string().required("CPF é obrigatório").min(11, "CPF inválido"),
  cep: Yup.string().required("CEP é obrigatório").min(9, "CEP inválido"),
  streetName: Yup.string().required("Nome da rua é obrigatório"),
  streetNumber: Yup.string().required("Número é obrigatório"),
  neighborhood: Yup.string().required("Bairro é obrigatório"),
  fullName: Yup.string().required("Nome completo é obrigatório"),
  stateId: Yup.string().required("Estado é obrigatório"),
  cityId: Yup.string().required("Cidade é obrigatória"),
});

export function StudentDetails(props) {
  const { order, setOrder } = useOrder();
  const { user } = useAuth();
  const [requestLoading, setRequestLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/sign_in");
    }
    if (order.user_id !== user.id) {
      navigate("/orders");
    }
  }, [user, order, navigate]);

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    if (user) {
      gtag("event", "conversion", {
        send_to: "AW-529398833/ulnYCMC9o9IZELH4t_wB",
        email: user.email,
      });
    }
  }, [user]);

  const fetchStates = async () => {
    const response = await api.get(`/states`);
    setStates(response.data.states);
  };

  const fetchCities = async (stateId) => {
    if (!stateId) return;
    const response = await api.get(`/states/${stateId}/cities`);
    setCities(response.data.cities);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const data = {
      order: {
        birth_date: values.birthDate,
        custom_course: values.customCourse,
        course_type: values.courseType,
        cpf: values.cpf,
        cep: values.cep,
        city_id: values.cityId,
        street_name: values.streetName,
        street_number: values.streetNumber,
        street_complement: values.streetComplement,
        neighborhood: values.neighborhood,
        full_name: values.fullName,
      },
    };

    setRequestLoading(true);
    try {
      const response = await api.put(`orders/${order.identifier}`, data);
      setOrder(response.data);
    } catch (error) {
      console.error("Erro ao atualizar o pedido:", error);
    } finally {
      setRequestLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography
        variant="h4"
        textAlign="center"
        gutterBottom
        sx={{ fontWeight: 500, marginBottom: 4 }}
      >
        Informe sua data de nascimento, o seu curso e seus dados pessoais
      </Typography>

      <Formik
        initialValues={{
          birthDate: "",
          customCourse: "",
          courseType: "",
          cpf: "",
          streetName: "",
          streetNumber: "",
          streetComplement: "",
          neighborhood: "",
          cep: "",
          cityId: "",
          stateId: "",
          fullName: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          handleChange,
          setFieldValue,
          values,
          isValid,
          dirty,
          touched,
          errors,
        }) => (
          <Form>
            <Grid container spacing={2}>
              {/* Campos existentes: birthDate, customCourse, courseType */}
              <Grid item xs={12} md={6}>
                <InputMask
                  mask="99/99/9999"
                  value={values.birthDate}
                  onChange={(e) =>
                    setFieldValue("birthDate", e.target.value)
                  }
                >
                  {() => (
                    <TextField
                      fullWidth
                      name="birthDate"
                      label="Data de Nascimento"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarToday />
                          </InputAdornment>
                        ),
                      }}
                      error={
                        !!values.birthDate &&
                        !!ErrorMessage
                      }
                      helperText={<ErrorMessage name="birthDate" />}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} md={6}>
                <Field
                  as={TextField}
                  name="fullName"
                  fullWidth
                  label="Nome Completo *"
                  variant="outlined"
                  error={touched.fullName && Boolean(errors.fullName)}
                  helperText={<ErrorMessage name="fullName" />}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  as={TextField}
                  fullWidth
                  name="customCourse"
                  label="Curso"
                  variant="outlined"
                  onChange={handleChange}
                  value={values.customCourse}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MenuBook />
                      </InputAdornment>
                    ),
                  }}
                  error={
                    !!values.customCourse &&
                    !!ErrorMessage
                  }
                  helperText={<ErrorMessage name="customCourse" />}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Tipo de Curso:
                </Typography>
                <RadioGroup
                  value={values.courseType}
                  onChange={(e) =>
                    setFieldValue("courseType", e.target.value)
                  }
                  row
                >
                  {COURSE_TYPES.map((course) => (
                    <FormControlLabel
                      key={course.value}
                      value={course.value}
                      control={<Radio />}
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {course.icon}
                          <Typography sx={{ ml: 1 }}>
                            {course.label}
                          </Typography>
                        </Box>
                      }
                    />
                  ))}
                </RadioGroup>
                <Typography color="error">
                  <ErrorMessage name="courseType" />
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <InputMask
                  mask="999.999.999-99"
                  value={values.cpf}
                  onChange={handleChange}
                >
                  {() => (
                    <TextField
                      fullWidth
                      name="cpf"
                      label="CPF *"
                      variant="outlined"
                      error={!!values.cpf && values.cpf.length !== 14}
                      helperText={<ErrorMessage name="cpf" />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} md={6}>
                <InputMask
                  mask="99999-999"
                  value={values.cep}
                  onChange={handleChange}
                  onBlur={() => {
                    if (values.cep.length === 9) {
                      api
                        .get(`address_info_by_cep/${values.cep}`)
                        .then((response) => {
                          setFieldValue(
                            "streetName",
                            response.data.logradouro || ""
                          );
                          setFieldValue(
                            "neighborhood",
                            response.data.bairro || ""
                          );
                          setFieldValue(
                            "stateId",
                            response.data.state_id || ""
                          );
                          setFieldValue(
                            "cityId",
                            response.data.city_id || ""
                          );
                          fetchCities(response.data.state_id);
                        });
                    }
                  }}
                >
                  {() => (
                    <TextField
                      fullWidth
                      name="cep"
                      label="CEP *"
                      variant="outlined"
                      error={!!values.cep && values.cep.length !== 9}
                      helperText={<ErrorMessage name="cep" />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationCity />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  label="Estado *"
                  name="stateId"
                  value={values.stateId}
                  onChange={(e) => {
                    handleChange(e);
                    fetchCities(e.target.value);
                  }}
                  variant="outlined"
                  error={!values.stateId}
                  helperText={<ErrorMessage name="stateId" />}
                >
                  {states.map((state) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.acronym}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  fullWidth
                  label="Cidade *"
                  name="cityId"
                  value={values.cityId}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={!values.stateId}
                  error={!values.cityId}
                  helperText={<ErrorMessage name="cityId" />}
                >
                  {cities.map((city) => (
                    <MenuItem key={city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={10}>
                <TextField
                  fullWidth
                  name="streetName"
                  label="Rua *"
                  variant="outlined"
                  onChange={handleChange}
                  value={values.streetName}
                  error={!values.streetName}
                  helperText={<ErrorMessage name="streetName" />}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <TextField
                  fullWidth
                  name="streetNumber"
                  label="Número *"
                  variant="outlined"
                  onChange={handleChange}
                  value={values.streetNumber}
                  error={!values.streetNumber}
                  helperText={<ErrorMessage name="streetNumber" />}
                />
              </Grid>

              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  name="neighborhood"
                  label="Bairro *"
                  variant="outlined"
                  onChange={handleChange}
                  value={values.neighborhood}
                  error={!values.neighborhood}
                  helperText={<ErrorMessage name="neighborhood" />}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  name="streetComplement"
                  label="Complemento"
                  variant="outlined"
                  onChange={handleChange}
                  value={values.streetComplement}
                />
              </Grid>

              {/* Botão de Submissão */}
              <Grid item xs={12} textAlign="center" mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    !isValid ||
                    !dirty ||
                    requestLoading ||
                    isSubmitting
                  }
                  endIcon={<ArrowForward />}
                >
                  Adicionar Informações
                </Button>
              </Grid>

              {/* Indicador de Carregamento */}
              {requestLoading && (
                <Box display="flex" justifyContent="center" mt={3}>
                  <CircularProgress />
                </Box>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}