import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  MonetizationOn,
  ArrowForward,
  Discount,
  CheckCircle,
  Cancel,
} from "@mui/icons-material";
import { useOrder } from "../../../hooks/useOrder";
import api from "../../../services/api";
import priceBRL from "../../../services/priceBRL";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Esquema de validação com Yup
const validationSchema = Yup.object().shape({
  selectedDesign: Yup.object().required("Selecione um modelo de design"),
});

export function DesignChoice() {
  const { order, setOrder } = useOrder();
  const [designs, setDesigns] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Controla a visibilidade do modal de cupom
  const [showCouponModal, setShowCouponModal] = useState(false);

  // Função para buscar os designs da API
  const fetchDesigns = async () => {
    try {
      const response = await api.get("designs");
      setDesigns(response.data.designs);
    } catch (error) {
      console.error("Erro ao buscar designs:", error);
    }
  };

  // Função para validar o cupom e registrar para qual design ele se aplica
  const validateCoupon = async (
    couponCode,
    setFieldValue,
    selectedDesignId,
    kind
  ) => {
    if (!selectedDesignId) {
      setFieldValue("validCoupon", false);
      setFieldValue("statusCoupon", "Selecione um modelo primeiro");
      setFieldValue("couponDesignId", null);
      return;
    }
    if (!couponCode) {
      setFieldValue("validCoupon", false);
      setFieldValue("statusCoupon", "Não aplicado");
      setFieldValue("couponDesignId", null);
      return;
    }
    try {
      const response = await api.get(
        `orders/${order.identifier}/coupons/${couponCode}`,
        {
          params: {
            design_id: selectedDesignId,
            kind: kind,
          },
        }
      );
      const { result, error, discount } = response.data;
      if (result) {
        setFieldValue("validCoupon", true);
        setFieldValue("statusCoupon", discount); // discount em percentual, ex.: "50" para 50%
        setFieldValue("couponDesignId", selectedDesignId);
      } else {
        setFieldValue("validCoupon", false);
        setFieldValue("statusCoupon", error);
        setFieldValue("couponDesignId", null);
      }
    } catch (err) {
      console.error("Erro ao validar cupom:", err);
      setFieldValue("validCoupon", false);
      setFieldValue("statusCoupon", "Erro na validação do cupom");
      setFieldValue("couponDesignId", null);
    }
  };

  // Função de submissão do formulário
  async function handleSubmit(values, { setSubmitting }) {
    setRequestLoading(true);
    const dataObject = {
      order: {
        design_id: values.selectedDesign.id,
        kind: values.kind,
        coupon_code: values.couponCode,
      },
    };

    try {
      const response = await api.put(`orders/${order.identifier}`, dataObject);
      setRequestLoading(false);
      setSubmitting(false);

      if (response.data.errors.length) {
        setErrorMessage(JSON.stringify(response.data.errors));
        setErrorDialog(true);
      } else {
        setOrder(response.data);
      }
    } catch (error) {
      setRequestLoading(false);
      setSubmitting(false);
      setErrorMessage("Ocorreu um erro ao enviar o pedido.");
      setErrorDialog(true);
    }
  }

  // Carrega os designs ao montar o componente
  useEffect(() => {
    fetchDesigns();
  }, []);

  // Exibe o modal de cupom após 40 segundos
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCouponModal(true);
    }, 40000);
    return () => clearTimeout(timer);
  }, []);

  // Exibe o modal caso o mouse saia da viewport (exit intent)
  useEffect(() => {
    const handleMouseLeave = (e) => {
      if (e.clientY <= 0) {
        setShowCouponModal(true);
      }
    };
    document.addEventListener("mouseleave", handleMouseLeave);
    return () =>
      document.removeEventListener("mouseleave", handleMouseLeave);
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          selectedDesign: "",
          kind: "digital",
          couponCode: "",
          hasCoupon: false,
          validCoupon: undefined,
          statusCoupon: "Não aplicado",
          couponDesignId: null,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
          handleChange,
        }) => (
          <Form>
            <Box sx={{ padding: 3 }}>
              <Typography
                variant="h4"
                textAlign="center"
                gutterBottom
                sx={{ fontWeight: 500, marginBottom: 4 }}
              >
                Clique para escolher o modelo
              </Typography>

              <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
                {designs.map(
                  (design) =>
                    design.visibility && (
                      <Grid item xs={12} md={4} key={design.id}>
                        <Box
                          sx={{
                            position: "relative",
                            cursor: "pointer",
                            border:
                              values.selectedDesign &&
                              design.id === values.selectedDesign.id
                                ? "4px solid #800080"
                                : "2px solid transparent",
                            borderRadius: 2,
                            width: "100%",
                            overflow: "hidden",
                            transition: "border-color 0.3s ease-in-out",
                            boxShadow: "0px 4px 8px rgba(0,0,0,0.15)",
                            "&:hover .price-overlay": {
                              opacity: 1,
                              transform: "translateY(0)",
                            },
                          }}
                          onClick={() => {
                            // Define o design selecionado e revalida o cupom, se houver
                            setFieldValue("selectedDesign", design);
                            if (values.hasCoupon && values.couponCode) {
                              validateCoupon(
                                values.couponCode,
                                setFieldValue,
                                design.id,
                                values.kind
                              );
                            } else {
                              setFieldValue("validCoupon", false);
                              setFieldValue("statusCoupon", "Não aplicado");
                              setFieldValue("couponDesignId", null);
                            }
                          }}
                        >
                          <Box
                            component="img"
                            src={design.photo_url}
                            alt={`Design ${design.name}`}
                            sx={{
                              width: "100%",
                              display: "block",
                            }}
                          />

                          <Box
                            className="price-overlay"
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: "rgba(0, 0, 0, 0.6)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "white",
                              opacity:
                                values.selectedDesign &&
                                design.id === values.selectedDesign.id
                                  ? 1
                                  : 0,
                              transform:
                                values.selectedDesign &&
                                design.id === values.selectedDesign.id
                                  ? "translateY(0)"
                                  : "translateY(100%)",
                              transition:
                                "opacity 0.3s ease, transform 0.3s ease",
                            }}
                          >
                            <Box display="flex" alignItems="center">
                              <MonetizationOn sx={{ fontSize: "2rem", mr: 1 }} />
                              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                                {values.selectedDesign &&
                                design.id === values.selectedDesign.id &&
                                values.hasCoupon &&
                                values.validCoupon &&
                                values.couponDesignId === design.id
                                  ? priceBRL(
                                      Math.round(
                                        design.price_in_cents *
                                          (1 - parseFloat(values.statusCoupon) / 100)
                                      )
                                    )
                                  : priceBRL(design.price_in_cents)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )
                )}
              </Grid>

              {errors.selectedDesign && touched.selectedDesign && (
                <Typography color="error" textAlign="center">
                  {errors.selectedDesign}
                </Typography>
              )}

              <Box textAlign="center" mt={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.hasCoupon}
                      onChange={() => {
                        // Ao desmarcar, reseta os dados do cupom
                        if (values.hasCoupon) {
                          setFieldValue("couponCode", "");
                          setFieldValue("validCoupon", false);
                          setFieldValue("statusCoupon", "Não aplicado");
                          setFieldValue("couponDesignId", null);
                        }
                        setFieldValue("hasCoupon", !values.hasCoupon);
                      }}
                      color="primary"
                    />
                  }
                  label="Possuí cupom de desconto?"
                />
              </Box>

              {values.hasCoupon && (
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="couponCode"
                      label="Cupom de Desconto"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.couponCode}
                      onBlur={() =>
                        validateCoupon(
                          values.couponCode,
                          setFieldValue,
                          values.selectedDesign ? values.selectedDesign.id : null,
                          values.kind
                        )
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Discount />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Status"
                      variant="outlined"
                      value={values.statusCoupon}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {values.validCoupon ? (
                              <CheckCircle color="success" />
                            ) : (
                              <Cancel color="error" />
                            )}
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              )}

              <Box textAlign="center" mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!values.selectedDesign || requestLoading || isSubmitting}
                  sx={{ px: 5, py: 1.5 }}
                  endIcon={<ArrowForward />}
                >
                  Confirmar Modelo
                </Button>
              </Box>

              {requestLoading && (
                <Box display="flex" justifyContent="center" mt={4}>
                  <CircularProgress />
                </Box>
              )}

              <Dialog open={errorDialog} onClose={() => setErrorDialog(false)}>
                <DialogTitle>Erro!</DialogTitle>
                <DialogContent>
                  <Typography>{errorMessage}</Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setErrorDialog(false)} color="primary">
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Form>
        )}
      </Formik>

      <Dialog open={showCouponModal} onClose={() => setShowCouponModal(false)}>
        <DialogTitle>Espere um pouco!</DialogTitle>
        <DialogContent>
          <Typography>
            Você ganhou um cupom especial de 50% de desconto para fazer sua
            carteirinha agora mesmo! Aproveite essa chance.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Cupom: <strong>meiadameia</strong>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowCouponModal(false)}
            color="primary"
            variant="contained"
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
