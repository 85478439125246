import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Paper,
  Pagination,
  TextField,
  CircularProgress,
  Snackbar,
  Stack,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import api from "../../../services/api";
import { MainLogo } from "../../../components/MainLogo";

const AdminCouponList = () => {
  const [coupons, setCoupons] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchCoupons = async (pageNumber = 1, query = "") => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get("/admin/coupons", {
        params: { page: pageNumber, per_page: perPage, search: query },
      });
      setCoupons(response.data.coupons);
      setTotal(response.data.total);
      setPage(response.data.page);
      setTotalPages(response.data.total_pages);
      setSuccessMessage("Cupons carregados com sucesso!");
    } catch (err) {
      console.error("Erro ao carregar cupons:", err);
      setError("Erro ao carregar a lista de cupons. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoupons(page, search);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchCoupons(1, search);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [search]);

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchCoupons(value, search);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleCouponEdit = (couponId) => {
    navigate(`/admin/coupons/${couponId}/edit`);
  };

  const handleCouponDelete = async (couponId) => {
    const confirmed = window.confirm("Tem certeza que deseja excluir este cupom?");
    if (!confirmed) return;

    setError(null);
    try {
      await api.delete(`/admin/coupons/${couponId}`);
      setSuccessMessage("Cupom excluído com sucesso!");
      fetchCoupons(page, search);
    } catch (err) {
      console.error("Erro ao excluir cupom:", err);
      const errorMsg = err.response?.data?.errors?.join(", ") || "Erro ao excluir cupom. Tente novamente.";
      setError(errorMsg);
    }
  };

  const handleBack = () => {
    navigate("/admin");
  };

  const formatDateTime = (dateString) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleString("pt-BR", options);
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <MainLogo />

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Button variant="text" startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Voltar
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom>
        Lista de Cupons
      </Typography>

      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <TextField
          label="Buscar por código, limite ou validade"
          variant="outlined"
          size="small"
          value={search}
          onChange={handleSearchChange}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate("/admin/coupons/new")}
          sx={{ whiteSpace: "nowrap" }}
        >
          Criar Cupom
        </Button>
      </Stack>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : coupons.length === 0 ? (
        <Typography color="text.secondary">
          Nenhum cupom encontrado.
        </Typography>
      ) : (
        <Card variant="outlined">
          <CardHeader title="Cupons Disponíveis" sx={{ backgroundColor: "action.hover" }} />
          <CardContent>
            <List>
              {coupons.map((coupon) => (
                <ListItem key={coupon.id} divider>
                  <ListItemText
                    primary={`Código: ${coupon.code}`}
                    secondary={
                      <>
                        <Typography component="span">
                          Desconto: {coupon.discount}
                          {coupon.kind === "percentage" ? "%" : ""}
                        </Typography>
                        <br />
                        <Typography component="span">Tipo: {coupon.kind}</Typography>
                        <br />
                        <Typography component="span">
                          Limite de resgate: {coupon.redemption_limit}
                        </Typography>
                        <br />
                        <Typography component="span">
                          Utilizados: {coupon.orders_count} ({coupon.orders_percentage}%)
                        </Typography>
                        <br />
                        <Typography component="span">
                          Validade: {formatDateTime(coupon.valid_until)}
                        </Typography>
                        {coupon.designs && coupon.designs.length > 0 && (
                          <>
                            <br />
                            <Typography component="span">
                              Designs: {coupon.designs.map((d) => d.name).join(", ")}
                            </Typography>
                          </>
                        )}
                      </>
                    }
                  />
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleCouponEdit(coupon.id)}
                    >
                      Editar
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => handleCouponDelete(coupon.id)}
                      sx={{ ml: 1 }}
                    >
                      Excluir
                    </Button>
                  </Box>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}

      {totalPages > 1 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
        </Box>
      )}

      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
      />
    </Container>
  );
};

export default AdminCouponList;