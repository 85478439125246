import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Stack,
  Pagination,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import { MainLogo } from "../../../components/MainLogo";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import ListIcon from "@mui/icons-material/List";

const AdminSiteList = () => {
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalSites, setTotalSites] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [query, setQuery] = useState("");
  const perPage = 20;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      fetchSites();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  useEffect(() => {
    fetchSites();
  }, [page]);

  const fetchSites = async () => {
    setLoading(true);
    try {
      let url = `/admin/sites?page=${page}&per_page=${perPage}`;
      if (query) {
        url += `&query=${encodeURIComponent(query)}`;
      }
      const response = await api.get(url);
      if (response.data && Array.isArray(response.data.sites)) {
        setSites(response.data.sites);
        setTotalSites(response.data.total_sites);
        setTotalPages(response.data.total_pages);
      } else {
        console.error("Resposta inesperada da API:", response.data);
        setSites([]);
      }
    } catch (error) {
      console.error("Erro ao carregar sites:", error);
      setError("Erro ao carregar sites.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <MainLogo />
      <Typography variant="h4" gutterBottom>
        Lista de Sites
      </Typography>

      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          avatar={<ListIcon color="primary" />}
          title="Gerenciamento de Sites"
          sx={{ backgroundColor: "action.hover" }}
        />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Button
              component={Link}
              to="/admin/sites/new"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Novo Site
            </Button>
            <TextField
              label="Pesquisar"
              variant="outlined"
              value={query}
              onChange={handleSearchChange}
              size="small"
              fullWidth
              sx={{ maxWidth: { xs: "100%", sm: 400 } }}
            />
          </Box>

          <Paper variant="outlined" sx={{ p: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Nome</strong></TableCell>
                  <TableCell><strong>Título</strong></TableCell>
                  <TableCell><strong>Ativo</strong></TableCell>
                  <TableCell><strong>Ações</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography align="center">Carregando...</Typography>
                    </TableCell>
                  </TableRow>
                ) : error ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography align="center" color="error">
                        {error}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : sites.length ? (
                  sites.map((site) => (
                    <TableRow key={site.id}>
                      <TableCell>{site.name}</TableCell>
                      <TableCell>{site.title}</TableCell>
                      <TableCell>{site.active ? "Sim" : "Não"}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <Button
                            component={Link}
                            to={`/admin/sites/${site.id}`}
                            variant="outlined"
                            color="primary"
                            startIcon={<VisibilityIcon />}
                          >
                            Mostrar
                          </Button>
                          <Button
                            component={Link}
                            to={`/admin/sites/edit/${site.id}`}
                            variant="outlined"
                            color="secondary"
                            startIcon={<EditIcon />}
                          >
                            Editar
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={() => console.log("Excluir site", site.id)}
                          >
                            Excluir
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography align="center">
                        Nenhum site encontrado.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AdminSiteList;
