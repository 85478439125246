import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Checkbox,
  Slider,
  Radio,
  Grid,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../services/api";
import { MainLogo } from "../../components/MainLogo";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import { useOrder } from "../../hooks/useOrder";
import {
  School,
  ArrowForward,
  Engineering,
  LocalLibrary,
  Handyman,
  Science,
  WbSunny,
  Schedule,
  Brightness2,
  AllInclusive,
  Build,
  Work,
  Badge,
  CalendarToday,
  MenuBook,
  AccountCircle,
} from "@mui/icons-material";
import { PhotoCropper } from "../../components/PhotoCropper"; // importe o PhotoCropper

export function RequestAdjustments() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { order, isLoading, setOrder } = useOrder();
  const [requestLoading, setRequestLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    title: "",
    message: "",
    type: "error",
  });
  const [errors, setErrors] = useState(null);
  const [photoUpdated, setPhotoUpdated] = useState(false);
  const [photoChanged, setPhotoChanged] = useState(false);
  const [finalPhoto, setFinalPhoto] = useState(null);

  const [initialValues, setInitialValues] = useState({
    birthDate: "",
    customCourse: "",
    courseType: "",
    cpf: "",
    fullName: "",
    documentNumber: "",
    institutionName: "",
    registrationNumber: "",
    period: 1,
    time: "",
    hasNotInformedRegistrationNumber: false,
  });

  useEffect(() => {
    if (order) {
      setInitialValues({
        birthDate: order.birth_date || "",
        customCourse: order.custom_course || "",
        courseType: order.course_type || "",
        cpf: order.cpf || "",
        fullName: order.full_name || "",
        documentNumber: order.document_number || "",
        institutionName: order.institution_name || "",
        registrationNumber: order.registration_number || "",
        period: order.period || 1,
        time: order.time || "",
        hasNotInformedRegistrationNumber:
          order.has_not_informed_registration_number || false,
      });
    }
  }, [order]);

  const handleCroppedFile = (file) => {
    setFinalPhoto(file);
    setPhotoUpdated(true);
    setPhotoChanged(true);
  };
  
  const updateFields = async (values) => {
    setRequestLoading(true);
    const {
      birthDate,
      customCourse,
      courseType,
      cpf,
      fullName,
      documentNumber,
      institutionName,
      registrationNumber,
      period,
      time,
      hasNotInformedRegistrationNumber,
    } = values;

    const sanitizedCPF = cpf.replace(/\D/g, "");

    // Cria um FormData para enviar tanto os campos quanto a foto
    const formData = new FormData();

    // Se a foto foi atualizada, adiciona-a no FormData
    if (finalPhoto) {
      formData.append("order[card_photo]", finalPhoto);
    }

    formData.append("order[birth_date]", birthDate);
    formData.append("order[custom_course]", customCourse);
    formData.append("order[course_type]", courseType);
    formData.append("order[cpf]", sanitizedCPF);
    formData.append("order[full_name]", fullName);
    formData.append("order[document_number]", documentNumber);
    formData.append("order[institution_name]", institutionName);
    formData.append(
      "order[registration_number]",
      hasNotInformedRegistrationNumber ? "" : registrationNumber
    );
    formData.append("order[period]", period);
    formData.append("order[time]", time);
    formData.append(
      "order[has_not_informed_registration_number]",
      hasNotInformedRegistrationNumber
    );

    try {
      // Envia todos os dados em uma única requisição
      const response = await api.patch(`/orders/${id}/resend_to_review`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const { errors, success } = response.data;
      setErrors(errors);

      if (success) {
        setOrder(response.data);
        setDialogData({
          title: "Sucesso!",
          message: "Os dados foram atualizados com sucesso.",
          type: "success",
        });
        setDialogOpen(true);
        setTimeout(() => navigate("/orders"), 2000);
        return false;
      } else {
        setDialogData({
          title: "Erro!",
          message: "Confira as informações inseridas.",
          type: "error",
        });
        setDialogOpen(true);
        return true;
      }
    } catch (error) {
      console.error("Erro ao atualizar campos:", error);
      setDialogData({
        title: "Erro!",
        message: "Ocorreu um erro ao atualizar os campos.",
        type: "error",
      });
      setDialogOpen(true);
      return true;
    } finally {
      setRequestLoading(false);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    await updateFields(values);
    setSubmitting(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const COURSE_TYPES = [
    { label: "Ensino Infantil", icon: <School />, value: "Ensino Infantil" },
    { label: "Ensino Fundamental", icon: <School />, value: "Ensino Fundamental" },
    { label: "Ensino Médio", icon: <School />, value: "Ensino Médio" },
    { label: "Técnico", icon: <Engineering />, value: "Técnico" },
    { label: "Graduação", icon: <LocalLibrary />, value: "Graduação" },
    { label: "Graduação Tecnológica", icon: <Build />, value: "Graduação tecnológica" },
    { label: "Pós-graduação", icon: <Badge />, value: "Pós-graduação" },
    { label: "Especialização", icon: <Handyman />, value: "Especialização" },
    { label: "Mestrado", icon: <Science />, value: "Mestrado" },
    { label: "Doutorado", icon: <Science />, value: "Doutorado" },
    { label: "MBA", icon: <Work />, value: "MBA" },
  ];

  const validationSchema = Yup.object().shape({
    birthDate: Yup.string()
      .required("Data de nascimento é obrigatória")
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, "Data de nascimento inválida"),
    customCourse: Yup.string().required("Curso é obrigatório"),
    courseType: Yup.string().required("Tipo de curso é obrigatório"),
    cpf: Yup.string()
      .required("CPF é obrigatório")
      .matches(/^\d{11}$/, "CPF deve conter exatamente 11 números"),
    fullName: Yup.string().required("Nome completo é obrigatório"),
  });

  return (
    <Box sx={{ padding: 3 }}>
      <MainLogo />
      <Typography
        variant="h4"
        textAlign="center"
        gutterBottom
        sx={{ fontWeight: 500, marginBottom: 4 }}
      >
        Digitou alguma informação errada? Sem problemas, corrija abaixo! Altere apenas o que estiver errado e clique em enviar no final.
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 3 }}>
        <PhotoCropper onComplete={handleCroppedFile} />

        {photoUpdated && (
          <Typography variant="body1" color="success.main" textAlign="center" sx={{ mt: 2 }}>
            Foto atualizada com sucesso!
          </Typography>
        )}
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, handleChange, setFieldValue, values, isValid, dirty, touched, errors }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <InputMask
                    mask="999.999.999-99"
                    value={values.cpf}
                    onChange={(e) => {
                      const rawCPF = e.target.value.replace(/\D/g, "");
                      setFieldValue("cpf", rawCPF);
                    }}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        name="cpf"
                        label="CPF *"
                        variant="outlined"
                        error={!!values.cpf && values.cpf.length !== 11}
                        helperText={<ErrorMessage name="cpf" />}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="fullName"
                    fullWidth
                    label="Nome Completo *"
                    variant="outlined"
                    error={touched.fullName && Boolean(errors.fullName)}
                    helperText={<ErrorMessage name="fullName" />}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <InputMask
                    mask="99/99/9999"
                    value={values.birthDate}
                    onChange={(e) => setFieldValue("birthDate", e.target.value)}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        name="birthDate"
                        label="Data de Nascimento"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarToday />
                            </InputAdornment>
                          ),
                        }}
                        error={touched.birthDate && Boolean(errors.birthDate)}
                        helperText={<ErrorMessage name="birthDate" />}
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    name="documentNumber"
                    fullWidth
                    label="Número do Documento"
                    variant="outlined"
                    error={touched.documentNumber && Boolean(errors.documentNumber)}
                    helperText={<ErrorMessage name="documentNumber" />}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    label="Instituição de Ensino"
                    fullWidth
                    name="institutionName"
                    variant="outlined"
                    error={touched.institutionName && Boolean(errors.institutionName)}
                    helperText={<ErrorMessage name="institutionName" />}
                    sx={{ mb: 2 }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="customCourse"
                    label="Curso"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.customCourse}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MenuBook />
                        </InputAdornment>
                      ),
                    }}
                    error={touched.customCourse && Boolean(errors.customCourse)}
                    helperText={<ErrorMessage name="customCourse" />}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    Tipo de Curso:
                  </Typography>
                  <RadioGroup
                    value={values.courseType}
                    onChange={(e) => setFieldValue("courseType", e.target.value)}
                    row
                  >
                    {COURSE_TYPES.map((course) => (
                      <FormControlLabel
                        key={course.value}
                        value={course.value}
                        control={<Radio />}
                        label={
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {course.icon}
                            <Typography sx={{ ml: 1 }}>{course.label}</Typography>
                          </Box>
                        }
                      />
                    ))}
                  </RadioGroup>
                  <Typography color="error">
                    <ErrorMessage name="courseType" />
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    label="Número da Matrícula"
                    fullWidth
                    name="registrationNumber"
                    variant="outlined"
                    disabled={values.hasNotInformedRegistrationNumber}
                    error={touched.registrationNumber && Boolean(errors.registrationNumber)}
                    helperText={<ErrorMessage name="registrationNumber" />}
                    sx={{ mb: 2 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Field as={Checkbox} name="hasNotInformedRegistrationNumber" type="checkbox" />}
                    label="Não quero informar o número de matrícula"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography id="slider-label" gutterBottom>
                    Período: {values.period}
                  </Typography>
                  <Slider
                    aria-labelledby="slider-label"
                    value={typeof values.period === "number" ? values.period : 1}
                    onChange={(event, newValue) => setFieldValue("period", newValue)}
                    step={1}
                    marks
                    min={1}
                    max={10}
                    valueLabelDisplay="auto"
                    sx={{ mb: 2 }}
                  />
                  {touched.period && errors.period && (
                    <Typography color="error">{errors.period}</Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    Turno:
                  </Typography>
                  <Field name="time">
                    {({ field }) => (
                      <RadioGroup
                        {...field}
                        row
                        onChange={(event) => setFieldValue("time", event.target.value)}
                      >
                        <FormControlLabel
                          value="morning"
                          control={<Radio />}
                          label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <WbSunny />
                              <Typography sx={{ ml: 1 }}>Matutino</Typography>
                            </Box>
                          }
                        />
                        <FormControlLabel
                          value="afternoon"
                          control={<Radio />}
                          label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Schedule />
                              <Typography sx={{ ml: 1 }}>Vespertino</Typography>
                            </Box>
                          }
                        />
                        <FormControlLabel
                          value="night"
                          control={<Radio />}
                          label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Brightness2 />
                              <Typography sx={{ ml: 1 }}>Noturno</Typography>
                            </Box>
                          }
                        />
                        <FormControlLabel
                          value="integral"
                          control={<Radio />}
                          label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <AllInclusive />
                              <Typography sx={{ ml: 1 }}>Integral</Typography>
                            </Box>
                          }
                        />
                      </RadioGroup>
                    )}
                  </Field>
                  {touched.time && errors.time && (
                    <Typography color="error">{errors.time}</Typography>
                  )}
                </Grid>

                <Grid item xs={12} textAlign="center" mt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValid || (!dirty && !photoChanged) || requestLoading || isSubmitting}
                    endIcon={<ArrowForward />}
                  >
                    Solicitar alteração das informações
                  </Button>
                </Grid>

                {requestLoading && (
                  <Box display="flex" justifyContent="center" mt={3}>
                    <CircularProgress />
                  </Box>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      )}

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle
          sx={{
            color: dialogData.type === "error" ? "error.main" : "success.main",
          }}
        >
          {dialogData.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogData.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
