import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../services/api";

const AdminPostShow = () => {
  const { id } = useParams(); // ✅ Agora pegamos o ID corretamente
  const [post, setPost] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPost();
  }, []);

  const fetchPost = async () => {
    try {
      const response = await api.get(`/admin/posts/${id}`); // ✅ Agora usamos "id" corretamente
      setPost(response.data);
    } catch (error) {
      console.error("Erro ao buscar post", error);
    }
  };

  if (!post) {
    return <Typography>Carregando...</Typography>;
  }

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        {post.title}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Autor: {post.author}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        Tempo de leitura: {post.reading_time}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        Slug: {post.slug}
      </Typography>

      <Paper sx={{ p: 3, mt: 2 }}>
        <Typography variant="h6">Descrição</Typography>
        <Typography>{post.description}</Typography>
      </Paper>

      <Paper sx={{ p: 3, mt: 2 }}>
        <Typography variant="h6">Conteúdo</Typography>
        <Typography>{post.content}</Typography>
      </Paper>

      <Button
        sx={{ mt: 2 }}
        variant="contained"
        color="primary"
        onClick={() => navigate(`/admin/posts/edit/${post.id}`)} // ✅ Garantimos que usamos "post.id"
      >
        Editar
      </Button>
    </Box>
  );
};

export default AdminPostShow;
