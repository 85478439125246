import { createTheme } from '@mui/material/styles';
import { green, yellow, pink, cyan } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#800080', // Roxo
    },
    secondary: {
      main: pink[500], // Rosa
    },
    background: {
      default: 'rgb(47, 5, 73)', // Fundo escuro roxo
      paper: '#121212', // Fundo escuro cinza
    },
    text: {
      primary: '#FFFFFF', // Texto branco
      secondary: '#D27CFC', // Roxo claro
      orange: '#D27CFC', // (Parece um erro, deveria ser orange?)
    },
    error: {
      main: '#ccff00', // Amarelo limão
    },
    warning: {
      main: yellow[500], // Amarelo
    },
    info: {
      main: cyan[200], // Ciano claro
    },
    textconclued: {
      main: '#ffffff', // Branco (parece duplicado com text.primary)
    },
    success: {
      main: green[400], // Verde
    },
  },
  typography: {
    fontFamily: '"Nunito", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    h1: {
      fontFamily: '"Nunito", sans-serif',
      fontSize: '3rem',
      fontWeight: 700,
      color: '#FF4081', // Rosa claro
    },
    h2: {
      fontFamily: '"Nunito", sans-serif',
      fontSize: '2.5rem',
      fontWeight: 500,
      color: '#76FF03', // Verde limão
    },
    button: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 600,
      textTransform: 'uppercase',
      color: '#FFFFFF',
    },
    body1: {
      fontFamily: '"Nunito", sans-serif',
    },
    body2: {
      fontFamily: '"Nunito", sans-serif',
      fontSize: '1.5rem',
    },
    subtitle1: {
      fontFamily: '"Nunito", sans-serif',
    },
    subtitle2: {
      fontFamily: '"Nunito", sans-serif',
    },
    caption: {
      fontFamily: '"Nunito", sans-serif',
    },
    overline: {
      fontFamily: '"Nunito", sans-serif',
    },
  },
  components: {
    // Personalização do DataGrid
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212', // Fundo escuro do paper
          color: '#FFFFFF', // Texto branco
          border: '1px solid rgba(255, 255, 255, 0.1)', // Borda sutil
        },
        cell: {
          color: '#FFFFFF', // Texto das células
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)', // Linhas claras
        },
        columnHeaders: {
          backgroundColor: 'rgb(47, 5, 73)', // Fundo roxo escuro dos cabeçalhos
          color: '#D27CFC', // Texto roxo claro
          fontWeight: 600,
        },
        footerContainer: {
          backgroundColor: '#121212', // Fundo do rodapé
          color: '#FFFFFF',
        },
        sortIcon: {
          color: '#D27CFC', // Ícone de ordenação roxo claro
        },
        menuIcon: {
          color: '#FFFFFF', // Ícone do menu
        },
        overlay: {
          backgroundColor: 'rgba(47, 5, 73, 0.9)', // Overlay de loading
          color: '#FFFFFF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#FFFFFF', // Garante que os botões tenham texto branco
        },
      },
    },
  },
});

export default theme;