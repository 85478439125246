import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  Button,
  Container,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { MainLogo } from "../../../components/MainLogo";

const AdminDesignShow = () => {
  const { id } = useParams();
  const [design, setDesign] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDesign = async () => {
      try {
        const response = await api.get(`/admin/designs/${id}`);
        setDesign(response.data);
      } catch (error) {
        console.error("Erro ao buscar design", error);
      }
    };
    fetchDesign();
  }, [id]);

  if (!design) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container sx={{ mt: 4, mb: 4, display: "flex", flexDirection: "column", alignItems: "center" }}>
      <MainLogo />

      <Box sx={{ width: "100%", display: "flex", justifyContent: "center", mb: 2 }}>
        <Button variant="text" startIcon={<ArrowBackIcon />} onClick={() => navigate("/admin")}>
          Voltar
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom>
        Detalhes do Design
      </Typography>

      <Paper sx={{ p: 4, maxWidth: 600, width: "100%" }}>
        <Typography variant="h5">{design.name}</Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong>Preço:</strong> ${(design.price_in_cents / 100).toFixed(2)}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong>Ano:</strong> {design.year}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <img src={design.photo_url} alt={design.name} width="100%" />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/admin/designs/${id}/edit`)}
          >
            Editar Design
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default AdminDesignShow;
