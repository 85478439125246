import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  TextField,
  MenuItem
} from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
import api from "../../services/api";
import OrdersByStatusChart from "./statistics/OrdersByStatusChart";
import InvoiceStatisticsChart from "./statistics/InvoiceStatisticsChart";
import InvoiceConversionChart from "./statistics/InvoiceConversionChart";
import AdsCostPerSaleChart from "./statistics/AdsCostPerSaleChart"; // Importa o novo componente
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { MainLogo } from "../../components/MainLogo";

const orderStatusOptions = [
  { label: "Todos", value: "" },
  { label: "started", value: "started" },
  { label: "design_filled", value: "design_filled" },
  { label: "invoice_generated", value: "invoice_generated" },
  { label: "invoice_details_advanced", value: "invoice_details_advanced" },
  { label: "student_details_filled", value: "student_details_filled" },
  { label: "photo_uploaded", value: "photo_uploaded" },
  { label: "personal_identification_uploaded", value: "personal_identification_uploaded" },
  { label: "institution_identification_filled", value: "institution_identification_filled" },
  { label: "waiting_review", value: "waiting_review" },
  { label: "approved", value: "approved" },
  { label: "adjustments_requested", value: "adjustments_requested" },
  { label: "rejected", value: "rejected" },
  { label: "blocked", value: "blocked" }
];

const invoiceStatusOptions = [
  { label: "Todos", value: "" },
  { label: "paid", value: "paid" },
  { label: "partially_paid", value: "partially_paid" },
  { label: "pending", value: "pending" },
  { label: "invoice_not_generated", value: "invoice_not_generated" },
  { label: "expired", value: "expired" },
  { label: "canceled", value: "canceled" },
  { label: "in_protest", value: "in_protest" },
  { label: "chargeback", value: "chargeback" },
  { label: "refunded", value: "refunded" },
  { label: "in_analysis", value: "in_analysis" },
  { label: "externally_paid", value: "externally_paid" },
  { label: "gift", value: "gift" }
];

const AdminStatistics = () => {
  const [orders, setOrders] = useState([]);
  const [adsTrackers, setAdsTrackers] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [uniqueInvoiceStatuses, setUniqueInvoiceStatuses] = useState([]);
  const [showOrdersChart, setShowOrdersChart] = useState(false);
  const [showInvoiceChart, setShowInvoiceChart] = useState(false);
  const [showInvoiceConversionChart, setShowInvoiceConversionChart] = useState(false);
  const [showAdsCostChart, setShowAdsCostChart] = useState(false);

  const navigate = useNavigate();

  const fetchAdsTrackers = async (params) => {
    try {
      const response = await api.get("/admin/statistics/ads_statistics", { params });
      if (response.data && Array.isArray(response.data.ads_trackers)) {
        setAdsTrackers(response.data.ads_trackers);
      }
    } catch (error) {
      console.error("Erro ao buscar dados de ads:", error);
    }
  };

  const fetchOrders = async () => {
    try {
      const params = {};
      if (startDate) {
        params.filter_minimum_date = dayjs.utc(startDate).startOf("day").format();
      }
      if (endDate) {
        params.filter_maximum_date = dayjs.utc(endDate).endOf("day").format();
      }
      if (invoiceStatus) {
        params.filter_invoice_status = invoiceStatus;
      }
      if (orderStatus) {
        params.filter_order_status = orderStatus;
      }
      const response = await api.get("/admin/statistics/order_statistics", { params });
      if (response.data && Array.isArray(response.data.orders)) {
        setOrders(response.data.orders);
        const statuses = [
          ...new Set(
            response.data.orders
              .map(order => order.invoice_status)
              .filter(Boolean)
          )
        ];
        setUniqueInvoiceStatuses(statuses);
      }
      // Busca também os dados de ads
      fetchAdsTrackers(params);
    } catch (error) {
      console.error("Erro ao buscar pedidos:", error);
    }
  };

  const clearFilters = () => {
    setStartDate("");
    setEndDate("");
    setInvoiceStatus("");
    setOrderStatus("");
    setOrders([]);
    setAdsTrackers([]);
    setUniqueInvoiceStatuses([]);
    setShowOrdersChart(false);
    setShowInvoiceChart(false);
    setShowInvoiceConversionChart(false);
    setShowAdsCostChart(false);
  };

  return (
    <Container maxWidth="xl">
      <MainLogo />
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Button variant="text" startIcon={<ArrowBackIcon />} onClick={() => navigate("/admin")}>
          Voltar
        </Button>
      </Box>
      <Typography variant="h4" gutterBottom>
        Estatísticas de Pedidos
      </Typography>

      <Grid container spacing={3} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            type="date"
            label="Data Inicial"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            type="date"
            label="Data Final"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            label="Status da Fatura"
            value={invoiceStatus}
            onChange={(e) => setInvoiceStatus(e.target.value)}
          >
            {invoiceStatusOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            select
            label="Status do Pedido"
            value={orderStatus}
            onChange={(e) => setOrderStatus(e.target.value)}
          >
            {orderStatusOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Box sx={{ mb: 3 }}>
        <Button variant="contained" color="primary" onClick={fetchOrders}>
          Filtrar
        </Button>
        <Button variant="outlined" color="secondary" onClick={clearFilters} sx={{ ml: 2 }}>
          Limpar Filtros
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card onClick={() => setShowOrdersChart(true)} sx={{ cursor: "pointer" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Pedidos por Status
              </Typography>
              {showOrdersChart && (
                <OrdersByStatusChart
                  orders={orders}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card onClick={() => setShowInvoiceChart(true)} sx={{ cursor: "pointer" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Faturamento (R$)
              </Typography>
              {showInvoiceChart && (
                <InvoiceStatisticsChart
                  orders={orders}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card onClick={() => setShowInvoiceConversionChart(true)} sx={{ cursor: "pointer" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Conversão de Faturas por Dia
              </Typography>
              {showInvoiceConversionChart && (
                <InvoiceConversionChart
                  orders={orders}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card onClick={() => setShowAdsCostChart(true)} sx={{ cursor: "pointer" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Custo por Venda
              </Typography>
              {showAdsCostChart && (
                <AdsCostPerSaleChart
                  orders={orders}
                  adsTrackers={adsTrackers}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminStatistics;
