import React, { useEffect, useState } from "react";
import { Container, Typography, Table, Box, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Snackbar } from "@mui/material";
import api from "../../services/api";
import { MainLogo } from "../../components/MainLogo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const AdsTracker = () => {
  const [adsTrackers, setAdsTrackers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(""); // Adicionado para mostrar sucesso
  const [editedValues, setEditedValues] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchAdsTrackers();
  }, []);

  const fetchAdsTrackers = async () => {
    try {
      const response = await api.get("/admin/ads_trackers");
      if (response.data) {
        // Filtra datas que não são no futuro
        const validData = response.data.filter(tracker => {
          const referenceDate = new Date(tracker.reference_date);
          return referenceDate <= new Date();  // Exclui datas futuras
        });

        // Ordena pela data (mais recente primeiro)
        const sortedData = validData.sort(
          (a, b) => new Date(b.reference_date) - new Date(a.reference_date)
        );

        setAdsTrackers(sortedData.slice(0, 30)); // Pega os últimos 30 dias
      }
    } catch (err) {
      console.error("Erro ao buscar AdsTrackers:", err);
      setError("Erro ao buscar dados.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (id, field, value) => {
    setEditedValues((prev) => ({
      ...prev,
      [id]: { ...prev[id], [field]: value },
    }));
  };

  const handleSave = async (id) => {
    if (!editedValues[id]) return;
    try {
      await api.put(`/admin/ads_trackers/${id}`, { ads_tracker: editedValues[id] });
      setSuccessMessage("Dados atualizados com sucesso!");  // Mensagem de sucesso
      fetchAdsTrackers(); // Recarrega os dados após a atualização
    } catch (err) {
      console.error("Erro ao atualizar:", err);
      setError("Erro ao atualizar.");
    }
  };

  if (loading) return <Typography>Carregando...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="lg">
      <MainLogo />
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/admin")}
        >
          Voltar
        </Button>
      </Box>
      <Typography variant="h4" gutterBottom>
        Rastreamento de Anúncios (Últimos 30 dias)
      </Typography>

      {/* Snackbar para mostrar mensagem de sucesso */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
      />

      <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Google (R$)</TableCell>
              <TableCell>Facebook (R$)</TableCell>
              <TableCell>Bing (R$)</TableCell>
              <TableCell>Google Clicks</TableCell> {/* Adicionando clicks */}
              <TableCell>Google Impressions</TableCell> {/* Adicionando impressions */}
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adsTrackers.map((tracker) => (
              <TableRow key={tracker.id}>
                <TableCell>{new Date(tracker.reference_date).toLocaleDateString()}</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={editedValues[tracker.id]?.google_cost ?? tracker.google_cost}
                    onChange={(e) => handleInputChange(tracker.id, "google_cost", e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={editedValues[tracker.id]?.facebook_cost ?? tracker.facebook_cost}
                    onChange={(e) => handleInputChange(tracker.id, "facebook_cost", e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={editedValues[tracker.id]?.bing_cost ?? tracker.bing_cost}
                    onChange={(e) => handleInputChange(tracker.id, "bing_cost", e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>

                <TableCell>
                  <TextField
                    type="number"
                    value={editedValues[tracker.id]?.google_clicks ?? tracker.google_clicks}
                    onChange={(e) => handleInputChange(tracker.id, "google_clicks", e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={editedValues[tracker.id]?.google_impressions ?? tracker.google_impressions}
                    onChange={(e) => handleInputChange(tracker.id, "google_impressions", e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>

                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSave(tracker.id)}
                    disabled={!editedValues[tracker.id]}
                  >
                    Salvar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AdsTracker;
