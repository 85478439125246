import { Box, Typography, Container, List, ListItem } from "@mui/material";
import { styled } from "@mui/system";

const TermsContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
}));

export function Terms() {
  return (
    <TermsContainer maxWidth="md">
      <Typography variant="h4" gutterBottom>
        TERMOS DE USO
      </Typography>

      <Typography variant="h6" gutterBottom>
        I – CARACTERÍSTICAS
      </Typography>
      <Typography paragraph>
        1.1. A Carteira de Identificação Estudantil – CIE Digital fornecida pelo Clube
        do Estudante, atua de acordo com a Lei nº 12.933/13, cumprindo todos os
        requisitos estipulados na referida lei, para assegurar ao seu
        consumidor os benefícios estabelecidos na referida lei tais como acesso
        a salas de cinema, cineclubes, teatros, espetáculos musicais e
        circenses e eventos educativos, esportivos, de lazer e de
        entretenimento, em todo o território nacional, mediante o pagamento da
        metade do preço do ingresso efetivamente cobrado do público em geral,
        nos termos da referida lei.
      </Typography>

      <Typography paragraph>
        1.2. O Clube do Estudante é uma empresa contemporânea que atua na área
        de auxílio no gerenciamento da gestão de emissão de Carteira de Identificação
        Estudantil, com intuito de oferecer ao estudante uma CIE que cumpre a
        legislação vigente e proporciona ao cliente um trabalho final que atenda
        as expectativas do mesmo em relação ao escopo, prazo e custos da CIE.
      </Typography>

      <Typography variant="h6" gutterBottom>
        II - QUEM PODE SOLICITAR?
      </Typography>
      <Typography paragraph>
        2.1. Podem solicitar a CIE Digital fornecida pelo Clube do Estudante os
        estudantes regularmente matriculados nos cursos de nível fundamental,
        médio, superior e pós-graduação, nas modalidades presencial e à
        distância, conforme Título V da Lei nº 9.394/96.
      </Typography>

      <Typography variant="h6" gutterBottom>
        III - QUEM NÃO PODE SOLICITAR?
      </Typography>
      <Typography paragraph>
        3.1. Não podem solicitar a CIE Digital fornecida pelo Clube do Estudante aqueles
        que não são matriculados em cursos de nível fundamental, médio, superior
        e pós-graduação reconhecidos pelo MEC e estudantes de outros cursos não
        reconhecidos pelo MEC, tais como de informática, de idiomas, cursos
        preparatórios para concursos e vestibulares, cursos de especialização de
        curta duração.
      </Typography>

      <Typography variant="h6" gutterBottom>
        IV - COMO SOLICITAR?
      </Typography>
      <Typography paragraph>
        4.1. Para solicitar a CIE, basta acessar o site www.estudante.cc e
        enviar digitalmente os documentos abaixo, bem como proceder ao pagamento
        na forma indicada no website em referência.
      </Typography>
      <List>
        <ListItem>1 (uma) Foto 3x4.</ListItem>
        <ListItem>
          1 (uma) Cópia Simples de um documento de identificação pessoal (RG,
          CNH, RNE ou passaporte válidos).
        </ListItem>
        <ListItem>
          1 (uma) Cópia Simples do comprovante de matrícula do ano corrente.
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        V - DOS DIREITOS DO ESTUDANTE
      </Typography>
      <Typography paragraph>
        5.1. O portador da CIE terá o direito de ser reconhecido como estudante
        e, desta forma, ter acesso a todas vantagens e benefícios concedidos aos
        estudantes, na forma da legislação federal, estadual e municipal, como,
        por exemplo, pagamento de metade do preço para o ingresso em
        estabelecimentos de diversão, eventos culturais, esportivos e de lazer,
        nos termos da legislação vigente.
      </Typography>

      <Typography variant="h6" gutterBottom>
        VI – DADOS DO ESTUDANTE
      </Typography>
      <Typography paragraph>
        6.1. A empresa Clube do Estudante tem permissão para o acesso e uso dos
        dados pessoais fornecidos pelo estudante visando a conferência de
        documentos e outras análises a seguir.
      </Typography>

      <Typography variant="h6" gutterBottom>
        VII – VALIDADE
      </Typography>
      <Typography paragraph>
        7.1. A Carteira de Identificação Estudantil será válida até o último dia
        do mês de março do ano seguinte ao de sua solicitação.
      </Typography>

      <Typography variant="h6" gutterBottom>
        VIII – IRREGULARIDADES
      </Typography>
      <Typography paragraph>
        8.1. O estudante, ao aceitar as regras do presente regulamento, declara
        que todas as informações por ele fornecidas são verdadeiras e que tem
        conhecimento que a apresentação de documentos não verdadeiros ou a
        declaração de informações falsas ou ainda a omissão de informações
        verdadeiras e relevantes podem ser consideradas crime de falsificação de
        documentos e crime de falsidade ideológica, conforme artigos 298 e 299,
        do Código Penal Brasileiro. As penas para tais condutas variam de 1 (um)
        a 5 (cinco) anos de reclusão. Verificadas evidências de tais condutas, as
        Entidades poderão comunicar o fato às autoridades policiais competentes.
      </Typography>
      <Typography paragraph>
        8.2. Caso seja verificada irregularidade, inconsistência ou imprecisão
        nas informações declaradas pelo estudante ou nos documentos por ele
        apresentados, a CIE não será emitida até que o problema seja sanado pelo
        estudante.
      </Typography>

      <Typography variant="h6" gutterBottom>
        IX - DA DESISTÊNCIA
      </Typography>

      <Typography paragraph>
        9.1. A Carteira de Identidade Estudantil é personalizada,
        individualizada para cada estudante e feita sob encomenda, logo, o Clube
        do Estudante não efetua cancelamento da carteira de identidade
        estudantil, tendo em vista as características acima citadas.
      </Typography>

      <Typography variant="h6" gutterBottom>
        X - DISPOSIÇÕES FINAIS
      </Typography>
      <Typography paragraph>
        10.1. O Clube do Estudante é isento de qualquer responsabilidade em
        decorrência da revogação ou alteração das leis que concedem benefícios a
        estudantes em eventos e estabelecimentos culturais, educativos, de
        diversão, esporte e lazer.
      </Typography>
    </TermsContainer>
  );
}
