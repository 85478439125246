import React, { useEffect, useState } from "react";
import { Button, Box, Grid, Typography, Snackbar, Alert, Divider } from "@mui/material";
import { ContentCopy, ArrowForward, Autorenew, Receipt } from '@mui/icons-material';
import { useOrder } from "../../../hooks/useOrder";
import { useAuth } from "../../../hooks/useAuth";
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";

export function InvoiceDetails() {
  const { order, setOrder, checkPayment } = useOrder();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/sign_in");
      return;
    }

    if (!order || !order.user_id || order.user_id !== user.id) {
      navigate("/orders");
    }
  }, [user, order, navigate]);

  function handleCopyCode(e) {
    e.preventDefault();
    navigator.clipboard.writeText(order.pix_qrcode_text);

    setSnackbarMessage("Código do Pix copiado com sucesso!");
    setSnackbarSeverity("success");
    setOpenSnackbar(true);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!order || !order.identifier) {
      setSnackbarMessage("Erro: Pedido não encontrado.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (order.invoice_status !== "paid" && order.invoice_status !== "gift") {
      setSnackbarMessage("Pagamento pendente. Verifique se já foi pago.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }    

    try {
      const dataObject = new FormData();
      dataObject.append("order[phone_number]", order.phone_number);

      const response = await api.put(`orders/${order.identifier}`, dataObject);
      setOrder(response.data);

      setSnackbarMessage("Pedido enviado com sucesso!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage("Erro ao enviar o formulário.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      console.error("Error during form submission: ", error);
    }
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ mb: 5, p: 5, borderRadius: 2, boxShadow: 3, backgroundColor: "rgb(221, 199, 221)" }}>
      <Typography variant="h4" textAlign="center" gutterBottom sx={{ color: "black", fontWeight: 700 }}>
        Detalhes da Fatura
      </Typography>

      <Typography variant="h6" textAlign="center" gutterBottom sx={{ color: "black", fontWeight: 500 }}>
        <strong style={{ textTransform: "capitalize", color: "black" }}>Estudante</strong>, você está quase lá! <br />
        Faça o pagamento e garanta a sua <strong>carteirinha!</strong>
      </Typography>

      <Divider sx={{ my: 3 }} />

      <Box sx={{ textAlign: "center", mt: 3 }}>
        <img src={order.pix_qrcode} alt="QR Code" style={{ width: "300px", height: "300px" }} />
      </Box>

      <Grid container spacing={2} sx={{ mt: 3 }} justifyContent="center">
        <Grid item>
          <Button
            variant="contained"
            color="success"
            startIcon={<ContentCopy />}
            onClick={handleCopyCode}
            sx={{ boxShadow: 3, minWidth: "auto", padding: "8px 12px" }}
          >
            Copiar Código Pix
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="warning"
            href={order.invoice_url}
            target="_blank"
            rel="noreferrer"
            startIcon={<Receipt />}
            sx={{ boxShadow: 3, minWidth: "auto", padding: "8px 12px" }}
          >
            Ver fatura completa
          </Button>
        </Grid>
      </Grid>

      <Grid container sx={{ justifyContent: "center", mt: 2 }}>
        <Grid item>
          <Button variant="contained" color="secondary" startIcon={<Autorenew />} onClick={() => checkPayment()}>
            Verificar Pagamento
          </Button>
        </Grid>
      </Grid>

      <Box textAlign="center" mt={4}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForward />}
          onClick={handleSubmit}
          sx={{ py: 2, fontSize: "1.1rem", fontWeight: 600, boxShadow: 3, mt: 3 }}
        >
          Continuar o Pedido
        </Button>
      </Box>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
