import { Box } from "@mui/material";

export function SideBarRight() {
  return (
    <Box
      sx={{
        display: { xs: "none", md: "block" },
        padding: 2,
        height: "100%",
        boxShadow: 3,
      }}
    >
    </Box>
  );
}
