import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Chip,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  HourglassEmpty as HourglassEmptyIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  Block as BlockIcon,
  Info as InfoIcon,
  ThumbUp as ThumbUpIcon,
  Cancel as CancelIcon,
  Autorenew as AutorenewIcon,
  AssignmentLate as AssignmentLateIcon,
  Payment as PaymentIcon,
  Replay as ReplayIcon,
  Logout as LogoutIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  Archive as ArchiveIcon,
  DoneAll as DoneAllIcon,
  Download as DownloadIcon,
  Refresh as RefreshIcon, // Adicionado para o botão de regenerar fatura
} from "@mui/icons-material";
import api from "../../services/api";
import strftime from "strftime";
import { MainLogo } from "../../components/MainLogo";
import { AuthContext } from "../../contexts/AuthContext";

export function Orders() {
  const [myOrders, setMyOrders] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { signOutUser } = useContext(AuthContext);

  const fetchOrders = async () => {
    try {
      setRequestLoading(true);
      const response = await api.get("/orders");
      setMyOrders(response.data.orders);
    } catch (error) {
      setErrorMessage("Erro ao buscar pedidos. Por favor, tente novamente.");
      console.error("Erro ao buscar pedidos:", error);
    } finally {
      setRequestLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleDownloadPdf = async (identifier) => {
    try {
      setRequestLoading(true);
      const response = await api.get(`/orders/${identifier}/download_pdf`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `carteirinha-${identifier}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setSuccessMessage("PDF baixado com sucesso!");
    } catch (error) {
      console.error("Erro ao baixar PDF:", error);
      setErrorMessage("Erro ao baixar o PDF. Por favor, tente novamente.");
    } finally {
      setRequestLoading(false);
    }
  };

  const handleRegenerateInvoice = async (identifier) => {
    try {
      setRequestLoading(true);
      const response = await api.post(`/orders/${identifier}/regenerate_invoice`);
      setSuccessMessage(response.data.message);
      fetchOrders(); // Atualiza a lista de pedidos após regenerar a fatura
    } catch (error) {
      console.error("Erro ao regenerar fatura:", error);
      setErrorMessage(error.response?.data?.error || "Erro ao regenerar fatura. Tente novamente.");
    } finally {
      setRequestLoading(false);
    }
  };

  const handleShelveOrder = async (e, order) => {
    e.preventDefault();
    try {
      setRequestLoading(true);
      await api.put(`/orders/${order.identifier}/shelve_order`, { order: { shelved: true } });
      setSuccessMessage("Pedido arquivado com sucesso!");
      fetchOrders();
    } catch (error) {
      setErrorMessage("Erro ao arquivar o pedido. Por favor, tente novamente.");
      console.error("Erro ao arquivar pedido:", error);
    } finally {
      setRequestLoading(false);
    }
  };

  const handleCancelInvoice = async (e, order) => {
    e.preventDefault();
    try {
      setRequestLoading(true);
      await api.put(`/orders/${order.identifier}/cancel_invoice`);
      setSuccessMessage("Fatura cancelada com sucesso!");
      fetchOrders();
    } catch (error) {
      setErrorMessage("Erro ao cancelar a fatura. Por favor, tente novamente.");
      console.error("Erro ao cancelar a fatura:", error);
    } finally {
      setRequestLoading(false);
    }
  };

  const paymentStatusDisplay = {
    invoice_not_generated: { label: "Não gerado", icon: <BlockIcon color="action" /> },
    pending: { label: "Pendente", icon: <HourglassEmptyIcon color="warning" /> },
    paid: { label: "Pago", icon: <CheckCircleIcon color="success" /> },
    partially_paid: { label: "Parcialmente Pago", icon: <InfoIcon color="info" /> },
    expired: { label: "Expirado", icon: <WarningIcon color="disabled" /> },
    canceled: { label: "Cancelado", icon: <CancelIcon color="error" /> },
    in_protest: { label: "Em Protesto", icon: <AssignmentLateIcon color="error" /> },
    chargeback: { label: "Chargeback", icon: <ReplayIcon color="error" /> },
    refunded: { label: "Reembolsado", icon: <AutorenewIcon color="info" /> },
    in_analysis: { label: "Em Análise", icon: <InfoIcon color="primary" /> },
    externally_paid: { label: "Pago Externamente", icon: <PaymentIcon color="success" /> },
    default: { label: "Status desconhecido", icon: <InfoIcon color="disabled" /> },
  };

  const orderStatusDisplay = {
    started: { label: "Iniciado", icon: <BlockIcon color="action" /> },
    design_filled: { label: "Modelo escolhido", icon: <InfoIcon color="disabled" /> },
    photo_uploaded: { label: "Foto enviada", icon: <InfoIcon color="disabled" /> },
    invoice_generated: { label: "Fatura gerada", icon: <WarningIcon color="warning" /> },
    invoice_details_advanced: { label: "Fatura avançada", icon: <WarningIcon color="warning" /> },
    student_details_filled: { label: "Detalhes preenchidos", icon: <ThumbUpIcon color="primary" /> },
    personal_identification_uploaded: { label: "Documento enviado", icon: <ThumbUpIcon color="primary" /> },
    institution_identification_filled: { label: "Comprovante enviado", icon: <ThumbUpIcon color="primary" /> },
    waiting_review: { label: "Em revisão", icon: <HourglassEmptyIcon color="error" /> },
    approved: { label: "Aprovado", icon: <CheckCircleIcon color="success" /> },
    adjustments_requested: { label: "Em ajustes", icon: <EditIcon color="action" /> },
    rejected: { label: "Rejeitado", icon: <CancelIcon color="disabled" /> },
    blocked: { label: "Bloqueado", icon: <BlockIcon color="error" /> },
    default: { label: "Status desconhecido", icon: <InfoIcon color="disabled" /> },
  };

  const renderOrder = (order) => (
    <Card key={order.identifier} sx={{ marginBottom: 4, boxShadow: 3, position: "relative" }}>
      <CardContent>
        <Grid container spacing={2}>
          {order.approved_at && (
            <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Tooltip title="Pedido concluído">
                <DoneAllIcon color="success" sx={{ fontSize: 80 }} />
              </Tooltip>
            </Grid>
          )}

          <Grid item xs={12} md={order.approved_at ? 5 : 7}>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                <strong>Etapa:</strong>
              </Typography>
              <Chip
                label={orderStatusDisplay[order.status]?.label || "Status desconhecido"}
                icon={orderStatusDisplay[order.status]?.icon || <ErrorIcon color="error" />}
                color="default"
                size="small"
              />
            </Box>

            {order.design && order.design.year && (
              <Typography variant="body2">
                <strong>Ano do Modelo:</strong> {order.design.year}
              </Typography>
            )}

            <Typography variant="body2">
              <strong>Nome:</strong> {order.full_name}
            </Typography>
            <Typography variant="body2">
              <strong>Criado em:</strong> {strftime("%d/%m/%Y - %H:%M", new Date(order.created_at))}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                <strong>Pagamento:</strong>
              </Typography>
              <Chip
                label={paymentStatusDisplay[order.invoice_status]?.label || "Fatura não gerada"}
                icon={paymentStatusDisplay[order.invoice_status]?.icon || <ErrorIcon color="error" />}
                color="default"
                size="small"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={order.status === "approved" ? 5 : 3} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {order.status === "approved" ? (
              <>
                <Button
                  variant="contained"
                  startIcon={<AssignmentTurnedInIcon />}
                  href={`orders/${order.identifier}`}
                  fullWidth
                  sx={{ maxWidth: 200 }}
                >
                  Abrir carteirinha
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  onClick={() => handleDownloadPdf(order.identifier)}
                  disabled={requestLoading}
                  fullWidth
                  sx={{ maxWidth: 200 }}
                >
                  Baixar Carteirinha PDF
                </Button>
              </>
            ) : order.status === "adjustments_requested" ? (
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                href={`orders/${order.identifier}`}
                fullWidth
                sx={{ maxWidth: 200 }}
              >
                Realizar ajustes
              </Button>
            ) : (
              <Button
                variant="contained"
                startIcon={<VisibilityIcon />}
                href={`orders/${order.identifier}`}
                fullWidth
                sx={{ maxWidth: 200 }}
              >
                Continuar pedido
              </Button>
            )}

            {order.invoice_url && order.invoice_status === "pending" && (
              <>
                <Button
                  variant="outlined"
                  startIcon={<VisibilityIcon />}
                  href={order.invoice_url}
                  fullWidth
                  target="_blank"
                  sx={{ maxWidth: 200 }}
                >
                  Visualizar fatura
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<CancelIcon />}
                  onClick={(e) => handleCancelInvoice(e, order)}
                  fullWidth
                  sx={{ maxWidth: 200 }}
                >
                  Cancelar fatura
                </Button>
              </>
            )}

            {["expired", "canceled"].includes(order.invoice_status) && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<RefreshIcon />}
                onClick={() => handleRegenerateInvoice(order.identifier)}
                disabled={requestLoading}
                fullWidth
                sx={{ maxWidth: 200 }}
              >
                Regerar Fatura
              </Button>
            )}

            {order.status === "approved" && (
              <Button
                variant="contained"
                color="warning"
                startIcon={<EditIcon />}
                href={`/orders/${order.identifier}/adjustments`}
                fullWidth
                sx={{ maxWidth: 200 }}
              >
                Solicitar Ajustes dos Dados
              </Button>
            )}

            {!order.shelved && (
              <Button
                variant="text"
                color="error"
                startIcon={<ArchiveIcon />}
                onClick={(e) => handleShelveOrder(e, order)}
                fullWidth
                sx={{ maxWidth: 200 }}
              >
                Arquivar pedido
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ textAlign: "center", marginBottom: 5 }}>
        <MainLogo />
      </Box>
      <Typography variant="h4" textAlign="center" sx={{ marginBottom: 5 }}>
        Meus pedidos
      </Typography>

      <Box
        textAlign="center"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 4, gap: 2 }}
      >
        <Button variant="outlined" color="error" href="https://estudante.cc/cde/initializeOrder?">
          Novo Pedido
        </Button>
        <Button variant="outlined" color="error" href="/shelved_orders">
          Pedidos Arquivados
        </Button>
        <Button variant="outlined" color="error" onClick={signOutUser} startIcon={<LogoutIcon />}>
          Sair
        </Button>
      </Box>

      {errorMessage && (
        <Box sx={{ textAlign: "center", marginBottom: 4 }}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      )}

      {requestLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {myOrders
            .filter((order) => order.approved_at && !order.shelved)
            .map((order) => renderOrder(order))}
          {myOrders
            .filter((order) => !order.approved_at && !order.shelved)
            .map((order) => renderOrder(order))}
        </Box>
      )}

      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert severity="success" onClose={() => setSuccessMessage("")}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}