import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Grid,
} from '@mui/material';
import {
  PermIdentity,
  ArrowForward,
  DriveEta,
  Public,
  Work,
  BabyChangingStation,
  CloudUpload,
} from '@mui/icons-material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import api from '../../../services/api';
import { useOrder } from '../../../hooks/useOrder';
import { useAuth } from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

export function PersonalIdentification(props) {
  const { order, setOrder } = useOrder();
  const { user } = useAuth();
  const [requestLoading, setRequestLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const fileInputFrontRef = useRef(null);
  const fileInputBackRef = useRef(null);

  useEffect(() => {
    if (user === null || !order || order.showSidebar === true) {
      setIsLoading(true);
      return;
    }
    setIsLoading(false);

    if (!user) {
      navigate("/sign_in");
      return;
    }
    if (order.user_id !== user.id) {
      navigate("/orders");
    }
  }, [user, order, navigate]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const documents = [
    { id: 1, label: 'CNH', icon: <DriveEta /> },
    { id: 2, label: 'RG', icon: <PermIdentity /> },
    { id: 3, label: 'Passaporte', icon: <Public /> },
    { id: 4, label: 'Carteira de Trabalho', icon: <Work /> },
    { id: 5, label: 'Certidão de Nascimento', icon: <BabyChangingStation /> },
  ];

  const initialValues = {
    documentType: '',
    documentNumber: '',
    documentPhotoFront: null,
    documentPhotoBack: null,
  };

  const validationSchema = Yup.object().shape({
    documentType: Yup.string().required('Tipo de documento é obrigatório.'),
    documentNumber: Yup.string()
      .required('Número do documento é obrigatório.')
      .when('documentType', {
        is: (value) => value === 'CNH',
        then: (schema) => schema.matches(/^\d{11}$/, 'CNH deve ter 11 dígitos.'),
        otherwise: (schema) =>
          schema.when('documentType', {
            is: (value) => value === 'RG',
            then: (schema) => schema.matches(/^\d{7,11}$/, 'RG deve ter entre 7 e 11 dígitos.'),
            otherwise: (schema) =>
              schema.when('documentType', {
                is: (value) => value === 'Passaporte',
                then: (schema) => schema.matches(/^[A-Z]{2}\d{6}$/, 'Passaporte deve ter 2 letras seguidas de 6 dígitos (ex.: AB123456).'),
                otherwise: (schema) =>
                  schema.when('documentType', {
                    is: (value) => value === 'Carteira de Trabalho',
                    then: (schema) => schema.matches(/^\d{8}$/, 'Carteira de Trabalho deve ter 8 dígitos.'),
                    otherwise: (schema) =>
                      schema.when('documentType', {
                        is: (value) => value === 'Certidão de Nascimento',
                        then: (schema) => schema.matches(/^\d{32}$/, 'Certidão de Nascimento deve ter 32 dígitos.'),
                        otherwise: (schema) => schema,
                      }),
                  }),
              }),
          }),
      }),
    documentPhotoFront: Yup.mixed()
      .required('Foto frontal do documento é obrigatória.')
      .test('fileSize', 'O arquivo é muito grande. Máximo 5MB.', (value) => {
        return value && value.size <= 5 * 1024 * 1024;
      })
      .test('fileFormat', 'Formato não suportado. Use .pdf, .jpg, .png', (value) => {
        return value && ['application/pdf', 'image/jpeg', 'image/png'].includes(value.type);
      }),
    documentPhotoBack: Yup.mixed().when('documentType', {
      is: 'RG',
      then: (schema) =>
        schema
          .required('Foto do verso do RG é obrigatória.')
          .test('fileSize', 'O arquivo é muito grande. Máximo 5MB.', (value) => {
            return value && value.size <= 5 * 1024 * 1024;
          })
          .test('fileFormat', 'Formato não suportado. Use .pdf, .jpg, .png', (value) => {
            return value && ['application/pdf', 'image/jpeg', 'image/png'].includes(value.type);
          }),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const formData = new FormData();
    formData.append('order[document_type]', values.documentType);
    formData.append('order[document_number]', values.documentNumber);
    formData.append('order[card_document_photo_front]', values.documentPhotoFront);
    if (values.documentType === 'RG') {
      formData.append('order[card_document_photo_back]', values.documentPhotoBack);
    }

    setRequestLoading(true);
    try {
      const response = await api.put(`/orders/${order.identifier}`, formData);
      setOrder(response.data);

      if (response.data.errors) {
        setErrors(response.data.errors);
      } else {
        navigate('/next-step');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      setErrors({ api: 'Erro ao enviar os dados. Tente novamente.' });
    } finally {
      setRequestLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {({ values, errors, touched, setFieldValue, validateForm, isSubmitting }) => (
        <Form>
          <Box display="flex" justifyContent="center" minHeight="100vh" p={2} sx={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
            <Box maxWidth="600px" width="100%">
              <Typography variant="h4" gutterBottom textAlign="center">
                Preencha as informações do seu documento de identificação:
              </Typography>

              {errors.api && (
                <Typography color="error" textAlign="center">
                  {errors.api}
                </Typography>
              )}

              <Box mt={3}>
                <Typography variant="body1" gutterBottom>
                  Tipo de Documento:
                </Typography>
                <Grid container spacing={2}>
                  {documents.map((doc) => (
                    <Grid item xs={12} sm={6} md={4} key={doc.id}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: values.documentType === doc.label ? '3px solid #1976d2' : '2px solid #e0e0e0',
                          borderRadius: 2,
                          padding: 2,
                          cursor: 'pointer',
                          transition: 'border-color 0.3s ease-in-out',
                          '&:hover': { borderColor: '#1976d2' },
                          boxShadow: values.documentType === doc.label ? '0px 4px 12px rgba(0,0,0,0.2)' : 'none',
                        }}
                        onClick={() => {
                          setFieldValue('documentType', doc.label);
                          setFieldValue('documentNumber', '');
                          setFieldValue('documentPhotoFront', null);
                          setFieldValue('documentPhotoBack', null);
                          setTimeout(() => validateForm(), 0);
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {doc.icon}
                          <Typography sx={{ ml: 1, fontWeight: values.documentType === doc.label ? 600 : 400 }}>
                            {doc.label}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                {touched.documentType && errors.documentType && (
                  <Typography color="error">{errors.documentType}</Typography>
                )}
              </Box>

              <Box mt={3}>
                <Field
                  as={TextField}
                  name="documentNumber"
                  fullWidth
                  label="Número do Documento"
                  value={values.documentNumber}
                  onChange={(e) => setFieldValue('documentNumber', e.target.value)}
                  error={touched.documentNumber && Boolean(errors.documentNumber)}
                  helperText={touched.documentNumber && errors.documentNumber}
                />
              </Box>

              <Box mt={3}>
                <Typography variant="h6">Faça upload do seu documento de identificação:</Typography>
                <Grid container spacing={2} justifyContent="center" mt={3}>
                  <Grid item xs={12} sm={6}>
                    <Box textAlign="center">
                      <Typography variant="subtitle1">
                        {values.documentType === 'RG' ? 'Frente do RG' : values.documentType || 'Documento'}
                      </Typography>
                      {!values.documentPhotoFront ? (
                        <>
                          <Box onClick={() => fileInputFrontRef.current.click()} sx={{ cursor: 'pointer', marginBottom: 2 }}>
                            <CloudUpload fontSize="large" />
                          </Box>
                          <Button variant="outlined" onClick={() => fileInputFrontRef.current.click()}>
                            Fazer Upload
                          </Button>
                          <input
                            style={{ display: 'none' }}
                            ref={fileInputFrontRef}
                            type="file"
                            accept=".pdf,.jpg,.png"
                            onChange={(e) => setFieldValue('documentPhotoFront', e.currentTarget.files[0])}
                          />
                          {touched.documentPhotoFront && errors.documentPhotoFront && (
                            <Typography color="error">{errors.documentPhotoFront}</Typography>
                          )}
                        </>
                      ) : (
                        <Box>
                          <Typography variant="h6" color="success.main">
                            Arquivo anexado com sucesso.
                          </Typography>
                          <Button variant="contained" color="warning" onClick={() => setFieldValue('documentPhotoFront', null)}>
                            Trocar Documento
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  {values.documentType === 'RG' && (
                    <Grid item xs={12} sm={6}>
                      <Box textAlign="center">
                        <Typography variant="subtitle1">Verso do RG</Typography>
                        {!values.documentPhotoBack ? (
                          <>
                            <Box onClick={() => fileInputBackRef.current.click()} sx={{ cursor: 'pointer', marginBottom: 2 }}>
                              <CloudUpload fontSize="large" />
                            </Box>
                            <Button variant="outlined" onClick={() => fileInputBackRef.current.click()}>
                              Fazer Upload
                            </Button>
                            <input
                              style={{ display: 'none' }}
                              ref={fileInputBackRef}
                              type="file"
                              accept=".pdf,.jpg,.png"
                              onChange={(e) => setFieldValue('documentPhotoBack', e.currentTarget.files[0])}
                            />
                            {touched.documentPhotoBack && errors.documentPhotoBack && (
                              <Typography color="error">{errors.documentPhotoBack}</Typography>
                            )}
                          </>
                        ) : (
                          <Box>
                            <Typography variant="h6" color="success.main">
                              Arquivo anexado com sucesso.
                            </Typography>
                            <Button variant="contained" color="warning" onClick={() => setFieldValue('documentPhotoBack', null)}>
                              Trocar Documento
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>

              <Box textAlign="center" mt={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3 }}
                  endIcon={<ArrowForward />}
                  disabled={isSubmitting || requestLoading || Object.keys(errors).length > 0}
                >
                  Enviar Dados
                </Button>
              </Box>
              {(isSubmitting || requestLoading) && <CircularProgress sx={{ mt: 2 }} />}
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}