import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Slider,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Radio,
  Dialog,
  RadioGroup,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useOrder } from "../../../hooks/useOrder";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import {
  School,
  Engineering,
  LocalLibrary,
  Handyman,
  Science,
  WbSunny,
  Schedule,
  Brightness2,
  AllInclusive,
  Build,
  Work,
  Badge,
  CalendarToday,
} from "@mui/icons-material";
import { PhotoCropper } from "../../PhotoCropper";

export function ReviewOrder() {
  const { order, setOrder, fetchOrder } = useOrder();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [requestLoading, setRequestLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({ title: "", message: "", type: "error" });
  const [showPhotoCropper, setShowPhotoCropper] = useState(false);
  const [finalPhoto, setFinalPhoto] = useState(null);
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const [photoUpdated, setPhotoUpdated] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate("/sign_in");
    }
    if (order?.user_id !== user?.id) {
      navigate("/orders");
    }
  }, [user, order, navigate]);

  useEffect(() => {
    if (finalPhoto) {
      const url = URL.createObjectURL(finalPhoto);
      setPreviewPhoto(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setPreviewPhoto(null);
    }
  }, [finalPhoto]);

  const formatPhoneNumber = (value) => {
    const phone = value.replace(/\D/g, "");
    if (phone.length <= 10) {
      return phone.replace(/(\d{2})(\d{4})(\d{0,4})/, "($1) $2-$3");
    } else {
      return phone.replace(/(\d{2})(\d{5})(\d{0,4})/, "($1) $2-$3");
    }
  };

  const COURSE_TYPES = [
    { label: "Ensino Infantil", icon: <School />, value: "Ensino Infantil" },
    { label: "Ensino Fundamental", icon: <School />, value: "Ensino Fundamental" },
    { label: "Ensino Médio", icon: <School />, value: "Ensino Médio" },
    { label: "Técnico", icon: <Engineering />, value: "Técnico" },
    { label: "Graduação", icon: <LocalLibrary />, value: "Graduação" },
    { label: "Graduação Tecnológica", icon: <Build />, value: "Graduação tecnológica" },
    { label: "Pós-graduação", icon: <Badge />, value: "Pós-graduação" },
    { label: "Especialização", icon: <Handyman />, value: "Especialização" },
    { label: "Mestrado", icon: <Science />, value: "Mestrado" },
    { label: "Doutorado", icon: <Science />, value: "Doutorado" },
    { label: "MBA", icon: <Work />, value: "MBA" },
  ];

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("O nome completo é obrigatório"),
    phone_number: Yup.string()
      .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, "Formato inválido (ex: (11) 99999-9999)")
      .required("O telefone é obrigatório"),
    birth_date: Yup.string()
      .required("Data de nascimento é obrigatória")
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, "Data de nascimento inválida"),
    cpf: Yup.string().required("O CPF é obrigatório"),
    institution_name: Yup.string().required("O nome da instituição é obrigatório"),
    registration_number: Yup.string().when("has_not_informed_registration_number", {
      is: false,
      then: (schema) => schema.required("O número de matrícula é obrigatório"),
      otherwise: (schema) => schema.notRequired(),
    }),
    period: Yup.number()
      .required("O período é obrigatório")
      .min(1, "Valor mínimo é 1")
      .max(10, "Valor máximo é 10"),
    time: Yup.string().required("O horário é obrigatório"),
    has_not_informed_registration_number: Yup.boolean().default(false),
    course_type: Yup.string().required("O tipo de curso é obrigatório"),
    custom_course: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      full_name: order?.full_name || "",
      phone_number: order?.phone_number || "",
      birth_date: order?.birth_date || "",
      custom_course: order?.custom_course || "",
      course_type: order?.course_type || "",
      cpf: order?.cpf || "",
      institution_name: order?.institution_name || "",
      registration_number: order?.registration_number || "",
      period: order?.period ? Number(order.period) : 1,
      time: order?.time || "",
      has_not_informed_registration_number: order?.has_not_informed_registration_number || false,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setRequestLoading(true);
        let response;
        if (finalPhoto) {
          const formData = new FormData();
          Object.keys(values).forEach((key) => {
            formData.append(`order[${key}]`, values[key]);
          });
          formData.append("order[card_photo]", finalPhoto);
          response = await api.put(`orders/${order.identifier}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        } else {
          response = await api.put(`orders/${order.identifier}`, { order: values });
        }
        const { errors, success } = response.data;
        if (!success) {
          setDialogData({
            title: "Erro!",
            message: "Confira as informações inseridas",
            type: "error",
          });
          setDialogOpen(true);
          return;
        }
        setOrder(response.data);
        const patchResponse = await api.patch(`/orders/${order.id}/send_to_review`);
        if (patchResponse.data.success) {
          fetchOrder();
        } else {
          setDialogData({
            title: "Erro!",
            message: patchResponse.data.errors
              ? patchResponse.data.errors[0].payment
              : "Erro ao enviar para revisão",
            type: "error",
          });
          setDialogOpen(true);
        }
      } catch (error) {
        setDialogData({
          title: "Erro!",
          message: "Falha ao enviar o pedido para revisão.",
          type: "error",
        });
        setDialogOpen(true);
      } finally {
        setRequestLoading(false);
      }
    },
  });

  const handleCroppedFile = (file) => {
    setFinalPhoto(file);
    setPhotoUpdated(true);
    setShowPhotoCropper(false);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Revisar Pedido
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 3 }}>
        {showPhotoCropper ? (
          <Box sx={{ width: "100%", maxWidth: 600, mb: 2 }}>
            <PhotoCropper
              onComplete={handleCroppedFile}
              initialPhoto={order?.card_photo}
              onCancel={() => setShowPhotoCropper(false)}
            />
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
            <Box
              component="img"
              src={previewPhoto ? previewPhoto : order?.card_photo}
              alt="Foto atual"
              sx={{
                width: 300,
                height: 300,
                objectFit: "cover",
                borderRadius: 2,
                mb: 2,
              }}
            />
            <Button variant="outlined" onClick={() => setShowPhotoCropper(true)}>
              Alterar Foto
            </Button>
          </Box>
        )}

        {photoUpdated && !showPhotoCropper && (
          <Typography variant="body1" color="success.main" textAlign="center" sx={{ mt: 2 }}>
            Foto atualizada com sucesso!
          </Typography>
        )}
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome Completo"
              name="full_name"
              value={formik.values.full_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.touched.full_name && formik.errors.full_name)}
              helperText={formik.touched.full_name && formik.errors.full_name}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Telefone"
              name="phone_number"
              value={formik.values.phone_number}
              onChange={(e) => {
                const formatted = formatPhoneNumber(e.target.value);
                formik.setFieldValue("phone_number", formatted);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
              helperText={
                formik.touched.phone_number && formik.errors.phone_number
                  ? formik.errors.phone_number
                  : "Digite no formato (11) 99999-9999"
              }
            />
          </Grid>

          <Grid item xs={12}>
            <InputMask
              mask="99/99/9999"
              value={formik.values.birth_date}
              onChange={(e) => formik.setFieldValue("birth_date", e.target.value)}
            >
              {() => (
                <TextField
                  fullWidth
                  name="birth_date"
                  label="Data de Nascimento"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarToday />
                      </InputAdornment>
                    ),
                  }}
                  error={formik.touched.birth_date && Boolean(formik.errors.birth_date)}
                  helperText={formik.touched.birth_date && formik.errors.birth_date}
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Curso"
              name="custom_course"
              value={formik.values.custom_course}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.custom_course && Boolean(formik.errors.custom_course)}
              helperText={formik.touched.custom_course && formik.errors.custom_course}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Tipo de Curso:
            </Typography>
            <RadioGroup
              value={formik.values.course_type}
              onChange={(e) => formik.setFieldValue("course_type", e.target.value)}
              row
            >
              {COURSE_TYPES.map((course) => (
                <FormControlLabel
                  key={course.value}
                  value={course.value}
                  control={<Radio />}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {course.icon}
                      <Typography sx={{ ml: 1 }}>{course.label}</Typography>
                    </Box>
                  }
                />
              ))}
            </RadioGroup>
            <Typography color="error">
              {formik.touched.course_type && formik.errors.course_type}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="CPF"
              name="cpf"
              value={formik.values.cpf}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.cpf && Boolean(formik.errors.cpf)}
              helperText={formik.touched.cpf && formik.errors.cpf}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome da Instituição"
              name="institution_name"
              value={formik.values.institution_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.institution_name && Boolean(formik.errors.institution_name)}
              helperText={formik.touched.institution_name && formik.errors.institution_name}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Número da Matrícula"
              name="registration_number"
              variant="outlined"
              InputProps={{
                readOnly: formik.values.has_not_informed_registration_number,
              }}
              {...formik.getFieldProps("registration_number")}
              error={formik.touched.registration_number && Boolean(formik.errors.registration_number)}
              helperText={formik.touched.registration_number && formik.errors.registration_number}
              sx={{ mb: 2 }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="has_not_informed_registration_number"
                  checked={formik.values.has_not_informed_registration_number}
                  onChange={formik.handleChange}
                />
              }
              label="Não quero informar o número de matrícula"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography id="slider-label" gutterBottom>
              Período: {formik.values.period}
            </Typography>
            <Slider
              aria-labelledby="slider-label"
              value={typeof formik.values.period === "number" ? formik.values.period : 1}
              onChange={(event, newValue) => formik.setFieldValue("period", newValue)}
              step={1}
              marks
              min={1}
              max={10}
              valueLabelDisplay="auto"
              sx={{ mb: 2 }}
            />
            {formik.touched.period && formik.errors.period && (
              <Typography color="error">{formik.errors.period}</Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Turno:
            </Typography>
            <RadioGroup
              value={formik.values.time}
              onChange={(event) => formik.setFieldValue("time", event.target.value)}
              row
            >
              <FormControlLabel
                value="morning"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <WbSunny />
                    <Typography sx={{ ml: 1 }}>Matutino</Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="afternoon"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Schedule />
                    <Typography sx={{ ml: 1 }}>Vespertino</Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="night"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Brightness2 />
                    <Typography sx={{ ml: 1 }}>Noturno</Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="integral"
                control={<Radio />}
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <AllInclusive />
                    <Typography sx={{ ml: 1 }}>Integral</Typography>
                  </Box>
                }
              />
            </RadioGroup>
            {formik.touched.time && formik.errors.time && (
              <Typography color="error">{formik.errors.time}</Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Enviar para Aprovação
            </Button>
          </Grid>
        </Grid>
      </form>

      {requestLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      )}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{dialogData.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogData.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
