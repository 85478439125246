import { createContext, useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router";
import api from "../services/api";
import axios from "axios";
import { Snackbar, Alert } from "@mui/material";

export const OrderContext = createContext({});

export function OrderContextProvider({ children }) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [order, setOrder] = useState({ showSidebar: true });

  console.log("OrderContextProvider - ID da URL:", id); // Log 1
  console.log("OrderContextProvider - Order inicial:", order); // Log 2

  const showSnackbar = useCallback((message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  }, []);

  const handleCloseSnackbar = useCallback(() => {
    setOpenSnackbar(false);
  }, []);

  const fetchOrder = useCallback(
    async (orderId = id, controller = null) => {
      console.log("fetchOrder - Buscando pedido com ID:", orderId); // Log 3
      setIsLoading(true);
      try {
        const config = controller ? { signal: controller.signal } : {};
        const response = await api.get(`orders/${orderId}`, config);
        console.log("fetchOrder - Resposta da API:", response.data); // Log 4
        setOrder(response.data);
      } catch (error) {
        if (!axios.isCancel(error) && error.code !== "ERR_CANCELED") {
          console.error("fetchOrder - Erro ao buscar pedido:", error); // Log 5
          showSnackbar("Não foi possível carregar o pedido. Tente novamente mais tarde.", "error");
        }
      } finally {
        setIsLoading(false);
      }
    },
    [id, showSnackbar]
  );

  const checkPayment = useCallback(async () => {
    if (!order) return;
    try {
      const response = await api.get(`orders/${order.identifier}/invoice_status`);
      const { invoice_status } = response.data;
  
      if (invoice_status === "paid" || invoice_status === "gift") {
        setOrder({ ...order, invoice_status });
        showSnackbar("O pagamento da sua fatura foi confirmado.", "success");
      } else {
        showSnackbar("O pagamento ainda não foi confirmado.", "info");
      }
    } catch (error) {
      console.error("Erro ao verificar pagamento:", error);
      showSnackbar("Não foi possível verificar o pagamento. Tente novamente mais tarde.", "error");
    }
  }, [order, showSnackbar]);  

  useEffect(() => {
    const controller = new AbortController();
    fetchOrder(id, controller).catch((error) => {
      console.error("Erro ao buscar o pedido no useEffect:", error);
      showSnackbar("Erro ao carregar o pedido. Tente novamente.", "error");
    });
    return () => {
      controller.abort();
    };
  }, [id, fetchOrder, showSnackbar]);

  const contextValue = useMemo(() => ({
    order,
    setOrder,
    isLoading,
    fetchOrder,
    checkPayment,
  }), [order, isLoading, fetchOrder, checkPayment]);

  console.log("OrderContextProvider - Contexto fornecido:", contextValue); // Log 6

  return (
    <OrderContext.Provider value={contextValue}>
      {children}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </OrderContext.Provider>
  );
}