import React, { useState } from "react";
import { TextField, MenuItem, Button, Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const AdminOrderFilters = ({ onFilter }) => {
  const [search, setSearch] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [status, setStatus] = useState([]);
  const [invoiceStatus, setInvoiceStatus] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const orderStatusOptions = [
    "started",
    "design_filled",
    "invoice_generated",
    "invoice_details_advanced",
    "student_details_filled",
    "photo_uploaded",
    "personal_identification_uploaded",
    "institution_identification_filled",
    "waiting_review",
    "approved",
    "adjustments_requested",
    "rejected",
    "blocked",
  ];

  const invoiceStatusOptions = [
    "paid",
    "partially_paid",
    "pending",
    "invoice_not_generated",
    "expired",
    "canceled",
    "in_protest",
    "chargeback",
    "refunded",
    "in_analysis",
    "externally_paid",
    "gift",
  ];

  const handleApplyFilters = () => {
    const filters = {
      search: search.trim() || null,
      user_email: userEmail.trim() || null,
      status: status.length ? status : null,
      invoice_status: invoiceStatus.length ? invoiceStatus : null,
      filter_minimum_date: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
      filter_maximum_date: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
    };
    onFilter(filters);
  };

  const handleClearFilters = () => {
    setSearch("");
    setUserEmail("");
    setStatus([]);
    setInvoiceStatus([]);
    setStartDate(null);
    setEndDate(null);
    onFilter({}); // Envia filtros vazios para resetar
  };

  return (
    <Box display="flex" gap={2} flexWrap="wrap" alignItems="center" p={2}>
      <TextField
        label="Buscar Pedido"
        variant="outlined"
        size="small"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <TextField
        label="Email do Usuário"
        variant="outlined"
        size="small"
        value={userEmail}
        onChange={(e) => setUserEmail(e.target.value)}
      />

      <TextField
        select
        label="Status"
        variant="outlined"
        size="small"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        SelectProps={{
          multiple: true,
          renderValue: (selected) => selected.join(", "),
        }}
        style={{ minWidth: 150 }}
      >
        {orderStatusOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Status da Fatura"
        variant="outlined"
        size="small"
        value={invoiceStatus}
        onChange={(e) => setInvoiceStatus(e.target.value)}
        SelectProps={{
          multiple: true,
          renderValue: (selected) => selected.join(", "),
        }}
        style={{ minWidth: 150 }}
      >
        {invoiceStatusOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>

      <DatePicker
        label="Data Inicial"
        value={startDate}
        onChange={(newValue) => setStartDate(newValue)}
        format="DD/MM/YYYY"
        slotProps={{ textField: { size: "small" } }}
      />

      <DatePicker
        label="Data Final"
        value={endDate}
        onChange={(newValue) => setEndDate(newValue)}
        format="DD/MM/YYYY"
        slotProps={{ textField: { size: "small" } }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleApplyFilters}
        sx={{ height: "40px" }}
      >
        Aplicar Filtros
      </Button>

      <Button
        variant="outlined"
        color="secondary"
        onClick={handleClearFilters}
        sx={{ height: "40px" }}
      >
        Limpar Filtros
      </Button>
    </Box>
  );
};

export default AdminOrderFilters;