import { useState, useRef, useEffect } from "react";
import { useOrder } from "../../../hooks/useOrder";
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";
import {
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export function Adjustments({ adjustments }) {
  const { order, setOrder } = useOrder();
  const navigate = useNavigate();

  const [preview, setPreview] = useState("");
  const [photo, setPhoto] = useState(undefined);
  const [crop, setCrop] = useState({
    unit: "px",
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    aspect: 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [croppedBlob, setCroppedBlob] = useState(null);
  const [requestLoading, setRequestLoading] = useState(false);
  const [openCropDialog, setOpenCropDialog] = useState(false);

  const imgRef = useRef(null);

  const [newOrder, setNewOrder] = useState({
    full_name: order.full_name,
    cpf: order.cpf,
    cep: order.cep,
    street_name: order.street_name,
    street_number: order.street_number,
    street_complement: order.street_complement,
    neighborhood: order.neighborhood,
    birth_date: order.birth_date,
    phone_number: order.phone_number,
    document_number: order.document_number,
    institution_name: order.institution_name,
    custom_course: order.custom_course,
    registration_number: order.registration_number,
    period: order.period,
    time: order.time,
    // Para campos de arquivo, poderá vir undefined ou um File
    card_photo: order.card_photo || undefined,
  });

  // Previews para campos do tipo upload
  const [imagePreviews, setImagePreviews] = useState({
    photo: "",
    card_photo: "",
    institution_statement_photo: "",
    card_institution_statement_photo: "",
    document_photo_front: "",
    card_document_photo_front: "",
    document_photo_back: "",
    card_document_photo_back: "",
  });

  // Tradução dos nomes dos campos
  const translatedFields = {
    card_photo: "Foto Pessoal",
    document_photo_front: "Foto do documento (frente)",
    document_photo_back: "Foto do documento (verso)",
    institution_statement_photo: "Foto da declaração da instituição",
    card_institution_statement_photo: "Foto do cartão da instituição",
    photo: "Foto",
    full_name: "Nome completo",
    cpf: "CPF",
    cep: "CEP",
    street_name: "Endereço",
    street_number: "Número",
    street_complement: "Complemento",
    neighborhood: "Bairro",
    birth_date: "Data de nascimento",
    phone_number: "Telefone",
    document_number: "Número do documento",
    institution_name: "Nome da instituição",
    custom_course: "Curso personalizado",
    registration_number: "Número de matrícula",
    period: "Período",
    time: "Turno",
  };

  // Função para mostrar preview da foto ou input para upload
  function handlePhotoFeedback(value, field) {
    let src = "";
    if (value) {
      if (typeof value === "string") {
        src = value;
      } else {
        try {
          src = URL.createObjectURL(value);
        } catch (error) {
          console.error("Erro ao criar objectURL:", error);
        }
      }
    }
    return (
      <div>
        {src && (
          <>
            <p>Prévia da imagem:</p>
            <img src={src} alt={`Prévia ${field}`} style={{ maxWidth: "100px" }} />
          </>
        )}
        <br />
        <label
          style={{
            display: "inline-block",
            padding: "8px 16px",
            backgroundColor: "#1976d2",
            color: "#fff",
            borderRadius: "4px",
            cursor: "pointer",
            marginTop: "8px",
          }}
        >
          {src ? "Alterar foto" : "Escolher foto"}
          <input
            type="file"
            accept="image/*"
            onChange={handlePhoto}
            style={{ display: "none" }}
          />
        </label>
      </div>
    );
  }  

  // Função para upload de campos não relacionados à foto (ex.: documentos)
  function handleFileUpload(value, field) {
    return (
      <input
        type="file"
        accept="image/*"
        onChange={(e) => {
          const file = e.target.files[0];
          handleNewOrderChange(field, file);
        }}
      />
    );
  }

  // Outras funções para atualizar o pedido e previews
  const fileUploadFieldNames = [
    "photo",
    "card_photo",
    "document_photo_front",
    "card_document_photo_front",
    "document_photo_back",
    "card_document_photo_back",
    "institution_statement_photo",
    "card_institution_statement_photo",
  ];

  const handleNewOrderChange = (field, value) => {
    const currentNewOrder = { ...newOrder };
    currentNewOrder[field] = value;
    if (fileUploadFieldNames.includes(field)) {
      const currentImagesPreviews = { ...imagePreviews };
      currentImagesPreviews[field] = URL.createObjectURL(value);
      setImagePreviews(currentImagesPreviews);
    }
    setNewOrder(currentNewOrder);
  };

  // Função para obter dimensões da imagem a partir de uma URL
  const getHeightAndWidthFromDataUrl = (dataURL) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () =>
        resolve({ height: img.height, width: img.width, image: img });
      img.src = dataURL;
    });

  // Função para redimensionar a imagem (para upload final) a partir de um blob
  function resizeImgBlob(blob) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const MAX_SIZE = 200;
        const canvas = document.createElement("canvas");
        canvas.width = MAX_SIZE;
        canvas.height = MAX_SIZE;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, MAX_SIZE, MAX_SIZE);
        canvas.toBlob(
          (resizedBlob) => resolve(resizedBlob),
          "image/jpeg"
        );
      };
      img.src = URL.createObjectURL(blob);
    });
  }

  // Função para gerar a imagem recortada a partir do crop e da imagem carregada
  async function generateCroppedImage(image, crop) {
    if (!crop || !image) return;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        setCroppedBlob(blob);
        resolve();
      }, "image/jpeg");
    });
  }

  // Função para lidar com a seleção da foto e abrir o diálogo de recorte
  function handlePhoto(e) {
    const imageFiles = e.target.files;
    if (imageFiles && imageFiles.length > 0) {
      const imageFile = imageFiles[0];
      const objectUrl = URL.createObjectURL(imageFile);
      setPreview(objectUrl);
      setPhoto(imageFile);
      // Abre o diálogo para recorte
      setOpenCropDialog(true);
      setCrop({
        unit: "px",
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        aspect: 1,
      });
      setCompletedCrop(null);
      setCroppedBlob(null);
    } else {
      setPhoto(undefined);
      setPreview("");
    }
  }

  // Quando a imagem do recorte é carregada, guarda a referência
  function onCropImageLoad(e) {
    const img = e.currentTarget;
    imgRef.current = img;
  }

  // Atualiza o recorte completo e gera a imagem recortada
  useEffect(() => {
    if (completedCrop && imgRef.current) {
      generateCroppedImage(imgRef.current, completedCrop);
    }
  }, [completedCrop]);

  // Função chamada ao confirmar o recorte
  async function handlerPhotoUpload(e) {
    e.preventDefault();
    if (!croppedBlob) {
      console.error("Nenhuma imagem recortada disponível.");
      return;
    }
    setRequestLoading(true);
    const dataObject = new FormData();
    const resizedBlob = await resizeImgBlob(croppedBlob);
    const finalFile = new File([resizedBlob], "finalImage.jpg", {
      type: resizedBlob.type,
    });
    dataObject.append("order[card_photo]", finalFile);
    try {
      const response = await api.put(
        `orders/${order.identifier}`,
        dataObject
      );
      setOrder(response.data);
    } catch (error) {
      console.error("Falha ao enviar a foto:", error);
    } finally {
      setRequestLoading(false);
      setOpenCropDialog(false);
    }
  }

  // Função para envio geral do formulário com outros campos
  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataObject = new FormData();
    if (newOrder.photo)
      dataObject.append("order[photo]", newOrder["photo"]);
    if (newOrder.card_photo)
      dataObject.append("order[card_photo]", newOrder["card_photo"]);
    if (newOrder.card_document_photo_front)
      dataObject.append(
        "order[card_document_photo_front]",
        newOrder["card_document_photo_front"]
      );
    if (newOrder.card_document_photo_back)
      dataObject.append(
        "order[card_document_photo_back]",
        newOrder["card_document_photo_back"]
      );
    if (newOrder.card_institution_statement_photo)
      dataObject.append(
        "order[card_institution_statement_photo]",
        newOrder["card_institution_statement_photo"]
      );
    dataObject.append("order[full_name]", newOrder["full_name"]);
    dataObject.append("order[cpf]", newOrder["cpf"]);
    dataObject.append("order[cep]", newOrder["cep"]);
    dataObject.append("order[street_name]", newOrder["street_name"]);
    dataObject.append("order[street_number]", newOrder["street_number"]);
    dataObject.append(
      "order[street_complement]",
      newOrder["street_complement"]
    );
    dataObject.append("order[neighborhood]", newOrder["neighborhood"]);
    dataObject.append("order[birth_date]", newOrder["birth_date"]);
    dataObject.append("order[phone_number]", newOrder["phone_number"]);
    dataObject.append(
      "order[document_number]",
      newOrder["document_number"]
    );
    dataObject.append(
      "order[institution_name]",
      newOrder["institution_name"]
    );
    dataObject.append("order[custom_course]", newOrder["custom_course"]);
    dataObject.append(
      "order[registration_number]",
      newOrder["registration_number"]
    );
    dataObject.append("order[period]", newOrder["period"]);
    dataObject.append("order[time]", newOrder["time"]);
    const response = await api.put(
      `/orders/${order.identifier}`,
      dataObject
    );
    setOrder(response.data);
    if (response.data.success) {
      showSnackbar(
        "Sua carteirinha está em processo de aprovação.",
        "success"
      );
      navigate(`/orders`);
    } else {
      showSnackbar("Ocorreu um erro ao enviar os ajustes.", "error");
    }
  };

  // Funções para Snackbar (feedback)
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {adjustments.map((adjustment) => {
              if (fileUploadFieldNames.includes(adjustment.field)) {
                return (
                  <div key={adjustment.field}>
                    <div className="adjustments-style">
                      <p className="top-main-title">
                        Essas são as informações que precisam ser alteradas para
                        continuarmos com o seu pedido.
                      </p>
                      <div className="">
                        <div className="row">
                          <div className="text-center">
                            {adjustment.field === "card_photo" ? (
                              <>
                                <p>{translatedFields[adjustment.field]}</p>
                                {handlePhotoFeedback(
                                  newOrder[adjustment.field],
                                  adjustment.field
                                )}
                              </>
                            ) : (
                              <>
                                <p>{translatedFields[adjustment.field]}</p>
                                {handleFileUpload(
                                  newOrder[adjustment.field],
                                  adjustment.field
                                )}
                              </>
                            )}
                            <div>
                              <p className="end-text">
                                <span className="text-danger fw-bold">
                                  Mensagem:
                                </span>{" "}
                                {adjustment.comment}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={adjustment.field}>
                    <div className="mt-3 mb-3">
                      <div className="row">
                        <div className="col-md-3">
                          <p>{translatedFields[adjustment.field]}</p>
                        </div>
                        <div className="col-md-8">
                          <input
                            className="w-100"
                            value={newOrder[adjustment.field]}
                            placeholder="Faça as suas alterações nesse campo"
                            onChange={(e) =>
                              handleNewOrderChange(
                                adjustment.field,
                                e.target.value
                              )
                            }
                          />
                          <p className="text-danger mt-3">
                            {adjustment.comment}
                          </p>
                          {order.errors && order.errors[adjustment.field] && (
                            <p className="text-danger mt-3">
                              {order.errors[adjustment.field]}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="d-flex justify-content-center">
            <button className="btn btn-primary mb-3" type="submit">
              Confirmar Edições
            </button>
          </div>
        </form>
      </div>

      {/* Diálogo para recorte da imagem */}
      <Dialog
        open={openCropDialog}
        onClose={() => setOpenCropDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Recortar Foto</DialogTitle>
        <DialogContent>
          {preview && (
            <ReactCrop
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={1}
              keepSelection
            >
              <img
                src={preview}
                alt="Para recorte"
                onLoad={onCropImageLoad}
                style={{ maxWidth: "100%" }}
              />
            </ReactCrop>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handlerPhotoUpload}
            color="primary"
            variant="contained"
            disabled={requestLoading}
          >
            {requestLoading ? <CircularProgress size={24} /> : "Confirmar Recorte"}
          </Button>
          <Button onClick={() => setOpenCropDialog(false)} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
