import React, { useState, useEffect } from "react";
import { Container, Typography, Grid, Card, CardContent, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Assignment, BarChart, CheckCircle, AddShoppingCart, Group, Settings } from "@mui/icons-material";
import { MainLogo } from "../../components/MainLogo";
import api from "../../services/api";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNextOrder = async () => {
      try {
        setLoading(true);
        const response = await api.get("/admin/orders/next");
        // Aqui, assumimos que o backend retorna { order: null, message: "..." } quando não há pedido.
        setOrder(response.data.order || null);
      } catch (err) {
        console.error("Erro ao buscar o próximo pedido:", err);
        setError("Erro ao buscar pedido.");
      } finally {
        setLoading(false);
      }
    };

    fetchNextOrder();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <MainLogo />
      <Typography variant="h4" gutterBottom>
        Painel do Administrador
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4 }}>
        Bem-vindo, <strong>{user?.email || "Administrador"}</strong>!
      </Typography>

      <Grid container spacing={3}>
        {/* Gerenciar Pedidos */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              textAlign: "center",
              p: 2,
              cursor: "pointer",
              "&:hover": { boxShadow: 6 },
            }}
          >
            <CardContent onClick={() => navigate("/admin/orders")}>
              <Avatar sx={{ bgcolor: "info.main", mx: "auto", mb: 2 }}>
                <Assignment />
              </Avatar>
              <Typography variant="h6">Gerenciar Pedidos</Typography>
              <Typography variant="body2" color="textSecondary">
                Filtrar, visualizar e editar pedidos.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Estatísticas de Vendas */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              textAlign: "center",
              p: 2,
              cursor: "pointer",
              "&:hover": { boxShadow: 6 },
            }}
          >
            <CardContent onClick={() => navigate("/admin/statistics")}>
              <Avatar sx={{ bgcolor: "primary.main", mx: "auto", mb: 2 }}>
                <BarChart />
              </Avatar>
              <Typography variant="h6">Estatísticas de Vendas</Typography>
              <Typography variant="body2" color="textSecondary">
                Acompanhe os indicadores de uso.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Revisar Pedidos */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              textAlign: "center",
              p: 2,
              cursor: "pointer",
              "&:hover": { boxShadow: 6 },
            }}
          >
            <CardContent
              onClick={() => {
                if (order && order.id) {
                  navigate(`/admin/orders/review/${order.id}`);
                } else {
                  alert("Nenhum pedido disponível para revisão.");
                }
              }}
            >
              <Avatar sx={{ bgcolor: "success.main", mx: "auto", mb: 2 }}>
                <CheckCircle />
              </Avatar>
              <Typography variant="h6">Revisar Pedidos</Typography>
              <Typography variant="body2" color="textSecondary">
                Aprove ou rejeite pedidos pendentes.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mt: 4 }}>
        {/* Gerenciar Sites */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              textAlign: "center",
              p: 2,
              cursor: "pointer",
              "&:hover": { boxShadow: 6 },
            }}
          >
            <CardContent onClick={() => navigate("/admin/sites")}>
              <Avatar sx={{ bgcolor: "warning.main", mx: "auto", mb: 2 }}>
                <AddShoppingCart />
              </Avatar>
              <Typography variant="h6">Gerenciar Sites</Typography>
              <Typography variant="body2" color="textSecondary">
                Adicione, edite ou remova sites.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Gastos com Anúncios */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              textAlign: "center",
              p: 2,
              cursor: "pointer",
              "&:hover": { boxShadow: 6 },
            }}
          >
            <CardContent onClick={() => navigate("/admin/ads_tracker")}>
              <Avatar sx={{ bgcolor: "info.main", mx: "auto", mb: 2 }}>
                <BarChart />
              </Avatar>
              <Typography variant="h6">Gastos com Anúncios</Typography>
              <Typography variant="body2" color="textSecondary">
                Acompanhe o desempenho de suas campanhas.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Gerenciar Designs */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              textAlign: "center",
              p: 2,
              cursor: "pointer",
              "&:hover": { boxShadow: 6 },
            }}
          >
            <CardContent onClick={() => navigate("/admin/designs")}>
              <Avatar sx={{ bgcolor: "error.main", mx: "auto", mb: 2 }}>
                <Settings />
              </Avatar>
              <Typography variant="h6">Gerenciar Designs</Typography>
              <Typography variant="body2" color="textSecondary">
                Adicione, edite ou remova designs.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mt: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              textAlign: "center",
              p: 2,
              cursor: "pointer",
              "&:hover": { boxShadow: 6 },
            }}
          >
            <CardContent onClick={() => navigate("/admin/coupons")}>
              <Avatar sx={{ bgcolor: "warning.main", mx: "auto", mb: 2 }}>
                <Assignment />
              </Avatar>
              <Typography variant="h6">Gerenciar Cupons</Typography>
              <Typography variant="body2" color="textSecondary">
                Adicione, edite ou remova cupons.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              textAlign: "center",
              p: 2,
              cursor: "pointer",
              "&:hover": { boxShadow: 6 },
            }}
          >
            <CardContent onClick={() => navigate("/admin/users")}>
              <Avatar sx={{ bgcolor: "secondary.main", mx: "auto", mb: 2 }}>
                <Group />
              </Avatar>
              <Typography variant="h6">Gerenciar Usuários</Typography>
              <Typography variant="body2" color="textSecondary">
                Adicione, edite ou remova usuários.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              textAlign: "center",
              p: 2,
              cursor: "pointer",
              "&:hover": { boxShadow: 6 },
            }}
          >
            <CardContent onClick={() => navigate("/admin/posts")}>
              <Avatar sx={{ bgcolor: "error.main", mx: "auto", mb: 2 }}>
                <Settings />
              </Avatar>
              <Typography variant="h6">Gerenciar Posts</Typography>
              <Typography variant="body2" color="textSecondary">
                Adicione, edite ou remova posts.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;
