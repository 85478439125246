import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Alert,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MainLogo } from "../../../components/MainLogo";
import api from "../../../services/api";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

dayjs.locale("pt-br");

const AdminCouponNew = () => {
  const [coupon, setCoupon] = useState({
    code: "",
    discount: "",
    valid_until: null,
    redemption_limit: "",
    kind: "",
  });
  const [selectedDesignIds, setSelectedDesignIds] = useState([]);
  const [designs, setDesigns] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({}); // Erros por campo
  const [generalError, setGeneralError] = useState(null); // Erro geral
  const navigate = useNavigate();

  useEffect(() => {
    const loadDesigns = async () => {
      try {
        const response = await api.get("/admin/designs");
        const designsArray = response.data.designs || response.data;
        const visibleDesigns = designsArray.filter(
          (design) => design.visibility === true
        );
        setDesigns(visibleDesigns);
      } catch (error) {
        console.error("Erro ao carregar designs:", error);
        setGeneralError("Erro ao carregar designs. Tente novamente.");
      }
    };
    loadDesigns();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoupon({ ...coupon, [name]: value });
    setFieldErrors({ ...fieldErrors, [name]: null }); // Limpa erro ao editar
  };

  const handleDateChange = (newValue) => {
    setCoupon({ ...coupon, valid_until: newValue });
    setFieldErrors({ ...fieldErrors, valid_until: null }); // Limpa erro ao editar
  };

  const handleDesignToggle = (designId) => {
    if (selectedDesignIds.includes(designId)) {
      setSelectedDesignIds(selectedDesignIds.filter((id) => id !== designId));
    } else {
      setSelectedDesignIds([...selectedDesignIds, designId]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFieldErrors({});
    setGeneralError(null);

    try {
      const payload = {
        ...coupon,
        valid_until: coupon.valid_until ? coupon.valid_until.toISOString() : null,
        design_ids: selectedDesignIds,
      };
      const response = await api.post("/admin/coupons", { coupon: payload });
      navigate("/admin/coupons");
    } catch (error) {
      const errorData = error.response?.data;
      if (errorData?.errors) {
        // Se os erros vierem como array, processamos
        const newFieldErrors = {};
        errorData.errors.forEach((err) => {
          if (err.includes("Code")) newFieldErrors.code = err;
          else if (err.includes("Discount")) newFieldErrors.discount = err;
          else if (err.includes("Valid until")) newFieldErrors.valid_until = err;
          else if (err.includes("Redemption limit")) newFieldErrors.redemption_limit = err;
          else if (err.includes("Kind")) newFieldErrors.kind = err;
          else setGeneralError(err); // Erros gerais que não correspondem a campos específicos
        });
        setFieldErrors(newFieldErrors);
        if (!Object.keys(newFieldErrors).length && errorData.errors.length) {
          setGeneralError(errorData.errors.join(", "));
        }
      } else {
        setGeneralError("Erro ao criar cupom. Tente novamente.");
      }
      console.error("Erro ao criar cupom:", error);
    }
  };

  const handleBack = () => {
    navigate("/admin");
  };

  return (
    <Container
      sx={{
        mt: 4,
        mb: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <MainLogo />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Button variant="text" startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Voltar
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom>
        Criar Novo Cupom
      </Typography>

      {generalError && (
        <Alert severity="error" sx={{ mb: 2, width: "100%", maxWidth: 600 }}>
          {generalError}
        </Alert>
      )}

      <Paper sx={{ p: 4, maxWidth: 600, width: "100%" }}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Código"
            name="code"
            value={coupon.code}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            error={!!fieldErrors.code}
            helperText={fieldErrors.code || ""}
          />

          <TextField
            label="Desconto (%)"
            name="discount"
            type="number"
            value={coupon.discount}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            error={!!fieldErrors.discount}
            helperText={fieldErrors.discount || ""}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Data de Validade (DD/MM/YYYY HH:mm)"
              value={coupon.valid_until}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true,
                  margin: "normal",
                  variant: "outlined",
                  error: !!fieldErrors.valid_until,
                  helperText: fieldErrors.valid_until || "",
                },
              }}
            />
          </LocalizationProvider>

          <TextField
            label="Limite de Resgates"
            name="redemption_limit"
            type="number"
            value={coupon.redemption_limit}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            error={!!fieldErrors.redemption_limit}
            helperText={fieldErrors.redemption_limit || ""}
          />

          <FormControl
            fullWidth
            required
            margin="normal"
            variant="outlined"
            error={!!fieldErrors.kind}
          >
            <InputLabel id="coupon-kind-label">Tipo de Cupom</InputLabel>
            <Select
              labelId="coupon-kind-label"
              label="Tipo de Cupom"
              name="kind"
              value={coupon.kind}
              onChange={handleChange}
            >
              <MenuItem value="percentage">Percentage</MenuItem>
              <MenuItem value="fixed">Fixed</MenuItem>
            </Select>
            {fieldErrors.kind && (
              <Typography variant="caption" color="error">
                {fieldErrors.kind}
              </Typography>
            )}
          </FormControl>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Designs
            </Typography>
            <FormGroup>
              {designs.map((design) => (
                <FormControlLabel
                  key={design.id}
                  control={
                    <Checkbox
                      checked={selectedDesignIds.includes(design.id)}
                      onChange={() => handleDesignToggle(design.id)}
                    />
                  }
                  label={design.name}
                />
              ))}
            </FormGroup>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Criar Cupom
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default AdminCouponNew;