import { Box } from "@mui/material";
import LogoImg from "../../assets/images/cdelogo.svg";

export function MainLogo() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: 1, md: 2 },
      }}
    >
      <Box
        component="img"
        src={LogoImg}
        alt="Clube do Estudante"
        loading="lazy"
        sx={{
          width: { xs: "150px", sm: "180px", md: "200px", lg: "220px" },
          height: "auto",
        }}
      />
    </Box>
  );
}
