import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  CircularProgress,
  Alert,
  Pagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Edit, Visibility } from "@mui/icons-material";
import api from "../../../services/api";

const AdminUsersList = () => {
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(1); // usaremos página iniciando em 1
  const [rowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const navigate = useNavigate();

  // Debounce para o campo de busca: atualiza o debouncedSearch após 500ms sem alterações
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
      setPage(1); // Reseta para a primeira página ao buscar
    }, 500);
    return () => clearTimeout(handler);
  }, [search]);

  // Função para buscar os usuários
  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      // A API espera a página iniciando em 1
      const response = await api.get(
        `/admin/users?page=${page}&per_page=${rowsPerPage}&search=${debouncedSearch}`
      );
      setUsers(response.data.users || []);
      setTotalUsers(response.data.total_users || 0);
    } catch (err) {
      console.error("Erro ao buscar usuários", err);
      setError("Ocorreu um erro ao buscar os usuários.");
      setUsers([]);
      setTotalUsers(0);
    } finally {
      setIsLoading(false);
    }
  }, [page, rowsPerPage, debouncedSearch]);

  // Atualiza a listagem sempre que page, rowsPerPage ou o filtro de busca mudar
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // Memoiza as linhas da tabela para evitar renderizações desnecessárias
  const renderedRows = useMemo(() => {
    return users.map((user) => (
      <TableRow key={user.id}>
        <TableCell>{user.id}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.access}</TableCell>
        <TableCell>
          <IconButton
            onClick={() => navigate(`/admin/users/${user.id}`)}
            color="primary"
          >
            <Visibility />
          </IconButton>
          <IconButton
            onClick={() => navigate(`/admin/users/edit/${user.id}`)}
            color="secondary"
          >
            <Edit />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  }, [users, navigate]);

  // Calcula o total de páginas
  const totalPages = Math.ceil(totalUsers / rowsPerPage);

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Gerenciar Usuários
      </Typography>

      {/* Campo de busca */}
      <TextField
        label="Buscar"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        fullWidth
        margin="normal"
      />

      {/* Indicador de loading */}
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Mensagem de erro */}
      {error && (
        <Alert severity="error" sx={{ my: 2 }}>
          {error}
        </Alert>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Acesso</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderedRows}</TableBody>
        </Table>
      </TableContainer>

      {/* Paginação utilizando o componente Pagination */}
      {totalPages > 1 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            color="primary"
          />
        </Box>
      )}
    </Box>
  );
};

export default AdminUsersList;
