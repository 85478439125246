import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/useAuth";
import AdminOrderFilters from "../order/AdminOrderFilters";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MainLogo } from "../../../components/MainLogo";

const AdminOrderList = () => {
  const [orders, setOrders] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({});

  const perPage = 10;
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    fetchOrders();
  }, [isAuthenticated, filters, page]);

  const fetchOrders = async () => {
    if (!isAuthenticated()) return;
    setLoading(true);

    let query = new URLSearchParams({
      page,
      per_page: perPage,
    });

    if (filters.search) {
      query.append("search", filters.search);
    }
    if (filters.user_email) {
      query.append("user_email", filters.user_email);
    }
    if (filters.status) {
      query.append("filter_status", filters.status.join(","));
    }
    if (filters.invoice_status) {
      query.append("filter_invoice_status", filters.invoice_status.join(","));
    }
    if (filters.filter_minimum_date) {
      query.append("filter_minimum_date", filters.filter_minimum_date);
    }
    if (filters.filter_maximum_date) {
      query.append("filter_maximum_date", filters.filter_maximum_date);
    }

    try {
      const response = await api.get(`/admin/orders?${query.toString()}`);
      if (response.data && Array.isArray(response.data.orders)) {
        setOrders(response.data.orders);
        setHasNext(response.data.has_next);
      } else {
        console.error("Resposta inesperada da API:", response.data);
        setOrders([]);
      }
    } catch (err) {
      console.error("Erro ao buscar pedidos:", err);
      setError("Erro ao buscar pedidos. Verifique sua conexão.");
    } finally {
      setLoading(false);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (hasNext) {
      setPage(page + 1);
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(1);
  };

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString("pt-BR");
    const formattedTime = date.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDate} ${formattedTime}`;
  };

  if (loading) return <Typography sx={{ color: '#FFFFFF' }}>Carregando...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="xl" sx={{ backgroundColor: 'rgb(47, 5, 73)', padding: 2 }}>
      <MainLogo />
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/admin")}
          sx={{ color: '#D27CFC' }}
        >
          Voltar
        </Button>
      </Box>
      <Typography variant="h4" gutterBottom sx={{ color: '#FF4081' }}>
        Lista de Pedidos
      </Typography>

      <AdminOrderFilters onFilter={handleFilterChange} />

      <TableContainer component={Paper} sx={{ backgroundColor: '#121212' }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'rgb(47, 5, 73)' }}>
              <TableCell sx={{ color: '#D27CFC' }}>ID</TableCell>
              <TableCell sx={{ color: '#D27CFC' }}>Nome</TableCell>
              <TableCell sx={{ color: '#D27CFC' }}>Data de Criação</TableCell>
              <TableCell sx={{ color: '#D27CFC' }}>Status</TableCell>
              <TableCell sx={{ color: '#D27CFC' }}>Status da Fatura</TableCell>
              <TableCell sx={{ color: '#D27CFC' }}>Usuário</TableCell>
              <TableCell sx={{ color: '#D27CFC' }}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.length > 0 ? (
              orders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell sx={{ color: '#FFFFFF' }}>{order.id}</TableCell>
                  <TableCell sx={{ color: '#FFFFFF' }}>{order.full_name}</TableCell>
                  <TableCell sx={{ color: '#FFFFFF' }}>{formatDateTime(order.created_at)}</TableCell>
                  <TableCell sx={{ color: '#FFFFFF' }}>{order.status}</TableCell>
                  <TableCell sx={{ color: '#FFFFFF' }}>{order.invoice_status}</TableCell>
                  <TableCell sx={{ color: '#FFFFFF' }}>{order.user_id}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(`/admin/orders/${order.id}`)}
                      sx={{ color: '#D27CFC', borderColor: '#D27CFC' }}
                    >
                      Ver
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate(`/admin/orders/${order.id}/edit`)}
                      sx={{ marginLeft: 1 }}
                    >
                      Editar
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center" sx={{ color: '#FFFFFF' }}>
                  Nenhum pedido encontrado.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={handlePrevious}
          disabled={page === 1}
          color="primary"
        >
          Anterior
        </Button>
        <Typography sx={{ color: '#FFFFFF' }}>Página {page}</Typography>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={!hasNext}
          color="primary"
        >
          Próxima
        </Button>
      </Box>
    </Container>
  );
};

export default AdminOrderList;