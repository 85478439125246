import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title
} from "chart.js";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title);

const OrdersByStatusChart = ({ orders, startDate, endDate }) => {
  // Define a ordem dos status conforme o enum do pedido
  const statusOrder = [
    "started",
    "design_filled",
    "invoice_generated",
    "invoice_details_advanced",
    "student_details_filled",
    "photo_uploaded",
    "personal_identification_uploaded",
    "institution_identification_filled",
    "waiting_review",
    "approved",
    "adjustments_requested",
    "rejected",
    "blocked"
  ];

  // Gera labels de todas as datas entre startDate e endDate
  const generateLabels = (start, end) => {
    const labels = [];
    let current = dayjs(start);
    const last = dayjs(end);
    while (current.isSameOrBefore(last, "day")) {
      labels.push(current.format("DD/MM/YYYY"));
      current = current.add(1, "day");
    }
    return labels;
  };

  const labels = generateLabels(startDate, endDate);

  // Agrupa os pedidos por data e pelo campo "status" (do pedido)
  const groupedOrders = orders.reduce((acc, order) => {
    const date = dayjs(order.created_at).format("DD/MM/YYYY");
    if (!acc[date]) acc[date] = {};
    acc[date][order.status] = (acc[date][order.status] || 0) + 1;
    return acc;
  }, {});

  const datasets = statusOrder.map((status, index) => ({
    label: status,
    data: labels.map(date => (groupedOrders[date] ? groupedOrders[date][status] || 0 : 0)),
    backgroundColor: `hsl(${(index * 60) % 360}, 70%, 50%)`
  }));

  const data = { labels, datasets };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Pedidos por Status ao Longo do Tempo" }
    },
    scales: {
      x: { title: { display: true, text: "Data do Pedido" } },
      y: { title: { display: true, text: "Quantidade de Pedidos" }, beginAtZero: true }
    }
  };

  return <Bar data={data} options={options} />;
};

export default OrdersByStatusChart;
