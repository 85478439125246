import React, { useEffect } from "react";
import { Container, Stack, Typography, CircularProgress } from "@mui/material";
import { DesignChoice } from "../Form/DesignChoice";
import { PhotoUpload } from "../Form/PhotoUpload";
import { StudentDetails } from "../Form/StudentDetails";
import { UserCreation } from "../Form/UserCreation";
import { useOrder } from "../../hooks/useOrder";
import { PersonalIdentification } from "../Form/PersonalIdentification";
import { ReviewOrder } from "../Form/ReviewOrder";
import { InstitutionIdentification } from "../Form/InstitutionIdentification";
import { MainLogo } from "../MainLogo";
import { Adjustments } from "../Form/Adjustments";
import { InvoiceDetails } from "../Form/InvoiceDetails";
import { OrderShow } from "../../components/OrderShow";
import { RejectRequest } from "../../pages/RejectRequest";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import api from "../../services/api";

export function OrderSteps() {
  const { order, isLoading } = useOrder(); // ✅ Pegamos `isLoading` para verificar se o pedido está carregando
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    async function fetchFreeOrder() {
      if (order && order.coupon_id && order.status === "invoice_generated") {
        try {
          const response = await api.get(`orders/${order.id}/free_order`);
          console.log("✅ Pedido grátis verificado:", response.data);
        } catch (error) {
          console.error("❌ Erro ao buscar pedido grátis:", error);
        }
      }
    }

    if (order) {
      fetchFreeOrder();
    }
  }, [order]);

  function studentOption() {
    if (!order) return <Typography variant="h4">Erro ao carregar o pedido.</Typography>;

    console.log("🔄 order.status:", order.status);

    switch (order.status) {
      case "started":
        return <DesignChoice />;
      case "design_filled":
        return <UserCreation />;
      case "invoice_generated":
        return <InvoiceDetails />;
      case "invoice_details_advanced":
        return <StudentDetails />;
      case "student_details_filled":
        return <PhotoUpload />;
      case "photo_uploaded":
        return <PersonalIdentification />;
      case "personal_identification_uploaded":
        return <InstitutionIdentification />;
      case "institution_identification_filled":
        return <ReviewOrder />;
      case "waiting_review":
        navigate(`/congratulations/${order.identifier}`);
        return null; // ✅ Evita erro ao tentar renderizar outro componente
      case "adjustments_requested":
        return <Adjustments adjustments={order.not_solved_adjustment_request_items} />;
      case "rejected":
        return <RejectRequest />;
      case "approved":
        return <OrderShow />;
      default:
        return <Typography variant="h4">Status do pedido desconhecido.</Typography>;
    }
  }

  return (
    <Container sx={{ minHeight: "100vh", py: 5 }}>
      <Stack spacing={5} justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
        <MainLogo />

        {/* 🔄 Mostra o loading enquanto `order` não está carregado */}
        {isLoading ? (
          <CircularProgress />
        ) : (
          studentOption()
        )}
      </Stack>
    </Container>
  );
}
