import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Stack,
  Modal,
  Backdrop,
  Fade,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/useAuth";
import { MainLogo } from "../../../components/MainLogo";
import OrderTimeline from "./OrderTimeline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ApiIcon from "@mui/icons-material/Api";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoIcon from "@mui/icons-material/Info";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const AdminOrderShow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useAuth();
  const [modalImage, setModalImage] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [actionError, setActionError] = useState(null);

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const translatedInvoiceStatus = (status) => {
    switch (status) {
      case "paid":
        return "Pago";
      case "pending":
        return "Pendente";
      case "failed":
        return "Falhou";
      default:
        return status;
    }
  };

  const translatedOrderStatus = (status) => {
    switch (status) {
      case "waiting_review":
        return "Aguardando revisão";
      case "approved":
        return "Aprovado";
      case "rejected":
        return "Reprovado";
      case "exempt":
        return "Isento";
      default:
        return status;
    }
  };

  const translatedPeriod = (period) => {
    switch (period) {
      case "morning":
        return "Manhã";
      case "afternoon":
        return "Tarde";
      case "evening":
        return "Noite";
      default:
        return period;
    }
  };

  const translatedTime = (time) => {
    switch (time) {
      case "morning":
        return "Manhã";
      case "afternoon":
        return "Tarde";
      case "night":
        return "Noite";
      case "full_time":
        return "Integral";
      default:
        return time;
    }
  };

  useEffect(() => {
    if (!isAuthenticated()) return;

    setLoading(true);
    api
      .get(`/admin/orders/${id}`)
      .then((response) => {
        setOrder(response.data);
        setError(null);
      })
      .catch((err) => {
        console.error("Erro ao buscar o pedido:", err);
        setError("Erro ao carregar o pedido.");
      })
      .finally(() => setLoading(false));
  }, [id, isAuthenticated]);

  const handleDownloadPdf = async () => {
    try {
      const response = await api.get(`/admin/orders/${id}/download_pdf`, {
        responseType: 'blob', // Para lidar com o arquivo PDF
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `carteirinha_${order.identifier}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setSuccessMessage("PDF baixado com sucesso!");
    } catch (err) {
      console.error("Erro ao baixar o PDF:", err);
      setActionError("Erro ao baixar o PDF. Tente novamente.");
    }
  };

  const handleApprove = async () => {
    try {
      const response = await api.post(`/admin/orders/${id}/approve`, {}, {
        headers: {
          "Content-Type": "application/json",
          "X-HTTP-Method-Override": "POST",
        },
      });
      setOrder(response.data.order || response.data);
      setSuccessMessage("Pedido aprovado com sucesso!");
      setActionError(null);
    } catch (err) {
      console.error("Erro ao aprovar o pedido:", err);
      setActionError(err.response?.data?.error || "Erro ao aprovar o pedido. Tente novamente.");
    }
  };

  const handleReject = async () => {
    if (window.confirm("Tem certeza que deseja reprovar este pedido?")) {
      try {
        const response = await api.post(`/admin/orders/${id}/reject`, {}, {
          headers: {
            "Content-Type": "application/json",
            "X-HTTP-Method-Override": "POST",
          },
        });
        setOrder(response.data.order || response.data);
        setSuccessMessage("Pedido reprovado com sucesso!");
        setActionError(null);
      } catch (err) {
        console.error("Erro ao reprovar o pedido:", err);
        setActionError(err.response?.data?.error || "Erro ao reprovar o pedido. Tente novamente.");
      }
    }
  };

  const handleApproveAPI = async () => {
    try {
      const response = await api.post(`/admin/orders/${id}/approve_via_api`, {}, {
        headers: {
          "Content-Type": "application/json",
          "X-HTTP-Method-Override": "POST",
        },
      });
      setOrder(response.data.order || response.data);
      setSuccessMessage("Pedido aprovado por API com sucesso!");
      setActionError(null);
    } catch (err) {
      console.error("Erro ao aprovar o pedido por API:", err);
      setActionError(err.response?.data?.error || "Erro ao aprovar o pedido por API. Tente novamente.");
    }
  };

  const handleRejectAPI = async () => {
    if (window.confirm("Tem certeza que deseja reprovar este pedido?")) {
      try {
        const response = await api.post(`/admin/orders/${id}/reject_via_api`, {}, {
          headers: {
            "Content-Type": "application/json",
            "X-HTTP-Method-Override": "POST",
          },
        });
        setOrder(response.data.order || response.data);
        setSuccessMessage("Pedido reprovado por API com sucesso!");
        setActionError(null);
      } catch (err) {
        console.error("Erro ao reprovar o pedido por API:", err);
        setActionError(err.response?.data?.error || "Erro ao reprovar o pedido por API. Tente novamente.");
      }
    }
  };

  const handleExempt = async () => {
    try {
      const response = await api.patch(`/admin/orders/${id}/exempt`);
      setOrder(response.data.order || response.data);
      setSuccessMessage("Pagamento isentado com sucesso!");
      setActionError(null);
    } catch (err) {
      console.error("Erro ao isentar o pagamento:", err);
      setActionError(err.response?.data?.error || "Erro ao isentar o pagamento. Tente novamente.");
    }
  };

  const handleImageClick = (url) => {
    if (!url) return;
    if (url.toLowerCase().endsWith(".pdf")) {
      window.open(url, "_blank");
    } else {
      setModalImage(url);
    }
  };

  if (loading) {
    return (
      <Container sx={{ mt: 4, textAlign: "center" }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Carregando...
        </Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 4, textAlign: "center" }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!order) {
    return (
      <Container sx={{ mt: 4, textAlign: "center" }}>
        <Typography variant="h6">
          Nenhum pedido encontrado.
        </Typography>
      </Container>
    );
  }

  const photos = [
    { url: order.card_photo_url, label: "Foto do Estudante" },
    { url: order.card_document_photo_front_url, label: "Frente do Documento" },
    { url: order.card_document_photo_back_url, label: "Verso do Documento" },
    { url: order.card_institution_statement_photo_url, label: "Comprovante de Matrícula" },
  ];

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <MainLogo />
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/admin/orders")}
        >
          Voltar
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom>
        Detalhes do Pedido
      </Typography>

      {actionError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {actionError}
        </Alert>
      )}

      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          avatar={<PhotoCameraIcon color="primary" />}
          title="Fotos do Pedido"
          sx={{ backgroundColor: "action.hover" }}
        />
        <CardContent>
          <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
            {photos.map((photo, index) => (
              <Paper
                key={index}
                elevation={3}
                sx={{
                  width: 270,
                  height: 270,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: photo.url ? "pointer" : "default",
                  overflow: "hidden",
                }}
                onClick={() => handleImageClick(photo.url)}
              >
                {photo.url ? (
                  <img
                    src={photo.url}
                    alt={photo.label}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    {photo.label}
                  </Typography>
                )}
              </Paper>
            ))}
          </Box>
        </CardContent>
      </Card>

      <Modal
        open={Boolean(modalImage)}
        onClose={() => setModalImage(null)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={Boolean(modalImage)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "90%",
              maxHeight: "90%",
              outline: "none",
            }}
          >
            <img
              src={modalImage}
              alt="Visualização ampliada"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Box>
        </Fade>
      </Modal>

      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          avatar={<InfoIcon color="primary" />}
          title="Informações Básicas"
          sx={{ backgroundColor: "action.hover" }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Nome:</strong> {order.full_name}
              </Typography>
              <Typography variant="body1">
                <strong>Status:</strong> {translatedOrderStatus(order.status)}
              </Typography>
              <Typography variant="body1">
                <strong>ID:</strong> {order.id}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Identificador:</strong> {order.identifier}
              </Typography>
              <Typography variant="body1">
                <strong>Usuário (ID):</strong> {order.user_id}
              </Typography>
              <Typography variant="body1">
                <strong>Email do Usuário:</strong> {order.user_email}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          avatar={<AccountCircleIcon color="primary" />}
          title="Dados Pessoais"
          sx={{ backgroundColor: "action.hover" }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>CPF:</strong> {order.cpf}
              </Typography>
              <Typography variant="body1">
                <strong>Telefone:</strong> {order.phone_number}
              </Typography>
              <Typography variant="body1">
                <strong>Data de Nascimento:</strong> {formatDate(order.birth_date)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Tipo de Documento:</strong> {order.document_type}
              </Typography>
              <Typography variant="body1">
                <strong>Número do Documento:</strong> {order.document_number}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          avatar={<HomeIcon color="primary" />}
          title="Endereço"
          sx={{ backgroundColor: "action.hover" }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1">
                <strong>CEP:</strong> {order.cep}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1">
                <strong>Estado:</strong> {order.state_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1">
                <strong>Cidade:</strong> {order.city_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1">
                <strong>Bairro:</strong> {order.neighborhood}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Rua:</strong> {order.street_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Número:</strong> {order.street_number}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Complemento:</strong> {order.street_complement || "Não informado"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          avatar={<SchoolIcon color="primary" />}
          title="Informações do Curso & Contato"
          sx={{ backgroundColor: "action.hover" }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Instituição:</strong> {order.institution_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Curso:</strong> {order.custom_course}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Número de Registro:</strong> {order.registration_number || "Não informado"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Tipo de Curso:</strong> {order.course_type}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Período:</strong> {translatedPeriod(order.period)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Turno:</strong> {translatedTime(order.time)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          avatar={<ReceiptIcon color="primary" />}
          title="Fatura"
          sx={{ backgroundColor: "action.hover" }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>ID da Fatura:</strong> {order.invoice_id}
              </Typography>
              <Typography variant="body1">
                <strong>URL da Fatura:</strong> {order.invoice_url}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Pago em:</strong> {formatDate(order.invoice_paid_at)}
              </Typography>
              <Typography variant="body1">
                <strong>Valor Pago:</strong> {order.invoice_paid_cents}
              </Typography>
              <Typography variant="body1">
                <strong>Status da Fatura:</strong> {translatedInvoiceStatus(order.invoice_status)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          avatar={<SettingsIcon color="primary" />}
          title="Outras Informações"
          sx={{ backgroundColor: "action.hover" }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>ID do Site:</strong> {order.site_id}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>ID do Cupom:</strong> {order.coupon_id}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Nome do Cupom:</strong> {order.coupon_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>ID Externo:</strong> {order.student_external_id}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Criado via API:</strong> {order.created_via_api ? "Sim" : "Não"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Arquivado:</strong> {order.shelved ? "Sim" : "Não"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Código de Ativação:</strong> {order.activation_code}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>ID do Design:</strong> {order.design_id}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="body1">
                <strong>Nome do Design:</strong> {order.design_name}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          avatar={<AccessTimeIcon color="primary" />}
          title="Datas"
          sx={{ backgroundColor: "action.hover" }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1">
                <strong>Criado em:</strong> {formatDate(order.created_at)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1">
                <strong>Atualizado em:</strong> {formatDate(order.updated_at)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1">
                <strong>Finalizado em:</strong> {formatDate(order.finished_at)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1">
                <strong>Aprovado em:</strong> {formatDate(order.approved_at)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          avatar={<AccessTimeIcon color="primary" />}
          title="Eventos do Pedido"
          sx={{ backgroundColor: "action.hover" }}
        />
        <CardContent>
          <OrderTimeline orderId={id} />
        </CardContent>
      </Card>

      <Paper variant="outlined" sx={{ p: 2, mt: 3 }}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2} justifyContent="center">
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => navigate(`/admin/orders/${id}/edit`)}
          >
            Editar Pedido
          </Button>
          <Button
            variant="contained"
            startIcon={<PictureAsPdfIcon />}
            onClick={handleDownloadPdf}
          >
            Baixar PDF
          </Button>
          {order.status === "waiting_review" && !order.created_via_api && (
            <>
              <Button
                variant="contained"
                color="success"
                startIcon={<CheckCircleIcon />}
                onClick={handleApprove}
              >
                Aprovar
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<CancelIcon />}
                onClick={handleReject}
              >
                Reprovar
              </Button>
            </>
          )}
          {order.status === "waiting_review" && order.created_via_api && (
            <>
              <Button
                variant="contained"
                color="success"
                startIcon={<ApiIcon />}
                onClick={handleApproveAPI}
              >
                Aprovar por API
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<ApiIcon />}
                onClick={handleRejectAPI}
              >
                Reprovar por API
              </Button>
            </>
          )}
          {order.invoice_status !== "paid" && (
            <Button
              variant="contained"
              color="warning"
              startIcon={<WarningAmberIcon />}
              onClick={handleExempt}
            >
              Isentar
            </Button>
          )}
        </Stack>
      </Paper>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert
          onClose={() => setSuccessMessage("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!actionError}
        autoHideDuration={3000}
        onClose={() => setActionError(null)}
      >
        <Alert
          onClose={() => setActionError(null)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {actionError}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminOrderShow;