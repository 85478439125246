import React from "react";
import { Chart } from "react-chartjs-2"; // Gráfico misto (bar e line)
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Title
} from "chart.js";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Title
);

const InvoiceStatisticsChart = ({ orders }) => {
  // Agrupa os pedidos por data e soma o total pago e conta a quantidade de pedidos pagos
  const groupedTotals = {};
  const groupedCounts = {};

  orders.forEach(order => {
    const date = dayjs(order.created_at).format("DD/MM/YYYY");
    if (!groupedTotals[date]) {
      groupedTotals[date] = 0;
      groupedCounts[date] = 0;
    }
    // Se houver valor de invoice_paid_cents, consideramos o pedido como pago
    if (order.invoice_paid_cents !== undefined && order.invoice_paid_cents !== null) {
      groupedTotals[date] += order.invoice_paid_cents;
      groupedCounts[date] += 1;
    }
  });

  // Ordena as datas cronologicamente
  const sortedDates = Object.keys(groupedTotals).sort(
    (a, b) => dayjs(a, "DD/MM/YYYY").valueOf() - dayjs(b, "DD/MM/YYYY").valueOf()
  );

  const totalPaidValues = sortedDates.map(date => groupedTotals[date] / 100);
  const ticketMedioValues = sortedDates.map(date => {
    const count = groupedCounts[date] || 0;
    return count > 0 ? (groupedTotals[date] / 100) / count : 0;
  });
  const ordersPaidCounts = sortedDates.map(date => groupedCounts[date] || 0);

  const data = {
    labels: sortedDates,
    datasets: [
      {
        type: "bar",
        label: "Valor Pago (R$)",
        data: totalPaidValues,
        backgroundColor: "#3498DB",
        borderColor: "#2980B9",
        borderWidth: 1,
        yAxisID: "y",
      },
      {
        type: "bar",
        label: "Pedidos Pagos",
        data: ordersPaidCounts,
        backgroundColor: "#2ecc71",
        borderColor: "#27ae60",
        borderWidth: 1,
        // Utiliza o mesmo eixo que o Ticket Médio
        yAxisID: "y1",
      },
      {
        type: "line",
        label: "Ticket Médio (R$)",
        data: ticketMedioValues,
        borderColor: "#e74c3c",
        backgroundColor: "#e74c3c",
        fill: false,
        tension: 0.2,
        borderWidth: 2,
        yAxisID: "y1",
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Valores Pagos, Pedidos Pagos e Ticket Médio por Data" }
    },
    scales: {
      x: { title: { display: true, text: "Data do Pedido" } },
      y: {
        title: { display: true, text: "Valor Pago (R$)" },
        beginAtZero: true,
        position: "left"
      },
      y1: {
        title: { display: true, text: "Ticket Médio (R$) / Pedidos Pagos" },
        beginAtZero: true,
        position: "right",
        grid: { drawOnChartArea: false }
      }
    }
  };

  return <Chart data={data} options={options} />;
};

export default InvoiceStatisticsChart;
