import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";

const AdminPostCreate = () => {
  const [post, setPost] = useState({
    title: "",
    description: "",
    reading_time: "",
    author: "",
    slug: "",
  });

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.post("/admin/posts", { post });
      navigate("/admin/posts");
    } catch (error) {
      console.error("Erro ao criar post", error);
    }
  };

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Criar Novo Post
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Título"
          fullWidth
          margin="normal"
          value={post.title}
          onChange={(e) => setPost({ ...post, title: e.target.value })}
        />
        <TextField
          label="Descrição"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={post.description}
          onChange={(e) => setPost({ ...post, description: e.target.value })}
        />
        <TextField
            label="Conteúdo"
            fullWidth
            margin="normal"
            multiline
            rows={6}
            value={post.content}
            onChange={(e) => setPost({ ...post, content: e.target.value })}
        />
        <TextField
          label="Tempo de leitura"
          fullWidth
          margin="normal"
          value={post.reading_time}
          onChange={(e) => setPost({ ...post, reading_time: e.target.value })}
        />
        <TextField
          label="Autor"
          fullWidth
          margin="normal"
          value={post.author}
          onChange={(e) => setPost({ ...post, author: e.target.value })}
        />
        <TextField
          label="Slug"
          fullWidth
          margin="normal"
          value={post.slug}
          onChange={(e) => setPost({ ...post, slug: e.target.value })}
        />
        <Button sx={{ mt: 2 }} type="submit" variant="contained" color="primary">
          Criar
        </Button>
      </form>
    </Box>
  );
};

export default AdminPostCreate;
