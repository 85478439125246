// AdminOrderEdit.jsx
import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  InputAdornment,
  Paper,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  Slider,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import InputMask from "react-input-mask";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/useAuth";
import { MainLogo } from "../../../components/MainLogo";
import {
  AccountCircle,
  MenuBook,
  LocationCity,
  CalendarToday,
  Badge,
  LocalLibrary,
  Handyman,
  School,
  Engineering,
  Build,
  Work,
  Phone,
  Science,
  PhotoCamera,
} from "@mui/icons-material";

const CitySelect = () => {
  const { values, handleChange, touched, errors } = useFormikContext();
  const [localCities, setLocalCities] = useState([]);

  useEffect(() => {
    if (values.stateId) {
      api
        .get(`/states/${values.stateId}/cities`)
        .then((response) => {
          // Verifica se a resposta já é um array ou se está encapsulada em uma propriedade
          const cities = Array.isArray(response.data)
            ? response.data
            : response.data.cities || [];
          setLocalCities(cities);
        })
        .catch((err) => console.error("Erro ao carregar cidades", err));
    } else {
      setLocalCities([]);
    }
  }, [values.stateId]);

  return (
    <Grid item xs={12} md={6}>
      <FormControl fullWidth margin="normal">
        <InputLabel id="city-label">Cidade</InputLabel>
        <Select
          labelId="city-label"
          name="cityId"
          value={values.cityId}
          label="Cidade"
          onChange={handleChange}
        >
          {localCities.map((city) => (
            <MenuItem key={city.id} value={city.id}>
              {city.name}
            </MenuItem>
          ))}
        </Select>
        {touched.cityId && errors.cityId && (
          <Typography color="error" variant="body2">
            {errors.cityId}
          </Typography>
        )}
      </FormControl>
    </Grid>
  );
};

const COURSE_TYPES = [
  { label: "Ensino Infantil", icon: <School />, value: "Ensino Infantil" },
  { label: "Ensino Fundamental", icon: <School />, value: "Ensino Fundamental" },
  { label: "Ensino Médio", icon: <School />, value: "Ensino Médio" },
  { label: "Técnico", icon: <Engineering />, value: "Técnico" },
  { label: "Graduação", icon: <LocalLibrary />, value: "Graduação" },
  { label: "Graduação Tecnológica", icon: <Build />, value: "Graduação tecnológica" },
  { label: "Pós-graduação", icon: <Badge />, value: "Pós-graduação" },
  { label: "Especialização", icon: <Handyman />, value: "Especialização" },
  { label: "Mestrado", icon: <Science />, value: "Mestrado" },
  { label: "Doutorado", icon: <Science />, value: "Doutorado" },
  { label: "MBA", icon: <Work />, value: "MBA" },
];

const TIME_OPTIONS = [
  { value: "morning", label: "Manhã" },
  { value: "afternoon", label: "Tarde" },
  { value: "night", label: "Noite" },
  { value: "full_time", label: "Integral" },
];

const DOCUMENT_TYPES = [
  { value: "rg", label: "RG" },
  { value: "cnh", label: "CNH" },
  { value: "passaporte", label: "Passaporte" },
  { value: "outro", label: "Outro" },
];

const SHELVED_OPTIONS = [
  { value: false, label: "Não arquivado" },
  { value: true, label: "Arquivado" },
];

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Nome completo é obrigatório"),
  cpf: Yup.string()
    .required("CPF é obrigatório")
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "CPF deve estar no formato 000.000.000-00"),
  phoneNumber: Yup.string().required("Telefone é obrigatório"),
  birthDate: Yup.string()
    .required("Data de nascimento é obrigatória")
    .matches(/^\d{2}\/\d{2}\/\d{4}$/, "Data de nascimento inválida"),
  documentType: Yup.string().required("Tipo de documento é obrigatório"),
  documentNumber: Yup.string().required("Número do documento é obrigatório"),
  cep: Yup.string()
    .required("CEP é obrigatório")
    .matches(/^\d{5}-\d{3}$/, "CEP deve estar no formato 00000-000"),
  stateId: Yup.string().required("Estado é obrigatório"),
  cityId: Yup.string().required("Cidade é obrigatória"),
  streetName: Yup.string().required("Nome da rua é obrigatório"),
  streetNumber: Yup.string().required("Número é obrigatório"),
  neighborhood: Yup.string().required("Bairro é obrigatório"),
  institutionName: Yup.string().required("Instituição é obrigatória"),
  customCourse: Yup.string().required("Curso é obrigatório"),
  registrationNumber: Yup.string().when("registrationNotProvided", (registrationNotProvided, schema) =>
    registrationNotProvided ? schema : schema.required("Número de registro é obrigatório")
  ),
  courseType: Yup.string().required("Tipo de curso é obrigatório"),
  period: Yup.number().min(1).max(10).required("Período é obrigatório"),
  time: Yup.string().required("Turno é obrigatório"),
});

const AdminOrderEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [states, setStates] = useState([]);

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Carrega o pedido
  useEffect(() => {
    if (!isAuthenticated()) return;
    setLoading(true);
    api
      .get(`/admin/orders/${id}`)
      .then((response) => setOrder(response.data))
      .catch((err) => {
        console.error("Erro ao buscar pedido:", err);
        setError("Erro ao carregar o pedido.");
      })
      .finally(() => setLoading(false));
  }, [id, isAuthenticated]);

  useEffect(() => {
    api
      .get("/states")
      .then((response) => {
        const data = response.data;
        const statesArray = Array.isArray(data) ? data : data.states || [];
        setStates(statesArray);
      })
      .catch((err) => console.error("Erro ao carregar estados", err));
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    const formData = new FormData();

    formData.append("order[full_name]", values.fullName);
    formData.append("order[cpf]", values.cpf);
    formData.append("order[phone_number]", values.phoneNumber);
    formData.append("order[birth_date]", values.birthDate);
    formData.append("order[document_type]", values.documentType);
    formData.append("order[document_number]", values.documentNumber);
    formData.append("order[cep]", values.cep);
    formData.append("order[state_id]", values.stateId);
    formData.append("order[city_id]", values.cityId);
    formData.append("order[street_name]", values.streetName);
    formData.append("order[street_number]", values.streetNumber);
    formData.append("order[street_complement]", values.streetComplement);
    formData.append("order[neighborhood]", values.neighborhood);
    formData.append("order[institution_name]", values.institutionName);
    formData.append("order[custom_course]", values.customCourse);
    formData.append(
      "order[registration_number]",
      values.registrationNotProvided ? "" : values.registrationNumber
    );
    formData.append("order[course_type]", values.courseType);
    formData.append("order[period]", values.period);
    formData.append("order[time]", values.time);
    formData.append("order[shelved]", values.shelved);
    formData.append("order[design_id]", values.designId);

    if (values.photoCard instanceof File) {
      formData.append("order[card_photo]", values.photoCard);
    }
    if (values.photoDocumentFront instanceof File) {
      formData.append("order[card_document_photo_front]", values.photoDocumentFront);
    }
    if (values.photoDocumentBack instanceof File) {
      formData.append("order[card_document_photo_back]", values.photoDocumentBack);
    }
    if (values.photoInstitutionStatement instanceof File) {
      formData.append("order[card_institution_statement_photo]", values.photoInstitutionStatement);
    }

    api
      .patch(`/admin/orders/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => navigate(`/admin/orders/${id}`))
      .catch((err) => {
        console.error("Erro ao atualizar pedido:", err);
        setError("Erro ao atualizar o pedido.");
      })
      .finally(() => setSubmitting(false));
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!order) return <Typography>Nenhum pedido encontrado.</Typography>;

  return (
    <Container sx={{ padding: 3 }}>
      <MainLogo />
      <Typography variant="h4" textAlign="center" gutterBottom>
        Editar Pedido
      </Typography>
      <Formik
        enableReinitialize
        initialValues={{
          fullName: order.full_name || "",
          cpf: order.cpf || "",
          phoneNumber: order.phone_number || "",
          birthDate: order.birth_date ? formatDate(order.birth_date) : "",
          // Converte para lowercase para casar com as opções do select
          documentType: order.document_type ? order.document_type.toLowerCase() : "",
          documentNumber: order.document_number || "",
          cep: order.cep || "",
          stateId: order.state_id || "",
          cityId: order.city_id || "",
          streetName: order.street_name || "",
          streetNumber: order.street_number || "",
          streetComplement: order.street_complement || "",
          neighborhood: order.neighborhood || "",
          institutionName: order.institution_name || "",
          customCourse: order.custom_course || "",
          registrationNumber: order.registration_number || "",
          registrationNotProvided: !order.registration_number,
          courseType: order.course_type || "",
          period: order.period && !isNaN(order.period) ? Number(order.period) : 5,
          time: order.time || "",
          shelved: order.shelved || false,
          designId: order.design_id || "",
          photoCard: order.card_photo_url || "",
          photoDocumentFront: order.card_document_photo_front_url || "",
          photoDocumentBack: order.card_document_photo_back_url || "",
          photoInstitutionStatement: order.card_institution_statement_photo_url || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, setFieldValue, isSubmitting, isValid, dirty }) => (
          <Form>
            <Card variant="outlined" sx={{ mb: 2 }}>
              <CardHeader
                avatar={<PhotoCamera color="primary" />}
                title="Fotos do Pedido"
                sx={{ backgroundColor: "action.hover" }}
              />
              <CardContent>
                <Grid container spacing={2}>
                  {[
                    { name: "photoCard", label: "Foto do Estudante" },
                    { name: "photoDocumentFront", label: "Frente do Documento" },
                    { name: "photoDocumentBack", label: "Verso do Documento" },
                    { name: "photoInstitutionStatement", label: "Comprovante de Matrícula" },
                  ].map((photoField, index) => {
                    const value = values[photoField.name];
                    let preview = "";
                    if (value instanceof File) {
                      preview = URL.createObjectURL(value);
                    } else {
                      preview = value;
                    }
                    return (
                      <Grid item xs={12} sm={6} md={3} key={index}>
                        <Paper elevation={3} sx={{ p: 1 }}>
                          {preview ? (
                            <img
                              src={preview}
                              alt={photoField.label}
                              style={{
                                width: "100%",
                                height: 200,
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              align="center"
                              sx={{
                                height: 200,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {photoField.label}
                            </Typography>
                          )}
                          <Button variant="contained" component="label" fullWidth sx={{ mt: 1 }}>
                            Alterar
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                              onChange={(e) => {
                                if (e.currentTarget.files && e.currentTarget.files[0]) {
                                  setFieldValue(photoField.name, e.currentTarget.files[0]);
                                }
                              }}
                            />
                          </Button>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>

            <Card variant="outlined" sx={{ mb: 2 }}>
              <CardHeader title="Dados do Pedido" sx={{ backgroundColor: "action.hover" }} />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Field
                      as={TextField}
                      name="fullName"
                      label="Nome"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                      error={touched.fullName && Boolean(errors.fullName)}
                      helperText={touched.fullName && errors.fullName}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputMask mask="999.999.999-99" value={values.cpf} onChange={handleChange}>
                      {() => (
                        <TextField
                          fullWidth
                          name="cpf"
                          label="CPF"
                          margin="normal"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                          }}
                          error={touched.cpf && Boolean(errors.cpf)}
                          helperText={touched.cpf && errors.cpf}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputMask mask="(99) 99999-9999" value={values.phoneNumber} onChange={handleChange}>
                      {() => (
                        <TextField
                          fullWidth
                          name="phoneNumber"
                          label="Telefone"
                          margin="normal"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Phone />
                              </InputAdornment>
                            ),
                          }}
                          error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputMask mask="99/99/9999" value={values.birthDate} onChange={handleChange}>
                      {() => (
                        <TextField
                          fullWidth
                          name="birthDate"
                          label="Data de Nascimento"
                          margin="normal"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CalendarToday />
                              </InputAdornment>
                            ),
                          }}
                          error={touched.birthDate && Boolean(errors.birthDate)}
                          helperText={touched.birthDate && errors.birthDate}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="documentType-label">Tipo de Documento</InputLabel>
                      <Select
                        labelId="documentType-label"
                        name="documentType"
                        value={values.documentType}
                        label="Tipo de Documento"
                        onChange={handleChange}
                      >
                        {DOCUMENT_TYPES.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="documentNumber"
                      label="Número do Documento"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Badge />
                          </InputAdornment>
                        ),
                      }}
                      error={touched.documentNumber && Boolean(errors.documentNumber)}
                      helperText={touched.documentNumber && errors.documentNumber}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <InputMask mask="99999-999" value={values.cep} onChange={handleChange}>
                      {() => (
                        <TextField
                          fullWidth
                          name="cep"
                          label="CEP"
                          margin="normal"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocationCity />
                              </InputAdornment>
                            ),
                          }}
                          error={touched.cep && Boolean(errors.cep)}
                          helperText={touched.cep && errors.cep}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="state-label">Estado</InputLabel>
                      <Select
                        labelId="state-label"
                        name="stateId"
                        value={values.stateId}
                        label="Estado"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("cityId", "");
                        }}
                      >
                        {states.map((state) => (
                          <MenuItem key={state.id} value={state.id}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.stateId && errors.stateId && (
                        <Typography color="error" variant="body2">
                          {errors.stateId}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  {/* Componente para o select de Cidade */}
                  <CitySelect />

                  <Grid item xs={12} md={3}>
                    <Field
                      as={TextField}
                      name="neighborhood"
                      label="Bairro"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationCity />
                          </InputAdornment>
                        ),
                      }}
                      error={touched.neighborhood && Boolean(errors.neighborhood)}
                      helperText={touched.neighborhood && errors.neighborhood}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Field
                      as={TextField}
                      name="streetName"
                      label="Rua"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationCity />
                          </InputAdornment>
                        ),
                      }}
                      error={touched.streetName && Boolean(errors.streetName)}
                      helperText={touched.streetName && errors.streetName}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Field
                      as={TextField}
                      name="streetNumber"
                      label="Número"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Badge />
                          </InputAdornment>
                        ),
                      }}
                      error={touched.streetNumber && Boolean(errors.streetNumber)}
                      helperText={touched.streetNumber && errors.streetNumber}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Field
                      as={TextField}
                      name="streetComplement"
                      label="Complemento"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Handyman />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="institutionName"
                      label="Instituição"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocalLibrary />
                          </InputAdornment>
                        ),
                      }}
                      error={touched.institutionName && Boolean(errors.institutionName)}
                      helperText={touched.institutionName && errors.institutionName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="customCourse"
                      label="Curso"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MenuBook />
                          </InputAdornment>
                        ),
                      }}
                      error={touched.customCourse && Boolean(errors.customCourse)}
                      helperText={touched.customCourse && errors.customCourse}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      as={TextField}
                      name="registrationNumber"
                      label="Número de Registro"
                      fullWidth
                      margin="normal"
                      disabled={values.registrationNotProvided}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Badge />
                          </InputAdornment>
                        ),
                      }}
                      error={touched.registrationNumber && Boolean(errors.registrationNumber)}
                      helperText={touched.registrationNumber && errors.registrationNumber}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="registration-not-label">Não informar</InputLabel>
                      <Select
                        labelId="registration-not-label"
                        name="registrationNotProvided"
                        value={values.registrationNotProvided ? "true" : "false"}
                        label="Não informar"
                        onChange={(e) =>
                          setFieldValue("registrationNotProvided", e.target.value === "true")
                        }
                      >
                        <MenuItem value="false">Informar</MenuItem>
                        <MenuItem value="true">Não informar</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="courseType-label">Tipo de Curso</InputLabel>
                      <Select
                        labelId="courseType-label"
                        name="courseType"
                        value={values.courseType}
                        label="Tipo de Curso"
                        onChange={handleChange}
                      >
                        {COURSE_TYPES.map((course) => (
                          <MenuItem key={course.value} value={course.value}>
                            {course.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography gutterBottom>Período</Typography>
                    <Slider
                      name="period"
                      value={values.period}
                      onChange={(e, value) => setFieldValue("period", value)}
                      min={1}
                      max={10}
                      step={1}
                      marks
                      valueLabelDisplay="auto"
                    />
                    {touched.period && errors.period && (
                      <Typography color="error" variant="body2">
                        {errors.period}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="time-label">Turno</InputLabel>
                      <Select
                        labelId="time-label"
                        name="time"
                        value={values.time}
                        label="Turno"
                        onChange={handleChange}
                      >
                        {TIME_OPTIONS.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="shelved-label">Arquivado</InputLabel>
                      <Select
                        labelId="shelved-label"
                        name="shelved"
                        value={values.shelved ? "true" : "false"}
                        label="Arquivado"
                        onChange={(e) =>
                          setFieldValue("shelved", e.target.value === "true")
                        }
                      >
                        {SHELVED_OPTIONS.map((option) => (
                          <MenuItem key={option.value.toString()} value={option.value ? "true" : "false"}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      as={TextField}
                      name="designId"
                      label="ID do Design"
                      fullWidth
                      margin="normal"
                      error={touched.designId && Boolean(errors.designId)}
                      helperText={touched.designId && errors.designId}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid container justifyContent="center" sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!(isValid && dirty) || isSubmitting}
              >
                Salvar Alterações
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AdminOrderEdit;
