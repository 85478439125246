import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";

const AdminUserShow = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUser();
  }, [id]);

  const fetchUser = async () => {
    try {
      const response = await api.get(`/admin/users/${id}`);
      setUser(response.data);
    } catch (error) {
      console.error("Erro ao buscar usuário", error);
    }
  };

  if (!user) return <Typography>Carregando...</Typography>;

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Detalhes do Usuário
      </Typography>
      <Paper sx={{ p: 4 }}>
        <Typography><strong>ID:</strong> {user.id}</Typography>
        <Typography><strong>Email:</strong> {user.email}</Typography>
        <Typography><strong>Acesso:</strong> {user.access}</Typography>

        <Button onClick={() => navigate("/admin/users")} sx={{ mt: 2 }}>
          Voltar para Lista
        </Button>
      </Paper>
    </Box>
  );
};

export default AdminUserShow;
