import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { Phone, Email, Lock, ArrowForward } from "@mui/icons-material";
import { Formik, Form, ErrorMessage } from "formik";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/useAuth";
import { useOrder } from "../../../hooks/useOrder";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';

export function UserCreation() {
  const { order, setOrder } = useOrder();
  const { signInUser, signUpUser, isAuthenticated } = useAuth();
  const [requestLoading, setRequestLoading] = useState(false);
  const [currentForm, setCurrentForm] = useState("signup");
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      setCurrentForm("phone");
      updateUserWhenUserIsAuthenticatedButOrderHasNotUser();
    } else {
      setCurrentForm("signup");
    }
  }, [order, isAuthenticated()]);

  const updateUserWhenUserIsAuthenticatedButOrderHasNotUser = async () => {
    if (isAuthenticated() && !order.user_id) {
      await api.put(`orders/${order.identifier}/update_user`);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setRequestLoading(true);
    try {
      if (currentForm === "phone") {
        const data = {
          order: {
            phone_number: values.phoneNumber,
          },
        };
        const response = await api.put(`orders/${order.identifier}`, data);
        setOrder(response.data);
      } else if (currentForm === "signup") {
        const { email, password, phoneNumber } = values;
        await signUpUser(email, password);
        const data = {
          order: {
            phone_number: phoneNumber,
          },
        };
        const response = await api.put(`orders/${order.identifier}`, data);
        setOrder(response.data);
        setCurrentForm("phone");
      } else if (currentForm === "signin") {
        const { email, password } = values;
        await signInUser(email, password);
        setCurrentForm("phone");
      }
    } catch (error) {
      console.error(error);
    }
    setRequestLoading(false);
    setSubmitting(false);
  };

  // Esquemas de validação
  const phoneValidationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required("Telefone é obrigatório")
      .test("valid-phone", "Telefone inválido", (value) => {
        return value && value.replace(/[^0-9]/g, "").length === 11;
      }),
  });

  const signupValidationSchema = Yup.object().shape({
    email: Yup.string().email("Email inválido").required("Email é obrigatório"),
    password: Yup.string().required("Senha é obrigatória"),
    phoneNumber: Yup.string()
      .required("Telefone é obrigatório")
      .test("valid-phone", "Telefone inválido", (value) => {
        return value && value.replace(/[^0-9]/g, "").length === 11;
      }),
  });

  const signinValidationSchema = Yup.object().shape({
    email: Yup.string().email("Email inválido").required("Email é obrigatório"),
    password: Yup.string().required("Senha é obrigatória"),
  });

  return (
    <Box sx={{ padding: 3 }}>
      <Box mb={4} textAlign="center">
        <Typography
          variant="h4"
          textAlign="center"
          gutterBottom
          sx={{ fontWeight: 500, marginBottom: 4 }}
        >
          {currentForm === "phone"
            ? "Confirme o seu telefone de contato:"
            : currentForm === "signup"
            ? "Bora lá! Vamos começar seu cadastro!"
            : "Entre na sua conta"}
        </Typography>
      </Box>

      <Formik
        initialValues={{ email: "", password: "", phoneNumber: "" }}
        validationSchema={
          currentForm === "phone"
            ? phoneValidationSchema
            : currentForm === "signup"
            ? signupValidationSchema
            : signinValidationSchema
        }
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, isSubmitting, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              {currentForm !== "signin" && (
                <Grid item xs={12}>
                  <InputMask
                    mask="(99) 99999-9999"
                    value={values.phoneNumber}
                    onChange={(e) => setFieldValue("phoneNumber", e.target.value)}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        label="Telefone *"
                        variant="outlined"
                        error={
                          !values.phoneNumber ||
                          values.phoneNumber.replace(/[^0-9]/g, "").length !== 11
                        }
                        helperText={<ErrorMessage name="phoneNumber" />}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Phone />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>
              )}

              {(currentForm === "signup" || currentForm === "signin") && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="email"
                      label="Email"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.email}
                      helperText={<ErrorMessage name="email" />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="password"
                      label="Senha"
                      type="password"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.password}
                      helperText={<ErrorMessage name="password" />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Box textAlign="center" mt={4}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<ArrowForward />}
                disabled={requestLoading || isSubmitting}
              >
                {currentForm === "signin"
                  ? "ENTRAR"
                  : currentForm === "signup"
                  ? "CRIAR USUÁRIO"
                  : "CONTINUAR"}
              </Button>
            </Box>

            {requestLoading && (
              <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress />
              </Box>
            )}

            {currentForm !== "phone" && (
              <Box textAlign="center" mt={2}>
                {currentForm === "signup" ? (
                  <Typography variant="body2">
                    Já tem uma conta?{" "}
                    <Button color="secondary" onClick={() => setCurrentForm("signin")}>
                      Entrar
                    </Button>
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    Não tem uma conta?{" "}
                    <Button color="primary" onClick={() => setCurrentForm("signup")}>
                      Criar conta
                    </Button>
                  </Typography>
                )}
                <Button
                  color="secondary"
                  onClick={() => {
                    navigate(`/forgot_password`); 
                  }}
                >
                  Esqueci a senha
                </Button>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
}
