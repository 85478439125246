import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { SignIn } from "./pages/SignIn";
import { SignUp } from "./pages/SignUp";
import { useAuth } from "./hooks/useAuth";
import { OrderForm } from "./pages/OrderForm";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Terms } from "./pages/Terms";
import { Orders } from "./pages/Orders";
import { StudentCardStatus } from "./pages/StudentCardStatus";
import { Home } from "./pages/Home";
import { ShelvedOrders } from "./pages/ShelvedOrders";
import { InitializeOrder } from "./pages/InitializeOrder";
import { Congratulations } from "./pages/Congratulations";
import { RejectRequest } from "./pages/RejectRequest";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";
import { PostsList } from "./pages/PostsList";
import { PostDetail } from "./pages/PostDetail";
import { RequestAdjustments } from "./pages/RequestAdjustments";
import { OrderContextProvider } from "./contexts/OrderContext";

import AdminDashboard from "./pages/Admin/AdminDashboard";

import AdminOrderList from "./pages/Admin/order/AdminOrderList";
import AdminOrderShow from "./pages/Admin/order/AdminOrderShow";
import AdminOrderEdit from "./pages/Admin/order/AdminOrderEdit";

import AdminOrderReview from "./pages/Admin/order/AdminOrderReview";

import AdsTracker from "./pages/Admin/AdminAdsTracker";

import AdminStatistics from "./pages/Admin/AdminStatistics";

import AdminSiteList from "./pages/Admin/site/AdminSiteList";
import AdminSiteEdit from "./pages/Admin/site/AdminSiteEdit";
import AdminSiteNew from "./pages/Admin/site/AdminSiteNew";
import AdminSiteShow from "./pages/Admin/site/AdminSiteShow";

import AdminDesignList from "./pages/Admin/design/AdminDesignList";
import AdminDesignEdit from "./pages/Admin/design/AdminDesignEdit";
import AdminDesignCreate from "./pages/Admin/design/AdminDesignCreate";
import AdminDesignShow from "./pages/Admin/design/AdminDesignShow";

import AdminCouponList from "./pages/Admin/coupon/AdminCouponList";
import AdminCouponEdit from "./pages/Admin/coupon/AdminCouponEdit";
import AdminCouponNew from "./pages/Admin/coupon/AdminCouponNew";

import AdminUserList from "./pages/Admin/user/AdminUserList";
import AdminUserEdit from "./pages/Admin/user/AdminUserEdit";
import AdminUserShow from "./pages/Admin/user/AdminUserShow";

import AdminPostList from "./pages/Admin/post/AdminPostList";
import AdminPostCreate from "./pages/Admin/post/AdminPostCreate";
import AdminPostEdit from "./pages/Admin/post/AdminPostEdit";
import AdminPostShow from "./pages/Admin/post/AdminPostShow";

function AuthenticatedRoute({ children }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated() ? children : <Navigate to="/sign_in" />;
}

function PublicRoute({ children }) {
  return <>{children}</>;
}

function AdminRoute({ children }) {
  const { isAdmin } = useAuth();
  return isAdmin() ? children : <Navigate to="/sign_in" />;
}

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicRoute><Home /></PublicRoute>} />
        <Route path="/privacy_policy" element={<PublicRoute><PrivacyPolicy /></PublicRoute>} />
        <Route path="/sign_in" element={<PublicRoute><SignIn /></PublicRoute>} />
        <Route path="/sign_up" element={<PublicRoute><SignUp /></PublicRoute>} />
        <Route path="/forgot_password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
        <Route path="/reset_password" element={<PublicRoute><ResetPassword /></PublicRoute>} />
        <Route path="/orders/:id" element={<PublicRoute><OrderForm /></PublicRoute>} />
        <Route path="/orders" element={<AuthenticatedRoute><Orders /></AuthenticatedRoute>} />
        <Route path="/student_card_status/:identifier" element={<PublicRoute><StudentCardStatus /></PublicRoute>} />
        <Route path="/shelved_orders" element={<AuthenticatedRoute><ShelvedOrders /></AuthenticatedRoute>} />
        <Route path="/terms" element={<PublicRoute><Terms /></PublicRoute>} />
        <Route path="/:site/initializeOrder" element={<PublicRoute><InitializeOrder /></PublicRoute>} />
        <Route path="/congratulations/:order_id" element={<PublicRoute><Congratulations /></PublicRoute>} />
        <Route path="/rejectRequest/:order_id" element={<PublicRoute><RejectRequest /></PublicRoute>} />
        <Route path="/blog" element={<PublicRoute><PostsList /></PublicRoute>} />
        <Route path="/blog/:slug" element={<PublicRoute><PostDetail /></PublicRoute>} />

        <Route path="/admin" element={<AuthenticatedRoute><AdminDashboard /></AuthenticatedRoute>} />
        <Route path="/admin/orders" element={<AuthenticatedRoute><AdminOrderList /></AuthenticatedRoute>} />
        <Route path="/admin/orders/:id" element={<AuthenticatedRoute><AdminOrderShow /></AuthenticatedRoute>} />
        <Route path="/admin/orders/:id/edit" element={<AuthenticatedRoute><AdminOrderEdit /></AuthenticatedRoute>} />
        <Route path="/admin/statistics" element={<AuthenticatedRoute><AdminStatistics /></AuthenticatedRoute>} />
        <Route path="/admin/orders/review/:id" element={<AuthenticatedRoute><AdminOrderReview /></AuthenticatedRoute>} />

        <Route path="/admin/ads_tracker" element={<AuthenticatedRoute><AdsTracker /></AuthenticatedRoute>} />

        <Route path="/admin/sites" element={<AuthenticatedRoute><AdminSiteList /></AuthenticatedRoute>} />
        <Route path="/admin/sites/new" element={<AuthenticatedRoute><AdminSiteNew /></AuthenticatedRoute>} />
        <Route path="/admin/sites/:id/edit" element={<AuthenticatedRoute><AdminSiteEdit /></AuthenticatedRoute>} />
        <Route path="/admin/sites/:id" element={<AuthenticatedRoute><AdminSiteShow /></AuthenticatedRoute>} />

        <Route path="/admin/designs" element={<AuthenticatedRoute><AdminDesignList /></AuthenticatedRoute>} />
        <Route path="/admin/designs/new" element={<AuthenticatedRoute><AdminDesignCreate /></AuthenticatedRoute>} />
        <Route path="/admin/designs/:id/edit" element={<AuthenticatedRoute><AdminDesignEdit /></AuthenticatedRoute>} />
        <Route path="/admin/designs/:id" element={<AuthenticatedRoute><AdminDesignShow /></AuthenticatedRoute>} />

        <Route path="/admin/coupons" element={<AuthenticatedRoute><AdminCouponList /></AuthenticatedRoute>} />
        <Route path="/admin/coupons/new" element={<AuthenticatedRoute><AdminCouponNew /></AuthenticatedRoute>} />
        <Route path="/admin/coupons/:id/edit" element={<AuthenticatedRoute><AdminCouponEdit /></AuthenticatedRoute>} />

        <Route path="/admin/users" element={<AuthenticatedRoute><AdminUserList /></AuthenticatedRoute>} />
        <Route path="/admin/users/:id" element={<AuthenticatedRoute><AdminUserShow /></AuthenticatedRoute>} />
        <Route path="/admin/users/edit/:id" element={<AuthenticatedRoute><AdminUserEdit /></AuthenticatedRoute>} />

        <Route path="/admin/posts" element={<AuthenticatedRoute><AdminPostList /></AuthenticatedRoute>} />
        <Route path="/admin/posts/new" element={<AuthenticatedRoute><AdminPostCreate /></AuthenticatedRoute>} />
        <Route path="/admin/posts/:id" element={<AuthenticatedRoute><AdminPostShow /></AuthenticatedRoute>} />
        <Route path="/admin/posts/edit/:id" element={<AuthenticatedRoute><AdminPostEdit /></AuthenticatedRoute>} />
        
        <Route
          path="/orders/:id/adjustments"
          element={
            <AuthenticatedRoute>
              <OrderContextProvider>
                <RequestAdjustments />
              </OrderContextProvider>
            </AuthenticatedRoute>
          }
        />
        <Route path="*" element={<h1>Página não encontrada</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;