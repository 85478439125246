import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Button, Paper, MenuItem } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";

const AdminUserEdit = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUser();
  }, [id]);

  const fetchUser = async () => {
    try {
      const response = await api.get(`/admin/users/${id}`);
      setUser(response.data);
    } catch (error) {
      console.error("Erro ao buscar usuário", error);
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.patch(`/admin/users/${id}`, { user });
      alert("Usuário atualizado com sucesso!");
      navigate("/admin/users");
    } catch (error) {
      console.error("Erro ao atualizar usuário", error);
    }
  };

  if (!user) return <Typography>Carregando...</Typography>;

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Editar Usuário
      </Typography>
      <Paper sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            name="email"
            value={user.email || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />

          <TextField
            label="Acesso"
            name="access"
            select
            value={user.access || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="user">Usuário</MenuItem>
          </TextField>

          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            Salvar Alterações
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default AdminUserEdit;
