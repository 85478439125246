import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";

const AdminPostEdit = () => {
  const { id } = useParams(); // Agora usamos o ID, não o slug
  const navigate = useNavigate();
  
  const [post, setPost] = useState({
    title: "",
    description: "",
    reading_time: "",
    author: "",
    slug: "",
    content: "",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPost();
  }, []);

  const fetchPost = async () => {
    try {
      const response = await api.get(`/admin/posts/${id}`); // Agora busca pelo ID
      console.log("Dados recebidos do backend:", response.data);
      setPost(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar post", error);
      setError("Erro ao carregar post.");
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.put(`/admin/posts/${id}`, { post }); // Agora edita pelo ID
      navigate("/admin/posts");
    } catch (error) {
      console.error("Erro ao atualizar post", error);
      setError("Erro ao atualizar post.");
    }
  };

  if (loading) return <Typography>Carregando...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Editar Post
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Título"
          fullWidth
          margin="normal"
          value={post.title || ""}
          onChange={(e) => setPost({ ...post, title: e.target.value })}
        />
        <TextField
          label="Descrição"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={post.description || ""}
          onChange={(e) => setPost({ ...post, description: e.target.value })}
        />
        <TextField
          label="Conteúdo"
          fullWidth
          margin="normal"
          multiline
          rows={6}
          value={post.content || ""}
          onChange={(e) => setPost({ ...post, content: e.target.value })}
        />
        <TextField
          label="Tempo de leitura"
          fullWidth
          margin="normal"
          value={post.reading_time || ""}
          onChange={(e) => setPost({ ...post, reading_time: e.target.value })}
        />
        <TextField
          label="Autor"
          fullWidth
          margin="normal"
          value={post.author || ""}
          onChange={(e) => setPost({ ...post, author: e.target.value })}
        />
        <TextField
          label="Slug"
          fullWidth
          margin="normal"
          value={post.slug || ""}
          onChange={(e) => setPost({ ...post, slug: e.target.value })}
        />
        <Button sx={{ mt: 2 }} type="submit" variant="contained" color="primary">
          Salvar
        </Button>
      </form>
    </Box>
  );
};

export default AdminPostEdit;
