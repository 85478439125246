import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOrder } from "../../../hooks/useOrder";
import { useAuth } from "../../../hooks/useAuth";
import api from "../../../services/api";
import { PhotoCropper } from "../../PhotoCropper";

export function PhotoUpload() {
  const { order, setOrder } = useOrder();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate("/sign_in");
    }
    if (order.user_id !== user.id) {
      navigate("/orders");
    }
  }, [user, order, navigate]);

  async function handleCroppedFile(finalFile) {
    const dataObject = new FormData();
    dataObject.append("order[card_photo]", finalFile);
    try {
      const response = await api.put(`orders/${order.identifier}`, dataObject);
      setOrder(response.data);
    } catch (error) {
      console.error("Falha ao enviar a foto:", error);
    }
  }

  return <PhotoCropper onComplete={handleCroppedFile} />;
}
