import { Box, Stepper, Step, StepLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { OrderContext } from "../../contexts/OrderContext";

const steps = [
  "started",
  "design_filled",
  "invoice_generated",
  "invoice_details_advanced",
  "student_details_filled",
  "photo_uploaded",
  "personal_identification_uploaded",
  "institution_identification_filled",
];

const stepsInPortuguese = {
  started: "Escolher Modelo",
  design_filled: "Criar Usuário",
  invoice_generated: "Pagamento",
  invoice_details_advanced: "Dados Pessoais",
  student_details_filled: "Foto",
  photo_uploaded: "Documentos Pessoais",
  personal_identification_uploaded: "Dados da Instituição",
  institution_identification_filled: "Revisão",
};

export function SideBarLeft() {
  const theme = useTheme();
  const { order } = useContext(OrderContext);
  const [activeStep, setActiveStep] = useState(0);

  // Sempre chame os hooks antes do retorno condicional
  useEffect(() => {
    if (order?.status) {
      const currentStepIndex = steps.indexOf(order.status);
      if (currentStepIndex !== -1) {
        setActiveStep(currentStepIndex);
      }
    }
  }, [order]);

  // Depois de todos os hooks, condicione o retorno da interface
  if (order && order.showSidebar === false) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: "none", md: "block" },
        backgroundColor: theme.palette.background.paper,
        padding: 2,
        height: "100%",
        boxShadow: 3,
        color: theme.palette.text.primary,
      }}
    >
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step) => (
          <Step key={step}>
            <StepLabel
              sx={{
                "& .MuiStepLabel-label": {
                  color: theme.palette.text.secondary,
                  fontFamily: theme.typography.fontFamily,
                  "&.Mui-active": {
                    color: theme.palette.primary.main,
                  },
                  "&.Mui-completed": {
                    color: theme.palette.textconclued.main,
                  },
                },
              }}
            >
              {stepsInPortuguese[step]}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
