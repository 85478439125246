import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Title
} from "chart.js";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend, Title);

const InvoiceConversionChart = ({ orders, startDate, endDate }) => {
  // Gera labels para todas as datas entre startDate e endDate
  const generateLabels = (start, end) => {
    const labels = [];
    let current = dayjs(start);
    const last = dayjs(end);
    while (current.isSameOrBefore(last, "day")) {
      labels.push(current.format("DD/MM/YYYY"));
      current = current.add(1, "day");
    }
    return labels;
  };

  const labels = generateLabels(startDate, endDate);

  // Agrupa os pedidos por data considerando apenas os que possuem invoice_status
  const grouped = orders.reduce((acc, order) => {
    if (!order.invoice_status) return acc;
    const date = dayjs(order.created_at).format("DD/MM/YYYY");
    if (!acc[date]) acc[date] = { total: 0, paid: 0 };
    acc[date].total += 1;
    if (order.invoice_status === "paid") {
      acc[date].paid += 1;
    }
    return acc;
  }, {});

  // Calcula a taxa de conversão para cada data (paid/total * 100)
  const conversionRates = labels.map(date => {
    const dayData = grouped[date];
    if (dayData && dayData.total > 0) {
      return (dayData.paid / dayData.total) * 100;
    }
    return 0;
  });

  const data = {
    labels,
    datasets: [
      {
        label: "Conversão de Faturas (%)",
        data: conversionRates,
        fill: false,
        borderColor: "#27ae60",
        backgroundColor: "#27ae60",
        tension: 0.1,
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Conversão de Faturas por Dia" }
    },
    scales: {
      x: { title: { display: true, text: "Data" } },
      y: {
        title: { display: true, text: "Conversão (%)" },
        beginAtZero: true,
        max: 100,
      }
    }
  };

  return <Line data={data} options={options} />;
};

export default InvoiceConversionChart;
