import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import api from "../../../services/api";

const OrderTimeline = ({ orderId }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!orderId) return;
    setLoading(true);
    api
      .get(`/admin/orders/${orderId}/order_events`)
      .then((response) => setEvents(response.data.events))
      .catch((err) => {
        console.error("Erro ao carregar eventos do pedido:", err);
        setError("Erro ao carregar eventos do pedido.");
      })
      .finally(() => setLoading(false));
  }, [orderId]);

  // Função para traduzir o tipo de evento com base no valor de `kind`
  const translateEventKind = (kind) => {
    const translations = {
      change_status_from_started_to_design_filled:
        "Mudou de iniciada para modelo selecionado",
      change_status_from_design_filled_to_invoice_generated:
        "Mudou de modelo selecionado para fatura gerada",
      change_status_from_invoice_generated_to_invoice_details_advanced:
        "Mudou de fatura gerada para fatura avançada",
      change_status_from_invoice_details_advanced_to_student_details_filled:
        "Mudou de fatura avançada para detalhes do estudante preenchidos",
      change_status_from_student_details_filled_to_photo_uploaded:
        "Mudou de detalhes do estudante preenchidos para foto enviada",
      change_status_from_photo_uploaded_to_personal_identification_uploaded:
        "Mudou de foto enviada para documentação pessoal enviada",
      change_status_from_personal_identification_uploaded_to_institution_identification_filled:
        "Mudou de documentação pessoal enviada para documentação estudantil enviada",
      sent_to_review: "Enviado para revisão",
      resent_to_review: "Reenviado para revisão",
      order_approved: "Pedido aprovado",
      order_rejected: "Pedido reprovado",
      adjustments_requested: "Pedido de ajustes realizado",
      account_created: "Conta criada",
      avarin: "Email da Avarin enviado",
      invoice_not_generated: "Email do Invoice não gerado com usuário",
      one_day_after_approval: "Email de Meia da Meia para amigo",
      exempted: "Pagamento liberado pelo administrador",
      bounce_detected: "Bounce detectado",
      renewal_february_email: "Email de renovação de fevereiro enviado",
      renewal_march_email: "Email de renovação de março enviado",
      renewal_april_email: "Email de renovação de abril enviado",
      renewal_may_email: "Email de renovação de maio enviado",
      renewal_email: "Email de renovação enviado",
    };
    return translations[kind] || "Evento desconhecido";
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (events.length === 0) {
    return (
      <Typography>Nenhum evento encontrado para este pedido.</Typography>
    );
  }

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Linha do Tempo do Pedido
        </Typography>
        <Timeline position="alternate">
          {events.map((event, index) => (
            <TimelineItem key={event.id || index}>
              <TimelineOppositeContent color="text.secondary">
                {new Date(event.created_at).toLocaleString("pt-BR")}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {index !== events.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="body1">
                  {translateEventKind(event.kind)}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
};

export default OrderTimeline;
